import {
	Dispatch,
	FC,
	PropsWithChildren,
	SetStateAction,
	createContext,
	useEffect,
	useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tag } from 'shared/models/tags';
import { RootState } from 'app/store/store';
import { Dayjs } from 'dayjs';

export interface FilterState {
	[filterGroupName: string]: {
		[filterName: string]: boolean | number | Date | Dayjs | null | undefined;
	};
}

export interface FiltersProps {
	filterGroupName: string;
	filterNames: string[];
}

interface FilterContextProps {
	isDrawerOpen: boolean;
	filterState: FilterState;
	setFilterState: Dispatch<SetStateAction<FilterState>>;
	filters: FiltersProps[];
	applyFilters: VoidFunction;
	handleOpen: VoidFunction;
	handleClose: VoidFunction;
	resetFilters: VoidFunction;
	transformFilters: (filterState: FilterState, tags: Tag[]) => FiltersProps[];
}

const FilterContext = createContext<FilterContextProps>({
	isDrawerOpen: false,
	filterState: {},
	setFilterState: () => null,
	filters: [],
	applyFilters: () => null,
	handleOpen: () => null,
	handleClose: () => null,
	resetFilters: () => null,
	transformFilters: () => [],
});

const FilterProvider: FC<PropsWithChildren> = ({ children }) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [filterState, setFilterState] = useState<FilterState>({});
	const [formattedFilters, setFormattedFilters] = useState<FiltersProps[]>([]);
	const tags = useSelector((state: RootState) => state.tags.tags);
	const location = useLocation();
	const transformFilters = (filterState: FilterState, tags: Tag[]): FiltersProps[] => {
	const transformedFilters: FiltersProps[] = [];

		for (const [filterGroupName, filters] of Object.entries(filterState)) {
			const transformedFilterGroupName = filterGroupName.toLowerCase().replace(/ /g, '_');
			const filterNames: string[] = [];

			for (const [filterName, filterValue] of Object.entries(filters)) {
				if (filterValue && typeof filterValue === 'object') {
					filterNames.push(`${filterName}-${filterValue.toISOString()}`);
				}
				if (filterGroupName === 'Ad spend') {
					const min = filters['min'];
					const max = filters['max'];

					if (min === 0 && max === 2000000) {
						continue;
					}
				}
				if ((filterValue && typeof filterValue === 'number') || filterValue === 0) {
					filterNames.push(String(filterValue));
				}
				if (filterValue === true) {
					if (filterGroupName === 'Tags') {
						const tagIds = tags.flatMap((tag) => {
							const subTagIds =
								tag.subTags
									?.filter((subTag) => filterName.includes(subTag.name))
									.map((subTag) => subTag.id.toString()) || [];
							return filterName.includes(tag.name)
								? [tag.id.toString(), ...subTagIds]
								: subTagIds;
						});
						filterNames.push(...tagIds);
					} else {
						filterNames.push(getTransformedFilterName(filterName));
					}
				}
			}
			if (filterNames.length > 0) {
				transformedFilters.push({
					filterGroupName: transformedFilterGroupName,
					filterNames,
				});
			}
		}
		return transformedFilters;
	};

	const getTransformedFilterName = (filterName: string) => {
		switch (filterName) {
			case 'TikTok':
			case 'Facebook':
			case 'Video':
			case 'Carousel':
			case 'Image':
			case 'Dco':
			case 'Dpo':
				return filterName.toLowerCase();
			case 'Still running':
				return 'active';
			case 'Not running':
				return 'inactive';
			case 'Removed':
				return 'removed';
			case 'Found by bots':
				return 'bot';
			case 'Found by human':
				return 'human';
			default:
				return filterName;
		}
	};

	const handleOpen = () => {
		setIsDrawerOpen(true);
	};

	const handleClose = () => {
		setIsDrawerOpen(false);
	};

	const applyFilters = () => {
		const transformedFilters = transformFilters(filterState, tags);
		setFormattedFilters(transformedFilters);
		handleClose();
	};

	const resetFilters = () => {
		setFormattedFilters([]);
	};

	useEffect(() => {
		resetFilters();
	}, [location.pathname]);

	const contextValue = {
		isDrawerOpen,
		filterState,
		setFilterState,
		filters: formattedFilters,
		applyFilters,
		handleOpen,
		handleClose,
		resetFilters,
		transformFilters,
	};

	return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
};

export { FilterProvider, FilterContext };
