import { Box, Chip } from '@mui/material';

import styles from './BillingToggle.module.scss';

interface BillingToggleProps {
	activeInterval: 'month' | 'year';
	handleIntervalClick: (label: 'month' | 'year') => void;
}

const BillingToggle = ({ activeInterval, handleIntervalClick }: BillingToggleProps) => (
	<Box className={styles.billingToggle}>
		<Chip
			label="Monthly"
			className={`${styles.defaultChip} ${activeInterval === 'month' ? styles.activeChip : ''}`}
			onClick={() => handleIntervalClick('month')}
		/>
		<Chip
			label="Annualy"
			className={`${styles.defaultChip} ${activeInterval === 'year' ? styles.activeChip : ''}`}
			onClick={() => handleIntervalClick('year')}
		/>
	</Box>
);

export default BillingToggle;
