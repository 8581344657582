/* eslint-disable @typescript-eslint/no-explicit-any */
import { InviteMembersResponse } from '../models/InviteMemberResponse';
import { TeamResponse } from '../models/TeamResponse';
import { UserTeamsResponse } from '../models/UserTeamsResponse';
import { apiService } from 'core/api/services';

export class TeamApiService {
	async getUsersTeams(user_id: number): Promise<UserTeamsResponse> {
		return apiService.responseHandler(
			await apiService.get<UserTeamsResponse>(`/api/client/user/${user_id}/team`)
		);
	}

	async getTeam(user_id: number | undefined, team_id: number | undefined): Promise<TeamResponse> {
		return apiService.responseHandler(
			await apiService.get<TeamResponse>(
				`/api/client/user/${user_id}/team/${team_id}/members`
			)
		);
	}

	async createTeam(user_id: number): Promise<{ message: string }> {
		return apiService.responseHandler(
			await apiService.post<{ message: string }>(`/api/client/user/${user_id}/team`, {
				name: 'My team',
			})
		);
	}

	async sendInvite(
		user_id: number,
		team_id: number,
		emails: string[]
	): Promise<InviteMembersResponse> {
		return apiService.responseHandler(
			await apiService.post<InviteMembersResponse>(
				`/api/client/user/${user_id}/team/${team_id}/invite`,
				{
					email: emails,
				}
			)
		);
	}

	async resendInvite(user_id: number, email: string, teamId: number | undefined): Promise<any> {
		return apiService.responseHandler(
			await apiService.post(`/api/client/user/${user_id}/team/${teamId}/resend`, {
				email,
			})
		);
	}

	async deactivate(user_id: number, email: string, teamId: number | undefined): Promise<any> {
		return apiService.responseHandler(
			await apiService.post(`/api/client/user/${user_id}/team/${teamId}/remove`, {
				email,
			})
		);
	}
}

export const teamApiService = new TeamApiService();
