import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { FormInput } from 'shared/components/FormInput';
import { CheckIcon } from 'shared/components/Icons';
import { RoutesEnum } from 'shared/enums';

import { Layout } from '../common/Layout';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePostForgotPassword } from 'app/pages/auth/hooks';
import { useUser } from 'core/UserProvider/useUser';
import * as yup from 'yup';

import styles from './ForgotPassword.module.scss';

const validationSchema = yup.object({
	email: yup.string().required('Email is required').email('Please enter a valid email address'),
});

const ForgotPassword: FC = () => {
	const { user } = useUser();
	const navigate = useNavigate();
	const methods = useForm<{ email: string }>({
		defaultValues: {
			email: '',
		},
		resolver: yupResolver(validationSchema),
		reValidateMode: 'onChange',
	});
	const { isValid, isSubmitting } = methods.formState;

	const { mutateAsync: handleForgotPassword, isSuccess, isPending } = usePostForgotPassword();

	const onSubmit = async (data: { email: string }) => {
		await handleForgotPassword({ email: data.email });
	};

	// Redirect to Home if the user is logged in
	useEffect(() => {
		if (user) {
			navigate(RoutesEnum.HOME);
		}
	}, [user, navigate]);

	// Stop unnecessary render if the user is logged in
	if (user) {
		return null;
	}

	return (
		<Box className={styles.containerForgotPassword}>
			<Layout headerTitle="Forgot your password?">
				<Typography className={styles.textLine}>
					Enter your email and we will send you instructions on how to reset your
					password.
				</Typography>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Box className={styles.emailInput}>
							<FormInput
								id="email"
								placeholder="Email"
								name="email"
								aria-label="Email"
								hiddenLabel={true}
								variant="outlined"
							/>
						</Box>

						<Button
							variant="contained"
							sx={{ height: '38px' }}
							fullWidth
							className={styles.btnSendInstruction}
							disabled={!isValid || isSubmitting || isSuccess || isPending}
							startIcon={isSuccess && <CheckIcon />}
							type="submit"
						>
							{isSuccess ? 'Instruction sent' : 'Send instructions'}
						</Button>
						<Typography className={styles.textLine}>
							Back to{' '}
							<Link to="/login" className={styles.linkSpan}>
								Log in
							</Link>
						</Typography>
					</form>
				</FormProvider>
			</Layout>
		</Box>
	);
};

export default ForgotPassword;
