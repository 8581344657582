import { SvgIcon, SvgIconProps } from '@mui/material';

export const GlobeIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M10 2.5C8.51664 2.5 7.0666 2.93987 5.83323 3.76398C4.59986 4.58809 3.63856 5.75943 3.07091 7.12987C2.50325 8.50032 2.35472 10.0083 2.64411 11.4632C2.9335 12.918 3.64781 14.2544 4.6967 15.3033C5.7456 16.3522 7.08197 17.0665 8.53683 17.3559C9.99168 17.6453 11.4997 17.4968 12.8701 16.9291C14.2406 16.3614 15.4119 15.4001 16.236 14.1668C17.0601 12.9334 17.5 11.4834 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5ZM16.2167 9.375H13.9167C13.6256 7.36751 12.7767 5.48207 11.4667 3.93333C12.7231 4.23941 13.8541 4.9267 14.7046 5.90096C15.555 6.87521 16.0832 8.0887 16.2167 9.375ZM7.34167 10.625H12.6583C12.2734 12.599 11.3539 14.4294 10 15.9167C8.64612 14.4294 7.72658 12.599 7.34167 10.625ZM7.34167 9.375C7.72658 7.40099 8.64612 5.57057 10 4.08333C11.3539 5.57057 12.2734 7.40099 12.6583 9.375H7.34167ZM8.53334 3.93333C7.22331 5.48207 6.37444 7.36751 6.08334 9.375H3.78334C3.91684 8.0887 4.44503 6.87521 5.29545 5.90096C6.14587 4.9267 7.27687 4.23941 8.53334 3.93333ZM3.78334 10.6H6.08334C6.37444 12.6075 7.22331 14.4929 8.53334 16.0417C7.28055 15.7371 6.1522 15.0535 5.30215 14.0841C4.4521 13.1148 3.92172 11.9068 3.78334 10.625V10.6ZM11.4667 16.0417C12.7767 14.4929 13.6256 12.6075 13.9167 10.6H16.2167C16.0881 11.8908 15.5621 13.1098 14.7113 14.089C13.8605 15.0681 12.7268 15.7591 11.4667 16.0667V16.0417Z"
				fill="white"
			/>
		</SvgIcon>
	);
};
