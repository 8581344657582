import { SvgIcon, SvgIconProps } from '@mui/material';

export const QuestionMarkIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="15" height="14" viewBox="0 0 15 14" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.40069 0.0887415C9.29345 0.266698 10.1133 0.705665 10.7563 1.35002C11.361 1.95822 11.7838 2.72317 11.9772 3.5587C12.1706 4.39424 12.1269 5.26717 11.8509 6.07919C11.575 6.89121 11.0778 7.61006 10.4154 8.15478C9.75298 8.69949 8.95163 9.04844 8.1016 9.16232V10.2018C8.1016 10.3609 8.03841 10.5134 7.92594 10.6259C7.81348 10.7384 7.66094 10.8015 7.50188 10.8015C7.34283 10.8015 7.19029 10.7384 7.07782 10.6259C6.96535 10.5134 6.90216 10.3609 6.90216 10.2018V8.60258C6.90424 8.44417 6.96809 8.29284 7.08011 8.18081C7.19213 8.06879 7.34347 8.00494 7.50188 8.00287C8.17628 8.00445 8.83597 7.80581 9.39737 7.43212C9.95877 7.05843 10.3966 6.52651 10.6554 5.90375C10.9142 5.28099 10.9824 4.59542 10.8512 3.9339C10.72 3.27238 10.3954 2.66468 9.91855 2.18781C9.44167 1.71094 8.83398 1.38635 8.17246 1.25517C7.51094 1.12399 6.82537 1.19212 6.20261 1.45093C5.57985 1.70974 5.04793 2.14759 4.67424 2.70899C4.30055 3.27039 4.10191 3.93008 4.10349 4.60448C4.10349 4.76353 4.04031 4.91607 3.92784 5.02854C3.81537 5.14101 3.66283 5.20419 3.50377 5.20419C3.34472 5.20419 3.19218 5.14101 3.07971 5.02854C2.96724 4.91607 2.90406 4.76353 2.90406 4.60448C2.90275 3.69415 3.17169 2.80392 3.67682 2.0466C4.18194 1.28927 4.90051 0.698936 5.74147 0.350392C6.58243 0.00184765 7.50793 -0.0892154 8.40069 0.0887415ZM8.5014 13.0005C8.5014 13.5526 8.0539 14.0001 7.50188 14.0001C6.94985 14.0001 6.50235 13.5526 6.50235 13.0005C6.50235 12.4485 6.94985 12.001 7.50188 12.001C8.0539 12.001 8.5014 12.4485 8.5014 13.0005Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
