import { FC } from 'react';

import { Box, CardActions, IconButton } from '@mui/material';

import {
	ActivityIndicatorIcon,
	ElipsisIcon,
	EyeIcon,
	HeartFillIcon,
	HeartIcon,
} from 'shared/components/Icons';
import { renderFormatedDuration } from 'shared/helpers';
import { Ad } from 'shared/models/ads';

import styles from '../AdCard.module.scss';

interface CardUserActionsProps {
	ad: Ad | undefined;
	handleMoreOptionsClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	handleToggleSaved: VoidFunction;
	handleViewDetails: (arg: string | undefined) => void;
	publicView?: boolean;
	isLoading: boolean;
}

const CardUserActions: FC<CardUserActionsProps> = ({
	ad,
	handleMoreOptionsClick,
	handleToggleSaved,
	handleViewDetails,
	publicView,
	isLoading,
}) => {
	const isActiveCampaign = ad?.campaign_end_date
		? new Date() <= new Date(new Date(ad.campaign_end_date).getTime() + 24 * 60 * 60 * 1000)
		: false;

	return (
		<CardActions disableSpacing className={styles.cardActionsContainer}>
			<Box className={styles.actionButtonsWrapper}>
				{!publicView && (
					<IconButton
						disabled={isLoading}
						aria-label="more options"
						onClick={handleMoreOptionsClick}
					>
						<ElipsisIcon sx={{ width: '20px', height: '20px' }} />
					</IconButton>
				)}
				<IconButton onClick={() => handleViewDetails(ad?._id)} aria-label="view ad">
					<EyeIcon sx={{ width: '20px', height: '20px' }} />
				</IconButton>
				{!publicView && (
					<IconButton
						disabled={isLoading}
						aria-label="like ad"
						onClick={handleToggleSaved}
					>
						{ad?.user_ad_id || ad?.isSaved ? (
							<HeartFillIcon color="primary" sx={{ width: '20px', height: '20px' }} />
						) : (
							<HeartIcon color="primary" sx={{ width: '20px', height: '20px' }} />
						)}
					</IconButton>
				)}
			</Box>
			<Box className={styles.activityContainer}>
				{renderFormatedDuration(ad?.campaign_start_date, ad?.campaign_end_date)}
				<ActivityIndicatorIcon
					sx={{
						width: '6px',
						height: '6px',
						color: isActiveCampaign ? 'rgba(34, 197, 94, 1)' : 'rgba(217, 217, 217, 1)',
					}}
				/>
			</Box>
		</CardActions>
	);
};

export default CardUserActions;
