import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	anchorElId: null,
};

export const modalManagementSlice = createSlice({
	name: 'modalManagement',
	initialState,
	reducers: {
		setAnchorElId: (state, action) => {
			state.anchorElId = action.payload;
		},
	},
});

export const { setAnchorElId } = modalManagementSlice.actions;
export default modalManagementSlice.reducer;
