import { FC } from 'react';

import { Container, ContainerProps } from '@mui/material';

import styles from './ContentContainer.module.scss';

const ContentContainer: FC<ContainerProps> = (props) => {
	return (
		<Container id="container-main" className={styles.root} {...props}>
			{props.children}
		</Container>
	);
};

export default ContentContainer;
