import { FC } from 'react';
import Masonry from 'react-masonry-css';
import { useSelector } from 'react-redux';

import { AdCardSkeleton } from 'shared/components/AdGrid/AdCardSkeleton';

import { RootState } from 'app/store/store';

import skeletonStyles from '../AdGrid.module.scss';

interface AdGridSkeletonProps {
	cardStyle?: boolean;
}

const AdGridSkeleton: FC<AdGridSkeletonProps> = ({ cardStyle }) => {
	const { breakpointCols } = useSelector((state: RootState) => state.gridZoom);
	return (
		<Masonry
			breakpointCols={breakpointCols}
			className={cardStyle ? skeletonStyles.cardMasonryGrid : skeletonStyles.masonryGrid}
			columnClassName={skeletonStyles.gridColumn}
		>
			{[...Array(16).keys()].map((i) => (
				<AdCardSkeleton key={i} />
			))}
		</Masonry>
	);
};

export default AdGridSkeleton;
