import { FC } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box/Box';
import Paper from '@mui/material/Paper/Paper';
import Typography from '@mui/material/Typography';

import { ChevronDownIcon, ImageIndentLeftIcon } from 'shared/components/Icons';

import styles from './Accordions.module.scss';

interface AccordionsProps {
	title: string | undefined;
	content: string | undefined;
}

const Accordions: FC<AccordionsProps> = ({ title, content }) => {
	return (
		<Paper className={styles.accordionsContainer}>
			<Box className={styles.accordionsHeader}>
				<ImageIndentLeftIcon sx={{ width: '20px', height: '20px' }} />
				<Typography variant="body1">Ad text</Typography>
			</Box>
			<Accordion className={styles.accordion} sx={{ '&.Mui-expanded': { margin: 0 } }}>
				<AccordionSummary
					className={styles.accordionSummary}
					expandIcon={<ChevronDownIcon sx={{ width: '14px', height: '14px' }} />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					sx={{
						'&.Mui-expanded': {
							minHeight: 'unset',
						},
					}}
				>
					<Typography variant="subtitle2">Ad text</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '8px 0 0 0' }}>
					{title && (
						<div className={styles.accText}>
							<ReactQuill
								value={title}
								readOnly={true}
								theme="bubble"
								className="read-only"
							/>
						</div>
					)}
					{content && (
						<div className={styles.accText}>
							<ReactQuill
								value={content}
								readOnly={true}
								theme="bubble"
								className="read-only"
							/>
						</div>
					)}
				</AccordionDetails>
			</Accordion>
			{/* <Accordion className={styles.accordion} sx={{ '&.Mui-expanded': { margin: 0 } }}>
				<AccordionSummary
					className={styles.accordionSummary}
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					sx={{
						'&.Mui-expanded': {
							minHeight: 'unset',
							borderTop: '1px solid rgba(205, 207, 209, 1)',
						},
					}}
				>
					<Typography variant="subtitle2">Video text</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '8px 0 0 0' }}>
					<Typography className={styles.accText}>
						Come with me to learn how to make more wealth for yourself
					</Typography>
					<Typography className={styles.accText}>
						Loving your creations! Found this one from @belladose and had to try it.
					</Typography>
					<Typography className={styles.accText}>
						One of the best side hustlers of 2023
					</Typography>
					<Typography className={styles.accText}>
						One of the best profitable side hustlers in 2023
					</Typography>
					<Typography className={styles.accText}>swipe left</Typography>
					<Typography className={styles.accText}>want to get more fans?</Typography>
					<Typography className={styles.accText}>
						want to get more followers on tiktok?
					</Typography>
				</AccordionDetails>
			</Accordion> */}
		</Paper>
	);
};

export default Accordions;
