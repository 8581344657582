import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
			<path
				d="M5.55648 13.5354C5.47098 13.5325 5.38697 13.5122 5.3096 13.4757C5.23223 13.4392 5.16312 13.3873 5.10653 13.3232L0.861628 9.07829C0.780693 9.02581 0.712738 8.95563 0.662893 8.87305C0.613048 8.79047 0.582617 8.69765 0.573897 8.60158C0.565176 8.50552 0.578395 8.40873 0.612554 8.31853C0.646714 8.22832 0.700921 8.14705 0.771083 8.08086C0.841246 8.01467 0.92553 7.96529 1.01757 7.93643C1.10961 7.90758 1.20701 7.90002 1.3024 7.91432C1.39779 7.92861 1.48869 7.9644 1.56823 8.01896C1.64777 8.07353 1.71387 8.14545 1.76155 8.22931L5.55648 11.9988L14.4453 3.13543C14.565 3.05784 14.7072 3.02267 14.8493 3.03557C14.9913 3.04846 15.1249 3.10867 15.2286 3.20654C15.3324 3.30441 15.4003 3.43427 15.4214 3.57532C15.4425 3.71637 15.4157 3.86042 15.3452 3.98441L6.00644 13.3232C5.94985 13.3873 5.88074 13.4392 5.80337 13.4757C5.726 13.5122 5.64198 13.5325 5.55648 13.5354Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
