import { SvgIcon, SvgIconProps } from '@mui/material';

export const LanguageIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M17.15 16.1417L14.6583 9.175C14.4834 8.71667 14.0917 8.43333 13.6417 8.43333C13.1917 8.43333 12.8084 8.71667 12.6167 9.19167L10.1333 16.1417C10.0167 16.4667 10.1833 16.825 10.5083 16.9417C10.8334 17.0583 11.1917 16.8917 11.3083 16.5667L11.825 15.125H15.45L15.9667 16.5667C16.0584 16.825 16.3 16.9833 16.5584 16.9833C16.625 16.9833 16.7 16.975 16.7667 16.95C17.0917 16.8333 17.2584 16.475 17.1417 16.15L17.15 16.1417ZM12.2833 13.8667L13.65 10.0417L15.0167 13.8667H12.2833ZM10.1583 6.54167C8.27502 9.51667 6.57502 11.3167 4.50835 12.5167C4.40835 12.575 4.30002 12.6 4.20002 12.6C3.98335 12.6 3.77502 12.4917 3.65835 12.2917C3.48335 11.9917 3.58335 11.6083 3.88335 11.4417C5.63335 10.425 7.06668 8.96667 8.67502 6.55H3.43335C3.09168 6.55 2.80835 6.26667 2.80835 5.925C2.80835 5.58333 3.09168 5.3 3.43335 5.3H6.55835V3.65C6.55835 3.30833 6.84168 3.025 7.18335 3.025C7.52502 3.025 7.80835 3.30833 7.80835 3.65V5.3H10.9333C11.275 5.3 11.5583 5.58333 11.5583 5.925C11.5583 6.26667 11.275 6.55 10.9333 6.55H10.15L10.1583 6.54167ZM10.1917 12.6C10.0833 12.6 9.97502 12.575 9.87502 12.5167C9.33335 12.2 8.80835 11.85 8.31668 11.4833C8.04168 11.275 7.98335 10.8833 8.19168 10.6083C8.40002 10.3333 8.79168 10.275 9.06668 10.4833C9.51668 10.825 10.0083 11.1417 10.5083 11.4333C10.8083 11.6083 10.9083 11.9917 10.7334 12.2917C10.6167 12.4917 10.4083 12.6 10.1917 12.6Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
