import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, TextField, TextFieldProps, Typography } from '@mui/material';

import classNames from 'classnames';

import styles from './FormInput.module.scss';

type FormInputProps = {
	label?: string;
	name: string;
} & TextFieldProps;

const FormInput: FC<FormInputProps> = ({ label, name, variant, className, ...props }) => {
	const { control, register, formState } = useFormContext();
	const error = formState.errors[name]?.message as string;
	return (
		<Box className={classNames(styles.container, className)}>
			{label && <Typography fontWeight={'bold'}>{label}</Typography>}
			<Controller
				key={name}
				control={control}
				name={name}
				render={({ field }) => (
					<>
						<TextField
							{...register(field.name)}
							className={styles.field}
							variant={variant}
							error={!!error}
							helperText={error}
							id="outlined-error-helper-text"
							FormHelperTextProps={{
								className: styles.helperText,
							}}
							{...props}
						/>
					</>
				)}
			/>
		</Box>
	);
};

export default FormInput;
