import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	searchResults: [],
	searchConducted: false,
	searchTerm: '',
};

export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearchResults: (state, action) => {
			state.searchResults = action.payload;
		},
		setSearchConducted: (state, action) => {
			state.searchConducted = action.payload;
		},
		setSearchTerm: (state, action) => {
			state.searchTerm = action.payload;
		},
	},
});

export const { setSearchResults, setSearchConducted, setSearchTerm } = searchSlice.actions;

export default searchSlice.reducer;
