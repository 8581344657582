import { SvgIcon, SvgIconProps } from '@mui/material';

export const DesktopIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.8333 13.9583H10.6249V16.0417H12.4999C12.6657 16.0417 12.8246 16.1075 12.9419 16.2247C13.0591 16.3419 13.1249 16.5009 13.1249 16.6667C13.1249 16.8324 13.0591 16.9914 12.9419 17.1086C12.8246 17.2258 12.6657 17.2917 12.4999 17.2917H9.99992H7.49992C7.33416 17.2917 7.17519 17.2258 7.05798 17.1086C6.94077 16.9914 6.87492 16.8324 6.87492 16.6667C6.87492 16.5009 6.94077 16.3419 7.05798 16.2247C7.17519 16.1075 7.33416 16.0417 7.49992 16.0417H9.37492V13.9583H4.16659C3.78049 13.9561 3.41082 13.8018 3.13781 13.5288C2.86479 13.2558 2.71044 12.8861 2.70825 12.5V4.16666C2.71044 3.78056 2.86479 3.4109 3.13781 3.13788C3.41082 2.86486 3.78049 2.71052 4.16659 2.70833H15.8333C16.2194 2.71052 16.589 2.86486 16.862 3.13788C17.1351 3.4109 17.2894 3.78056 17.2916 4.16666V12.5C17.2894 12.8861 17.1351 13.2558 16.862 13.5288C16.589 13.8018 16.2194 13.9561 15.8333 13.9583ZM15.8333 12.7083H9.99992H4.16659C4.11133 12.7083 4.05834 12.6864 4.01927 12.6473C3.9802 12.6082 3.95825 12.5552 3.95825 12.5V4.16666C3.95825 4.11141 3.9802 4.05842 4.01927 4.01935C4.05834 3.98028 4.11133 3.95833 4.16659 3.95833H15.8333C15.8885 3.95833 15.9415 3.98028 15.9806 4.01935C16.0196 4.05842 16.0416 4.11141 16.0416 4.16666V12.5C16.0416 12.5552 16.0196 12.6082 15.9806 12.6473C15.9415 12.6864 15.8885 12.7083 15.8333 12.7083Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
