import { useMemo } from 'react';

import { useGetActiveSubscriptionV2 } from './useGetActiveSubscriptionV2';
import { isEmpty } from 'lodash';

export const useActiveSubscriptionInfo = (user_id: number | undefined) => {
	const { data: subscriptionData, isLoading, isError } = useGetActiveSubscriptionV2(user_id);

	const activeSubscription = useMemo(() => {
		if (subscriptionData?.items?.data) {
			const subscriptionItem = subscriptionData.items.data.find(
				(item) => item.price.product.metadata.vs_item_type === 'subscription'
			);
			return subscriptionItem;
		}
		return undefined;
	}, [subscriptionData]);

	const activeSubscriptionPriceId = useMemo(() => {
		if (subscriptionData?.items?.data) {
			const subscriptionItem = subscriptionData.items.data.find(
				(item) => item.price.product.metadata.vs_item_type === 'subscription'
			);
			return subscriptionItem?.plan.id || undefined;
		}
		return undefined;
	}, [subscriptionData]);

	const activeAddon = useMemo(() => {
		if (subscriptionData?.items?.data) {
			const addonItem = subscriptionData.items.data.find(
				(item) => item.price.product.metadata.vs_item_type === 'addon'
			);
			return addonItem;
		}
		return undefined;
	}, [subscriptionData]);

	const activeAddonPriceId = useMemo(() => {
		if (subscriptionData?.items?.data) {
			const addonItem = subscriptionData.items.data.find(
				(item) => item.price.product.metadata.vs_item_type === 'addon'
			);
			return addonItem?.plan.id || undefined;
		}
		return undefined;
	}, [subscriptionData]);

	const isSubscriptionActive = useMemo(() => {
		return subscriptionData?.status === 'active';
	}, [subscriptionData]);

	const isSubscriptionTrialing = useMemo(() => {
		return subscriptionData?.status === 'trialing';
	}, [subscriptionData]);

	const isTrialCanceled = useMemo(() => {
		return subscriptionData?.trial_settings?.end_behavior?.missing_payment_method === 'cancel';
	}, [subscriptionData]);

	const cancelSubscriptionAt = useMemo(() => {
		return subscriptionData?.cancel_at || undefined;
	}, [subscriptionData]);

	const noSubscription = useMemo(() => {
		return isEmpty(subscriptionData);
	}, [subscriptionData]);

	const subscriptionEndsAt = useMemo(() => {
		return subscriptionData?.current_period_end || undefined;
	}, [subscriptionData]);

	return {
		isLoading,
		isError,
		isSubscriptionActive,
		isSubscriptionTrialing,
		activeSubscription,
		activeSubscriptionPriceId,
		activeAddon,
		activeAddonPriceId,
		isTrialCanceled,
		cancelSubscriptionAt,
		subscriptionEndsAt,
		noSubscription,
	};
};
