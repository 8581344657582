import { useContext } from 'react';

import { billingAndPlansApiService } from '../../services/billing/BillingAndPlansApiService';
import { useMutation } from '@tanstack/react-query';
import { ToastContext } from 'core/toast/ToastProvider';

export const useSubscribe = () => {
	const toast = useContext(ToastContext);
	return useMutation({
		mutationFn: (data: { user_id: number; planPriceId: string; addonPriceId?: string, fp_tid?: string }) =>
			billingAndPlansApiService.subscribe(data.user_id, data.planPriceId, data.addonPriceId, data.fp_tid),
		onSuccess: (res) => {
			toast.open('Success', 'You will be redirected to checkout', {
				severity: 'success',
			});
			setTimeout(() => {
				window.location.href = res.url;
			}, 1000);
		},
	});
};
