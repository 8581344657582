import { Tag } from 'shared/models/tags';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface TagsState {
	selectedTags: number[];
	tags: Tag[];
}

const initialState: TagsState = {
	selectedTags: [],
	tags: [],
};

export const tagsSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		setSelectedTags: (state, action) => {
			state.selectedTags = action.payload;
		},
		setTags: (state, action: PayloadAction<Tag[]>) => {
			state.tags = action.payload;
		},
		toggleTag: (state, action) => {
			const { tagId, allowEmpty } = action.payload;
			const index = state.selectedTags.indexOf(tagId);

			if (index !== -1) {
				if (!allowEmpty && state.selectedTags.length === 1) {
					return;
				}
				state.selectedTags.splice(index, 1);
			} else {
				state.selectedTags.push(tagId);
			}
		},
	},
});

export const { setSelectedTags, setTags, toggleTag } = tagsSlice.actions;

export default tagsSlice.reducer;
