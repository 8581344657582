import { FC, useState } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { TabPanel } from 'shared/components';
import { DatabaseIcon, HashtagIcon, PencilIcon } from 'shared/components/Icons';
import { Ad, AdDetails } from 'shared/models/ads';

import { AllData } from './AllData';
import { Notes } from './Notes';
import PublicNotes from './Notes/PublicNotes';
import { Organization } from './Organization';

interface DetailsTabsProps {
	external?: boolean;
	ad: Ad | undefined;
	adSource?: string | undefined;
	adSpend: string;
}

const DetailsTabs: FC<DetailsTabsProps> = ({ external, ad, adSource, adSpend }) => {
	const adData: AdDetails = {
		brand_name: ad?.brand_name,
		adSpend: ad?.spend,
		format: ad?.format,
		platform: ad?.platform,
		ad_impressions: ad?.ad_impressions,
		language: ad?.language,
		adType: ad?.ad_type,
		activityStart: ad?.campaign_start_date,
		activityEnd: ad?.campaign_end_date,
		ctaButton: ad?.cta_button,
		total_likes: ad?.total_likes,
		comments: ad?.comments,
		shares: ad?.shares,
		metadata: ad?.metadata,
		country_reach: ad?.country_reach,
		landing_page: ad?.landing_page,
		content_url: ad?.content_url,
		title: ad?.title,
		content: ad?.content,
		user_ad_id: ad?.user_ad_id,
		landing_page_image_url: ad?.landing_page_image_url,
		publicAdId: ad?._id,
	};
	const [activeTab, setActiveTab] = useState<number>(0);

	const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	return (
		<>
			<Tabs
				value={activeTab}
				onChange={handleTabChange}
				indicatorColor="primary"
				textColor="primary"
				variant="fullWidth"
			>
				<Tab
					value={0}
					label="All data"
					icon={<DatabaseIcon sx={{ width: '14px', height: '14px' }} />}
					iconPosition="start"
				/>
				{ad?.user_ad_id &&
					(adSource === 'userAds' || (adSource === 'publicAds' && ad?.user_ad_id)) && (
						<Tab
							value={1}
							label="Organization"
							icon={<HashtagIcon sx={{ width: '14px', height: '14px' }} />}
							iconPosition="start"
						/>
					)}
				{ad?.user_ad_id && (adSource === 'userAds' || ad?.user_ad_id) && (
					<Tab
						value={2}
						label="Notes"
						icon={<PencilIcon sx={{ width: '14px', height: '14px' }} />}
						iconPosition="start"
					/>
				)}
			</Tabs>
			<TabPanel value={activeTab} index={0}>
				<AllData details={adData} external={external} adSpend={adSpend} />
			</TabPanel>
			{ad?.user_ad_id &&
				(adSource === 'userAds' || (adSource === 'publicAds' && ad?.user_ad_id)) && (
					<TabPanel value={activeTab} index={1}>
						<Organization ad={ad} />
					</TabPanel>
				)}
			{ad?.user_ad_id && (
				<TabPanel value={activeTab} index={2}>
					{(adSource === 'userAds' || ad?.user_ad_id) && !external ? (
						<Notes notes={ad?.notes} adId={ad?.user_ad_id} />
					) : (
						<PublicNotes notes={ad?.notes} />
					)}
				</TabPanel>
			)}
		</>
	);
};

export default DetailsTabs;
