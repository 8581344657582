import { PriceObject, Product } from 'shared/models/billing';

import _ from 'lodash';


export const getDaysLeft = (validTo: string) => {
	const currentDate = new Date();
	const validToDate = new Date(validTo);
	const daysLeft = Math.ceil(
		(validToDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
	);
	return daysLeft > 0 ? `${daysLeft} days left` : 'Expired';
};

export const getDaysLeftV2 = (validTo: string | number) => {
	const currentDate = new Date();
	let validToDate;

	if (typeof validTo === 'number') {
		validToDate = new Date(validTo * 1000);
	} else {
		validToDate = new Date(validTo);
	}

	const daysLeft = Math.ceil(
		(validToDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
	);

	return daysLeft > 0 ? `${daysLeft} days left` : 'Expired';
};

export const isEmptyArrayOrUndefined = (value: any): boolean => {
	return _.isUndefined(value) || (_.isArray(value) && _.isEmpty(value));
};

export const getCookie = (name: string) => {
	const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
	if (match) return match[2];
	return null;
};

export const sortByPriceForInterval = (products: Product[], interval: 'month' | 'year') => {
	return products.sort((a, b) => {
		const priceA = a.prices.find((price) => price.recurring.interval === interval);
		const priceB = b.prices.find((price) => price.recurring.interval === interval);

		const unitAmountA = priceA?.unit_amount || 0;
		const unitAmountB = priceB?.unit_amount || 0;

		return unitAmountA - unitAmountB;
	});
};

export const getPriceInEuros = (prices: PriceObject[], interval: 'month' | 'year'): string => {
	const price = prices.find((price) => price.recurring.interval === interval);

	if (price?.unit_amount) {
		return (price.unit_amount / 100).toFixed(2);
	}

	return '0';
};

export const getPriceIntervalId = (
	prices: PriceObject[],
	interval: 'month' | 'year'
): string | undefined => {
	const price = prices.find((price) => price.recurring.interval === interval);

	return price?.id;
};
