import { UserUpdateRequest } from 'shared/models/UserUpdateRequest';

import { UserStatistics } from 'app/pages/overview/models/UserStatistics';
import { UserDTO } from 'core/api/models/user';
import { apiService } from 'core/api/services';

export class UserApiService {
	async getUser(id: number | null): Promise<UserDTO> {
		return apiService.responseHandler(await apiService.get<UserDTO>(`/api/client/user/${id}`));
	}

	async updateUser(id: number, body: UserUpdateRequest | FormData): Promise<UserDTO> {
		return apiService.responseHandler(
			await apiService.patch(`/api/client/user/${id}`, body, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
		);
	}

	async getStatistics(id: number): Promise<UserStatistics> {
		return apiService.responseHandler(
			await apiService.get<UserStatistics>(`/api/client/user/${id}/user-statistics`)
		);
	}
}

export const userApiService = new UserApiService();
