import { SvgIcon, SvgIconProps } from '@mui/material';

export const PencilIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="15" height="14" viewBox="0 0 15 14" fill="none" {...props}>
			<g clipPath="url(#clip0_2342_47335)">
				<path
					d="M1.10963 13.959C1.03066 13.9599 0.952305 13.9451 0.879077 13.9156C0.805849 13.8861 0.739191 13.8424 0.68294 13.787C0.618447 13.7234 0.568823 13.6464 0.537617 13.5614C0.50641 13.4765 0.49439 13.3857 0.502418 13.2956L0.75679 10.1501C0.767698 10.0041 0.831934 9.86709 0.937312 9.76516L10.0127 0.705715C10.5183 0.233014 11.1911 -0.0203041 11.8835 0.00127367C12.5714 0.00660201 13.2315 0.27311 13.7298 0.746671C14.2076 1.23651 14.4824 1.88848 14.4992 2.57201C14.5159 3.25554 14.2733 3.92015 13.82 4.4327L4.73648 13.5331C4.63482 13.6342 4.5017 13.6978 4.35903 13.7133L1.16707 14L1.10963 13.959ZM1.93018 10.4696L1.75787 12.714L4.00619 12.5092L12.9502 3.56444C13.1246 3.35841 13.2361 3.10674 13.2714 2.83933C13.3067 2.57193 13.2644 2.30002 13.1495 2.05591C13.0346 1.8118 12.8519 1.60575 12.6231 1.46224C12.3943 1.31873 12.1291 1.2438 11.8589 1.24633C11.6813 1.23508 11.5033 1.25975 11.3355 1.31887C11.1677 1.37798 11.0136 1.47033 10.8824 1.59036L1.93018 10.4696Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2342_47335">
					<rect width="14" height="14" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</SvgIcon>
	);
};
