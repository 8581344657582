import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { AdsControls, ContentContainer } from 'shared/components';
import { AdGrid } from 'shared/components';
import { FilterContext } from 'shared/context/FilterContext';
import { RoutesEnum } from 'shared/enums';
import { useSearchHook } from 'shared/hooks/useAdsManagement';
import { Tag } from 'shared/models/tags';

import { AdGridSkeleton } from '../AdGrid/AdGridSkeleton';
import NoAds from '../NoAds/NoAds';
import NoSearchResults from '../NoSearchResults/NoSearchResults';
import { RootState } from 'app/store/store';
import { selectFilteredAds } from 'features/ads/selectors';

import styles from './AdsContent.module.scss';

interface PageContentProps {
	adSource: string;
	isLoading: boolean;
	error: Error | null;
	tags?: Tag[];
	tagId?: number | null;
	searchBox?: boolean;
	cardStyle?: boolean;
	hasNextPage: boolean;
	isFetchingNextPage: boolean;
	isSuccess: boolean;
	publicView?: boolean;
	totalAds?: number;
	isLoadingTotal?: boolean;
	externalView?: boolean;
}

const PageContent: FC<PageContentProps> = ({
	adSource,
	totalAds,
	isLoading,
	error,
	tags,
	tagId,
	hasNextPage,
	isFetchingNextPage,
	searchBox = false,
	cardStyle = false,
	publicView,
	isLoadingTotal,
	externalView,
}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { handleSearch, handleResetSearch } = useSearchHook();
	const [showPromo, setShowPromo] = useState(false);
	const searchConducted = useSelector((state: RootState) => state.search.searchConducted);
	const searchTerm = useSelector((state: RootState) => state.search.searchTerm);
	const ads = useSelector((state: RootState) => selectFilteredAds(state, adSource));

	const { filters, resetFilters } = useContext(FilterContext);

	useEffect(() => {
		setTimeout(() => {
			setShowPromo(true);
		}, 1500);
	}, []);

	useEffect(() => {
		setShowPromo(false);
		setTimeout(() => {
			setShowPromo(true);
		}, 1500);
	}, [searchTerm]);

	const AddNewAd = () => {
		navigate(RoutesEnum.NEWAD, { state: { activeTab: 0 } });
	};

	const controlsMemo = useMemo(
		() => (
			<Box
				sx={{
					position: 'sticky',
					top: '0',
					backgroundColor: 'var(--background-default)',
					zIndex: 1000,
				}}
			>
				<AdsControls
					isLoading={isLoading}
					isLoadingTotal={isLoadingTotal}
					adSource={adSource}
					publicView={publicView}
					adsCount={totalAds}
					searchBox={searchBox}
					cardStyle={cardStyle}
					tags={tags}
					tagId={tagId}
					handleSearch={handleSearch}
				/>
			</Box>
		),
		[
			isLoadingTotal,
			publicView,
			totalAds,
			searchBox,
			cardStyle,
			tags,
			tagId,
			handleSearch,
			adSource,
			isLoading,
		]
	);

	if (error) {
		return (
			<ContentContainer>
				{controlsMemo}
				<NoAds error />
			</ContentContainer>
		);
	}

	const renderContent = () => {
		if (isLoading) {
			return <AdGridSkeleton cardStyle={cardStyle} />;
		}

		if (ads?.length === 0) {
			if (searchConducted && searchTerm !== '') {
				return <NoSearchResults resetSearch={handleResetSearch} />;
			} else {
				const isTagPage = location.pathname.includes('/tags/');
				const hasFilters = filters.some((filter) => filter.filterNames.length > 0);

				return (
					<NoAds
						handleAddNewAd={
							adSource !== 'publicAds' && !hasFilters ? AddNewAd : undefined
						}
						isTagPage={isTagPage}
						noDataMessage={
							hasFilters ? 'No data for the specified criteria.' : undefined
						}
						handleResetFilters={hasFilters ? resetFilters : undefined}
					/>
				);
			}
		}

		return (
			<>
				<AdGrid
					publicView={publicView}
					hasNextPage={hasNextPage}
					isLoading={isLoading}
					adSource={adSource}
					externalView={externalView}
					isFetchingNextPage={isFetchingNextPage}
				/>
				{showPromo && !hasNextPage && publicView && (
					<div className={styles.promoContainer}>
						<div className={styles.promoContent}>
							<Typography variant="h5" sx={{ textAlign: 'center' }}>
								Like what you see?
							</Typography>
							<Typography
								variant="body1"
								sx={{ textAlign: 'center' }}
								className={styles.promoDesc}
							>
								Discover the exciting world of ads and find inspiration with us!
								Join now to get creative and be inspired by others.
							</Typography>
							<Button
								variant="contained"
								color="primary"
								onClick={() => navigate(RoutesEnum.SIGNUP)}
								sx={{ margin: '20px auto' }}
							>
								Join AdUniverse.ai
							</Button>
						</div>
					</div>
				)}
			</>
		);
	};

	return (
		<ContentContainer sx={{ position: 'relative' }}>
			<Box
				sx={{
					width: '100%',
					backgroundColor: publicView ? '#fff' : 'transparent',
					overflowY: 'hidden',
				}}
			>
				{controlsMemo}
				{renderContent()}
			</Box>
		</ContentContainer>
	);
};

export default PageContent;
