import React, { SyntheticEvent, useState } from 'react';

import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';

import { CheckIcon } from 'shared/components/Icons';
import useScrollLock from 'shared/hooks/useScrollLock';

import styles from '../AdsControls.module.scss';

interface ZoomControlProps {
	zoom: number;
	setZoom: (value: number) => void;
}

type ZoomLevels = {
	[key: string]: number;
};

const zoomLevels: ZoomLevels = {
	'90': 5,
	'100': 4,
	'110': 3,
	'120': 2,
};

const ZoomControl: React.FC<ZoomControlProps> = ({ zoom, setZoom }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	useScrollLock(anchorEl);

	const handleZoomChange = (event: SelectChangeEvent) => {
		const newZoomValue = parseInt(event.target.value, 10);
		if (!isNaN(newZoomValue)) {
			setZoom(newZoomValue);
		}
	};

	const handleMenuOpen = (event: SyntheticEvent<Element, Event>) => {
		setAnchorEl(event.currentTarget as HTMLElement);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const zoomOptions = Object.entries(zoomLevels).map(([key]) => ({
		label: `${key}%`,
		value: parseInt(key, 10),
	}));

	return (
		<FormControl
			variant="outlined"
			size="small"
			className={`${styles.formControl} ${styles.zoomControl}`}
		>
			<InputLabel id="zoom-select-label" className={styles.selectInputLabel}>
				Zoom:
			</InputLabel>
			<Select
				className={styles.zoomSelect}
				labelId="zoom-select-label"
				value={String(zoom)}
				onChange={handleZoomChange}
				onOpen={handleMenuOpen}
				onClose={handleMenuClose}
				renderValue={(selected) => (
					<Box className={styles.zoomValue}>
						<Typography variant="body3">{`${selected}%`}</Typography>
					</Box>
				)}
				MenuProps={{
					PaperProps: {
						sx: {
							width: 140,
						},
					},
				}}
			>
				{zoomOptions.map((option) => (
					<MenuItem key={option.value} value={option.value} className={styles.zoomOption}>
						<Box className={styles.zoomOption_content}>
							<Typography
								style={{ fontWeight: zoom === option.value ? 'bold' : 'regular' }}
							>
								{option.label}
							</Typography>
							{zoom === option.value && (
								<CheckIcon
									sx={{
										width: '14px',
										height: '14px',
									}}
								/>
							)}
						</Box>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default ZoomControl;
