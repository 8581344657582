import { SvgIcon, SvgIconProps } from '@mui/material';

export const HeartRoundedIconOutlined = (props: SvgIconProps) => {
	return (
		<SvgIcon width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
			<path
				d="M11.2407 2C13.5889 2 15.1667 4.235 15.1667 6.32C15.1667 10.5425 8.61852 14 8.5 14C8.38148 14 1.83333 10.5425 1.83333 6.32C1.83333 4.235 3.41111 2 5.75926 2C7.10741 2 7.98889 2.6825 8.5 3.2825C9.01111 2.6825 9.89259 2 11.2407 2Z"
				stroke="#009EC2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
};
