import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import { Header } from '../Header';

import styles from './Layout.module.scss';

interface LayoutProps {
	headerTitle?: string;
	mobileLayout?: boolean;
	children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ headerTitle, mobileLayout, children }) => {
	return (
		<Box
			className={styles.containerLayout}
			sx={{ padding: mobileLayout ? '1.5rem' : '2.5rem' }}
		>
			<Header title={headerTitle} />
			<main className={styles.width} style={{ width: !mobileLayout ? '24.125rem' : 'unset' }}>
				{children}
			</main>
		</Box>
	);
};

export default Layout;
