import { HttpStatusCodeEnum } from '../enums';
import type { AxiosResponse } from 'axios';

function statusChecker<T>(response: AxiosResponse<T>): AxiosResponse<T> {
	if (
		response.status >= HttpStatusCodeEnum.Ok &&
		response.status < HttpStatusCodeEnum.MultipleChoices
	) {
		return response;
	}

	throw response;
}

export default statusChecker;
