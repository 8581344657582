import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import { useActiveSubscriptionInfo } from 'shared/api/hooks/billing/useActiveSubscriptionInfo';
import { useGetDashboard } from 'shared/api/hooks/billing/useGetDashboard';
import { usePlansAndAddOns } from 'shared/api/hooks/billing/usePlansAndAddons';
import { sortByPriceForInterval } from 'shared/helpers/billingHelpers';
import { useGetUser } from 'shared/hooks/useGetUser';

import { useUser } from 'core/UserProvider/useUser';
import { tokenService } from 'core/token/services';
import PlanItemV2 from './components/PlanItemV2/PlanItemV2';

import styles from './BillingAndPlansTab.module.scss';

const useBillingData = () => {
	const { data: user } = useGetUser();
	const { data: dashboardData } = useGetDashboard(user?.id);

	return { user, dashboardData };
};

const BillingAndPlansTabV2 = ({ opacityReduced }: { opacityReduced?: boolean }) => {
	const { user, dashboardData } = useBillingData();
	const navigate = useNavigate();
	const [billingInterval] = useState<'month' | 'year'>('month');
	const { plans } = usePlansAndAddOns(billingInterval);
	const { isSubscriptionActive, activeSubscription } = useActiveSubscriptionInfo(user?.id);
	const {
		actions: { logoutUser },
	} = useUser();

	if (!plans || !user) return null;

	const handleManagePlans = () => {
		if (dashboardData?.session_url) {
			window.location.href = dashboardData?.session_url;
		}
	};

	const handleLogout = () => {
		tokenService.clear();
		logoutUser();
		navigate('/login');
	};

	return (
		<Box className={styles.container}>
			<Box className={styles.plansWrapper} sx={{ opacity: opacityReduced ? 0.1 : 1 }}>
				{sortByPriceForInterval(plans, billingInterval).map((plan) => (
					<PlanItemV2
						key={plan.id}
						item={plan}
						interval={billingInterval}
						selectedPlanId={activeSubscription?.plan.product}
						billingInterval={billingInterval}
					/>
				))}
			</Box>
			<Box
				sx={{ width: '100%' }}
				display="flex"
				justifyContent={opacityReduced && !isSubscriptionActive ? 'center' : 'start'}
				alignItems="center"
			>
				{opacityReduced && !isSubscriptionActive ? (
					<Button
						variant="outlined"
						onClick={handleLogout}
						color="primary"
						sx={{ width: '230px' }}
					>
						Cancel for now and Log out
					</Button>
				) : (
					<Button
						variant="outlined"
						onClick={handleManagePlans}
						color="primary"
						disabled={!dashboardData?.session_url}
						sx={{ width: '230px' }}
					>
						Manage plans
					</Button>
				)}
			</Box>
		</Box>
	);
};

export default BillingAndPlansTabV2;
