import { SvgIcon, SvgIconProps } from '@mui/material';

export const ExternalLinkIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
			<path
				d="M12.4998 13.8333H4.49984C4.01361 13.8333 3.54729 13.6402 3.20347 13.2964C2.85966 12.9525 2.6665 12.4862 2.6665 12V3.99999C2.6665 3.51376 2.85966 3.04744 3.20347 2.70363C3.54729 2.35981 4.01361 2.16666 4.49984 2.16666H8.49984C8.63245 2.16666 8.75962 2.21933 8.85339 2.3131C8.94716 2.40687 8.99984 2.53405 8.99984 2.66666C8.99984 2.79926 8.94716 2.92644 8.85339 3.02021C8.75962 3.11398 8.63245 3.16666 8.49984 3.16666H4.49984C4.27882 3.16666 4.06686 3.25445 3.91058 3.41073C3.7543 3.56701 3.6665 3.77898 3.6665 3.99999V12C3.6665 12.221 3.7543 12.433 3.91058 12.5892C4.06686 12.7455 4.27882 12.8333 4.49984 12.8333H12.4998C12.7208 12.8333 12.9328 12.7455 13.0891 12.5892C13.2454 12.433 13.3332 12.221 13.3332 12V7.99999C13.3332 7.86738 13.3858 7.7402 13.4796 7.64644C13.5734 7.55267 13.7006 7.49999 13.8332 7.49999C13.9658 7.49999 14.093 7.55267 14.1867 7.64644C14.2805 7.7402 14.3332 7.86738 14.3332 7.99999V12C14.3332 12.4862 14.14 12.9525 13.7962 13.2964C13.4524 13.6402 12.9861 13.8333 12.4998 13.8333Z"
				fill="currentColor"
			/>
			<path
				d="M13.8332 5.83332C13.7011 5.8316 13.5749 5.77836 13.4815 5.68497C13.3881 5.59157 13.3349 5.46539 13.3332 5.33332V3.16666H11.1665C11.0339 3.16666 10.9067 3.11398 10.813 3.02021C10.7192 2.92644 10.6665 2.79926 10.6665 2.66666C10.6665 2.53405 10.7192 2.40687 10.813 2.3131C10.9067 2.21933 11.0339 2.16666 11.1665 2.16666H13.8332C13.9652 2.16838 14.0914 2.22162 14.1848 2.31501C14.2782 2.40841 14.3314 2.53459 14.3332 2.66666V5.33332C14.3314 5.46539 14.2782 5.59157 14.1848 5.68497C14.0914 5.77836 13.9652 5.8316 13.8332 5.83332Z"
				fill="currentColor"
			/>
			<path
				d="M9.49984 7.49999C9.37121 7.49015 9.25155 7.43032 9.16651 7.33332C9.08451 7.24165 9.03918 7.12298 9.03918 6.99999C9.03918 6.877 9.08451 6.75833 9.16651 6.66666L13.4998 2.33332C13.5456 2.2842 13.6008 2.2448 13.6621 2.21747C13.7235 2.19014 13.7897 2.17545 13.8568 2.17426C13.924 2.17308 13.9906 2.18543 14.0529 2.21057C14.1152 2.23572 14.1717 2.27315 14.2192 2.32063C14.2667 2.36811 14.3041 2.42467 14.3293 2.48692C14.3544 2.54918 14.3668 2.61587 14.3656 2.683C14.3644 2.75014 14.3497 2.81635 14.3224 2.87768C14.295 2.93901 14.2556 2.99421 14.2065 3.03999L9.83317 7.33332C9.74813 7.43032 9.62847 7.49015 9.49984 7.49999Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
