import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { MobileScreenInfo } from '../MobileScreenInfo';

import styles from './GlobalOverlay.module.scss';

const GlobalOverlay: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 1024);
		};

		handleResize();

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	if (isSmallScreen) {
		return (
			<Box className={styles.infoScreen}>
				<MobileScreenInfo />
			</Box>
		);
	}

	return <>{children}</>;
};

export default GlobalOverlay;
