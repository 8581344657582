import { tagsApiService } from 'shared/api/services/tags/TagsApiService';
import { TagsResponse } from 'shared/models/tags';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useGetTags = (userId: number | undefined): UseQueryResult<TagsResponse, Error> => {
	return useQuery<TagsResponse, Error>({
		queryKey: ['tags'],
		queryFn: async () => await tagsApiService.getTags(userId),
		enabled: !!userId,
	});
};
