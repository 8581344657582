import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { authApiService } from 'app/pages/auth/service';
import { ToastContext } from 'core/toast/ToastProvider';

const msgSuccessRestPassword =
	'Your password has been successfully reset. You can now log in with your new password.';
// The bellow messge can be corrected if neccessary.
const msgErrorRestPassword = 'Reset password error';

export const usePostResetPassword = (): UseMutationResult<unknown, unknown, unknown, unknown> => {
	const toast = useContext(ToastContext);
	const navigate = useNavigate();

	return useMutation({
		mutationFn: (data) => authApiService.postResetPassword(data),
		onSuccess: () => {
			toast.open('Success', msgSuccessRestPassword, {
				severity: 'success',
			});

			navigate('/login');
		},
		onError: () => {
			toast.open('Error', msgErrorRestPassword, {
				severity: 'error',
			});
		},
	});
};

export default usePostResetPassword;
