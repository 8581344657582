import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';



import { useGetPublicTagAds } from 'shared/api/hooks/public/useGetPublicTagAds';
import { Ad } from 'shared/models/ads';


export const usePublicAdsData = (token: string, sort: string) => {
	const searchTerm = useSelector((state: any) => state.search.searchTerm);

	const [filteredAds, setFilteredAds] = useState<Ad[]>();
	const [tagOwner, setTagOwner] = useState('');
	const [tagName, setTagName] = useState('');
	
	const [totalAds, setTotalAds] = useState<number>(0);

	const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isSuccess } =
		useGetPublicTagAds(token, sort, searchTerm);

	useEffect(() => {
		if (data?.pages) {
			const newAds = data.pages.flatMap((page) => page.ads);
			setFilteredAds(newAds);
			setTagOwner(data.pages[0].full_name);
			setTagName(data.pages[0].tag_name);
			setTotalAds(data.pages[0]?.total_in_tags || 0);
		}
	}, [data?.pages]);

	const hasAds = filteredAds && filteredAds?.length > 0;

	return {
		filteredAds,
		hasAds,
		totalAds,
		tagName,
		tagOwner,
		isLoading,
		error,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isSuccess,
	};
};