import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { Layout } from 'app/pages/auth/components/common/Layout';

import styles from './MobileScreenInfo.module.scss';

const MobileScreenInfo: FC = () => {
	return (
		<Box className={styles.mobileInfoContainer}>
			<Layout mobileLayout>
				<Box className={styles.mobileInfoWrapper}>
					<Typography variant='body1' className={styles.mobileInfoContent}>
						To get started, please use our <span>web application</span> to log in or
						sign up, as the mobile version is still on its way!
					</Typography>
					<Typography variant="h4" sx={{ marginTop: '1.5rem' }}>
						Thank you!
					</Typography>
					<Link to="https://www.aduniverse.ai">
						<Button variant="outlined" fullWidth sx={{ mt: '1.5rem' }}>
							Back to the home page
						</Button>
					</Link>
				</Box>
			</Layout>
		</Box>
	);
};

export default MobileScreenInfo;
