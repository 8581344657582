import { publicService } from 'shared/api/services/public/publicService';

import { useInfiniteQuery } from '@tanstack/react-query';

export const useGetPublicTagAds = (token: string, sort: string, search: string = '') => {
	const fetchPublicTagAds = ({ pageParam = 1 }) => {
		return publicService.getPublicTagAds(token, pageParam, sort, search);
	};

	return useInfiniteQuery({
		queryKey: ['external-ads', { sort, search }],
		queryFn: fetchPublicTagAds,
		getNextPageParam: (lastPage, allPages) => {
			const PAGE_SIZE = 30;
			const allFetchedAdsCount = allPages.reduce((total, page) => total + page.ads.length, 0);
			const isMoreAdsAvailable = allFetchedAdsCount < lastPage.total;
			if (!isMoreAdsAvailable) {
				return undefined;
			}
			const nextPage = Math.floor(allFetchedAdsCount / PAGE_SIZE) + 1;
			return nextPage <= Math.ceil(lastPage.total / PAGE_SIZE) ? nextPage : undefined;
		},
		enabled: !!token,
		initialPageParam: 1,
	});
};
