import { SvgIcon, SvgIconProps } from '@mui/material';

export const HashtagIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M17.5 6.04166H15L15.6417 3.48333C15.6814 3.32199 15.6555 3.15145 15.5696 3.00924C15.4836 2.86702 15.3447 2.76478 15.1833 2.72499C15.022 2.68521 14.8515 2.71115 14.7092 2.7971C14.567 2.88306 14.4648 3.02199 14.425 3.18333L13.7083 6.04166H8.33333L8.975 3.48333C9.01478 3.32199 8.98884 3.15145 8.90289 3.00924C8.81693 2.86702 8.67801 2.76478 8.51667 2.72499C8.35533 2.68521 8.18479 2.71115 8.04258 2.7971C7.90036 2.88306 7.79812 3.02199 7.75833 3.18333L7.00833 6.04166H4.16667C4.00091 6.04166 3.84194 6.10751 3.72472 6.22472C3.60751 6.34193 3.54167 6.5009 3.54167 6.66666C3.54167 6.83242 3.60751 6.99139 3.72472 7.1086C3.84194 7.22581 4.00091 7.29166 4.16667 7.29166H6.66667L5.30833 12.7083H2.5C2.33424 12.7083 2.17527 12.7742 2.05806 12.8914C1.94085 13.0086 1.875 13.1676 1.875 13.3333C1.875 13.4991 1.94085 13.6581 2.05806 13.7753C2.17527 13.8925 2.33424 13.9583 2.5 13.9583H5L4.35833 16.5167C4.31855 16.678 4.34449 16.8485 4.43044 16.9908C4.5164 17.133 4.65533 17.2352 4.81667 17.275C4.97801 17.3148 5.14854 17.2888 5.29076 17.2029C5.43297 17.1169 5.53522 16.978 5.575 16.8167L6.29167 13.9583H11.6667L11.025 16.5167C10.9852 16.678 11.0112 16.8485 11.0971 16.9908C11.1831 17.133 11.322 17.2352 11.4833 17.275C11.6447 17.3148 11.8152 17.2888 11.9574 17.2029C12.0996 17.1169 12.2019 16.978 12.2417 16.8167L12.9583 13.9583H15.8333C15.9991 13.9583 16.1581 13.8925 16.2753 13.7753C16.3925 13.6581 16.4583 13.4991 16.4583 13.3333C16.4583 13.1676 16.3925 13.0086 16.2753 12.8914C16.1581 12.7742 15.9991 12.7083 15.8333 12.7083H13.3333L14.6917 7.29166H17.5C17.6658 7.29166 17.8247 7.22581 17.9419 7.1086C18.0592 6.99139 18.125 6.83242 18.125 6.66666C18.125 6.5009 18.0592 6.34193 17.9419 6.22472C17.8247 6.10751 17.6658 6.04166 17.5 6.04166ZM13.3333 7.29166L11.975 12.7083H6.66667L8.025 7.29166H13.3333Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
