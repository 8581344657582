import { FC, useEffect, useState } from 'react';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography,
} from '@mui/material';

import { useUpdateAdTags } from 'shared/api/hooks/ads/useUpdateAdTags';
import { ChevronDownIcon, HashtagIcon } from 'shared/components/Icons';
import { useAdsData } from 'shared/hooks/useAdsData';
import { useTags } from 'shared/hooks/useTags';
import { Ad } from 'shared/models/ads';

import { useUser } from 'core/UserProvider/useUser';

import styles from './Organization.module.scss';

interface OrganizationProps {
	ad: Ad | undefined;
}

const Organization: FC<OrganizationProps> = ({ ad }) => {
	const { user } = useUser();
	const { tags, isLoading } = useTags(user?.id);
	const { data } = useAdsData('userAds', user?.id);
	const updateAdTags = useUpdateAdTags();
	const [checkedTagIds, setCheckedTagIds] = useState<number[]>([]);
	const [expanded, setExpanded] = useState<string | false>(false);
	const [isChanged, setIsChanged] = useState(false);
	useEffect(() => {
		if (ad && data) {
			const currentAd = data.find((filteredAd) => filteredAd._id === ad._id);
			if (currentAd && currentAd.tags) {
				const tagIds = currentAd.tags.map((tag) => tag.id);
				setCheckedTagIds(tagIds);
			} else {
				setCheckedTagIds([]);
			}
			setIsChanged(false);
		}
	}, [ad, data]);

	useEffect(() => {
		const currentAd = data?.find((ad) => ad._id === ad._id);
		if (currentAd && currentAd.tags) {
			const initialTagIds = currentAd.tags.map((tag) => tag.id).sort();
			const areTagsChanged =
				JSON.stringify(initialTagIds) !== JSON.stringify([...checkedTagIds].sort());
			setIsChanged(areTagsChanged);
		}
	}, [checkedTagIds, data, ad]);

	const handleAccordionChange = (panel: string) => (_: unknown, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleTagChange = (tagId: number) => {
		setCheckedTagIds((prev) =>
			prev.includes(tagId) ? prev.filter((id) => id !== tagId) : [...prev, tagId]
		);
	};

	const handleSaveChanges = () => {
		if (user && ad && ad.user_ad_id) {
			updateAdTags.mutate({
				userId: user.id,
				adId: ad.user_ad_id,
				tagData: checkedTagIds,
			});
		}
	};

	if (isLoading) {
		return <Typography>Loading...</Typography>;
	}

	return (
		<Box className={styles.tabContent}>
			<Box className={styles.organizationContainer}>
				<Box className={styles.organizationHeader}>
					<Box className={styles.organizationHeader__title}>
						<HashtagIcon sx={{ width: '24px', height: '24px' }} />
						<Typography variant="body2">Tags</Typography>
					</Box>
					<Typography className={styles.organizationHeader__desc}>
						This Ad is currently saved in the selected tag, see below. If you want to
						save this Ad to other Tags also, feel free to select the Tags below and save
						the changes.
					</Typography>
				</Box>
				<Box className={styles.tagsWrapper}>
					<FormGroup className={styles.tagsContainer}>
						<FormControlLabel
							control={
								<Checkbox
									checked={true}
									onChange={() => {}}
									disabled={true}
									checkedIcon={<span className={styles.swipeFileIcon} />}
								/>
							}
							label={'# My collection'}
							className={styles.swipeFile}
						/>
						{tags.map((tag, index) => (
							<Accordion
								key={index}
								expanded={expanded === `panel${index}`}
								onChange={handleAccordionChange(`panel${index}`)}
								defaultExpanded={false}
								sx={{
									'border': 'none',
									'& .MuiAccordionSummary-content': {
										margin: '0',
									},
									'&.MuiPaper-root.MuiAccordion-root::before': {
										content: 'none',
									},
									'boxShadow': 'none',
									'&.MuiPaper-root.MuiAccordion-root': {
										'&.Mui-expanded': {
											margin: '0',
										},
									},
									'& .MuiAccordionSummary-root.Mui-expanded': {
										'minHeight': 'unset',
										'&.MuiAccordionSummary-content': {
											margin: '16px 0 0 0',
										},
									},
								}}
							>
								<AccordionSummary
									aria-controls={`panel${index}bh-content`}
									id={`panel${index}bh-header`}
									expandIcon={
										tag.subTags && tag.subTags.length > 0 ? (
											<ChevronDownIcon
												sx={{ width: '14px', height: '14px' }}
											/>
										) : null
									}
								>
									<FormControlLabel
										onClick={(e) => e.stopPropagation()}
										onFocus={(e) => e.stopPropagation()}
										sx={{
											'& .MuiFormControlLabel-label': {
												fontWeight: checkedTagIds.includes(tag.id)
													? 600
													: 400,
											},
										}}
										label={`# ${tag.name}`}
										control={
											<Checkbox
												checked={checkedTagIds.includes(tag.id)}
												onChange={() => handleTagChange(tag.id)}
												icon={<span className={styles.uncheckedCheckbox} />}
												checkedIcon={
													<span className={styles.checkedCheckbox} />
												}
											/>
										}
									/>
								</AccordionSummary>
								<AccordionDetails
									sx={{
										'&.MuiAccordionDetails-root': {
											padding: '0 40px',
										},
									}}
								>
									<FormGroup>
										{tag.subTags?.map((childTag) => (
											<FormControlLabel
												key={childTag.id}
												control={
													<Checkbox
														icon={
															<span
																className={styles.uncheckedCheckbox}
															/>
														}
														checkedIcon={
															<span
																className={styles.checkedCheckbox}
															/>
														}
														checked={checkedTagIds.includes(
															childTag.id
														)}
														onChange={() =>
															handleTagChange(childTag.id)
														}
													/>
												}
												sx={{
													'& .MuiFormControlLabel-label': {
														fontWeight: checkedTagIds.includes(
															childTag.id
														)
															? 600
															: 400,
													},
												}}
												label={`# ${childTag.name}`}
											/>
										))}
									</FormGroup>
								</AccordionDetails>
							</Accordion>
						))}
					</FormGroup>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSaveChanges}
						disabled={!isChanged}
					>
						Save Changes
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default Organization;
