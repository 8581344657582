import React from 'react';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';

import noAds from 'assets/images/no-results-large.png';
import noTagAds from 'assets/images/no-tag-ads.png';

import styles from 'app/pages/swipeFile/SwipeFile.module.scss';

interface NoAdsProps {
	handleAddNewAd?: VoidFunction;
	handleResetFilters?: VoidFunction;
	error?: boolean;
	isTagPage?: boolean;
	noDataMessage?: string;
}

const NoAds: React.FC<NoAdsProps> = ({
	handleAddNewAd,
	handleResetFilters,
	error,
	isTagPage,
	noDataMessage,
}) => {
	const navigate = useNavigate();
	return (
		<Box className={styles.noResults} p={2}>
			<Box mb={2}>
				<img src={isTagPage ? noTagAds : noAds} alt="No ads" loading="lazy" width={320} height={260} />{' '}
			</Box>
			{error ? (
				<Typography variant="h6" color="error">
					Something went wrong.
				</Typography>
			) : noDataMessage ? (
				<>
					<Typography variant="h5" sx={{ mb: 2 }}>
						{noDataMessage}
					</Typography>
					{handleResetFilters && (
						<Button variant="contained" color="primary" onClick={handleResetFilters}>
							Reset Filters
						</Button>
					)}
				</>
			) : isTagPage ? (
				<>
					<Typography variant="h5" sx={{ mb: 2 }}>
						No Ads favorited yet
					</Typography>
					<Typography
						variant="body1"
						sx={{ mb: 2, maxWidth: '350px', textAlign: 'center' }}
					>
						Discover ads, save inspirations, and streamline the process with our
						plugin—effortlessly saving ads from your social media accounts to your
						library.
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => navigate('/discovery')}
					>
						Discover Ads
					</Button>
				</>
			) : (
				<>
					<Typography sx={{ mb: 2 }} variant="h5">
						You have no saved ads yet
					</Typography>

					{handleAddNewAd && (
						<Button
							variant="contained"
							color="primary"
							endIcon={<AddIcon />}
							onClick={handleAddNewAd}
						>
							New ad
						</Button>
					)}
				</>
			)}
		</Box>
	);
};

export default NoAds;
