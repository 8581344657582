import { SvgIcon, SvgIconProps } from '@mui/material';

export const HeartIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M10 16.4583C9.8343 16.4582 9.67544 16.3922 9.55834 16.275L3.91667 10.6167C3.15323 9.83779 2.7256 8.79064 2.7256 7.7C2.7256 6.60936 3.15323 5.5622 3.91667 4.78333C4.69025 4.00983 5.73939 3.5753 6.83334 3.5753C7.92728 3.5753 8.97643 4.00983 9.75001 4.78333L10 5L10.2333 4.76666C10.6158 4.38 11.0714 4.07329 11.5736 3.86441C12.0758 3.65553 12.6145 3.54865 13.1583 3.55C13.7016 3.54756 14.24 3.65313 14.7421 3.86057C15.2443 4.068 15.7002 4.37317 16.0833 4.75833C16.8468 5.5372 17.2744 6.58436 17.2744 7.675C17.2744 8.76564 16.8468 9.8128 16.0833 10.5917L10.4417 16.25C10.3858 16.3126 10.3179 16.3633 10.242 16.3991C10.1661 16.4349 10.0838 16.455 10 16.4583ZM6.84167 4.79166C6.4632 4.78891 6.08792 4.86113 5.7375 5.00416C5.38708 5.14719 5.06846 5.35819 4.80001 5.625C4.25907 6.16846 3.95538 6.90404 3.95538 7.67083C3.95538 8.43762 4.25907 9.1732 4.80001 9.71667L10 14.95L15.1917 9.73333C15.4605 9.46478 15.6738 9.14586 15.8193 8.79482C15.9648 8.44378 16.0397 8.0675 16.0397 7.6875C16.0397 7.30749 15.9648 6.93121 15.8193 6.58018C15.6738 6.22914 15.4605 5.91022 15.1917 5.64166C14.9253 5.37436 14.6083 5.16295 14.2591 5.01985C13.9099 4.87674 13.5357 4.80483 13.1583 4.80833C12.7799 4.80558 12.4046 4.8778 12.0542 5.02083C11.7038 5.16385 11.3851 5.37486 11.1167 5.64166L10.4417 6.31666C10.3224 6.42979 10.1644 6.49286 10 6.49286C9.83565 6.49286 9.67756 6.42979 9.55834 6.31666L8.88334 5.64166C8.61626 5.37185 8.29826 5.15777 7.94777 5.01186C7.59729 4.86594 7.22132 4.79109 6.84167 4.79166Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
