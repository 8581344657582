import { useContext } from 'react';

import { Ad } from 'shared/models/ads';

import { notesApiService } from '../service/NotesApiService';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastContext } from 'core/toast/ToastProvider';

interface Note {
	id: string | undefined;
	userId: number;
	content: string;
	is_private: boolean;
}

export const usePostNote = (adId: string | undefined, userId: number | undefined) => {
	const toast = useContext(ToastContext);
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data: {
			userId: number;
			content: string;
			adId: string | undefined;
			is_private: boolean;
		}) => notesApiService.postNote(data.userId, data.content, data.adId, data.is_private),
		onMutate: async (newNote) => {
			await queryClient.cancelQueries({
				queryKey: ['ad', userId, adId].filter(Boolean),
			});
			const previousAd = queryClient.getQueryData<Ad>(['ad', userId, adId]);

			if (previousAd) {
				queryClient.setQueryData<Ad>(['ad', userId, adId], {
					...previousAd,
					notes: [...previousAd.notes, { ...newNote, id: 'tempId' }],
				});
			}

			return { previousAd };
		},
		onSuccess: (data: Note) => {
			toast.open('Success', 'Note posted successfully', { severity: 'success' });
			const newNote: any = {
				...data,
				text: data.content,
				id: data.id,
				is_private: data.is_private,
			};
			queryClient.setQueryData<Ad>(['ad', userId, adId], (previousAd) => {
				if (!previousAd) return undefined;
				const updatedNotes = [...previousAd.notes, newNote];

				return { ...previousAd, notes: updatedNotes };
			});
		},
		onError: (_, __, context: any) => {
			toast.open('Error', 'Error posting note', { severity: 'error' });
			if (context?.previousAd) {
				queryClient.setQueryData(['ad', userId, adId], context.previousAd);
			}
		},
	});
};

export default usePostNote;
