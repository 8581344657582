import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Avatar, Box, Typography } from '@mui/material';

import { useActiveSubscriptionInfo } from 'shared/api/hooks/billing/useActiveSubscriptionInfo';
import { usePlansAndAddOns } from 'shared/api/hooks/billing/usePlansAndAddons';
import Header from 'shared/components/CheckoutComponents/CheckoutHeader';
import BillingToggle from 'shared/components/CheckoutComponents/CheckoutIntervalToggle';
import {
	AddonSelectionV2,
	PlanSelectionV2,
} from 'shared/components/CheckoutComponents/SubscriptionSelection';
import { PaymentModeEnum, RoutesEnum, StripeThemesEnum } from 'shared/enums';
import { useGetUser } from 'shared/hooks/useGetUser';
import { Product, StripeMetadataEnum } from 'shared/models/billing';

import CheckoutForm from './components/CheckoutForm';
import SpecialOfferTimer from './components/SpecialOfferTimer';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import quoteImage from 'assets/images/quote-image.jpeg';

import styles from './Checkout.module.scss';

const stripePromise = loadStripe(
	'pk_test_51PY2L4AHtxoVn5IKL5LXdDrmokWKxdNt0qNyzcDSCjOq0ViRYh7IYMyb1XD6rSTPcWP938yQeXOqsTuWrI1F0V2g008llnXNVb'
);

const stripeOptions: StripeElementsOptions = {
	mode: PaymentModeEnum.Subscription,
	amount: 0,
	currency: 'eur',
	paymentMethodCreation: 'manual',
	appearance: {
		theme: StripeThemesEnum.Stripe,
		variables: { colorPrimaryText: '#262626' },
	},
};

const Checkout = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [billingInterval, setBillingInterval] = useState<'month' | 'year'>('month');
	const { plans, addons, isLoading } = usePlansAndAddOns(billingInterval);
	const { data: user } = useGetUser();
	const {
		activeSubscription,
		isSubscriptionActive,
		activeAddon,
		noSubscription,
		isLoading: activeSubscriptionLoading,
	} = useActiveSubscriptionInfo(user?.id);

	const initialPlan: Product | undefined = location.state?.plan;
	const initialAddon: Product | undefined = location.state?.addon;
	const showBillingPlans: 'show' | undefined = location.state?.showBillingPlans;

	const [selectedPlan, setSelectedPlan] = useState<Product | null>(null);
	const [selectedAddon, setSelectedAddon] = useState<Product | null>(null);
	const [offerExpiry, setOfferExpiry] = useState('');
	const [offerExpired, setOfferExpired] = useState(false);

	useEffect(() => {
		if (!isLoading && plans && addons && !activeSubscriptionLoading) {
			if (noSubscription) {
				navigate(RoutesEnum.HOME);
			}
			if (selectedPlan) {
				return;
			}
			if (initialAddon) {
				setSelectedAddon(initialAddon);
			} else if (activeSubscription) {
				const subscriptionAddon = addons.find((p) => p.id === activeAddon?.plan.product);
				const defaultAddon = addons.find(
					(p) => p.metadata.vs_id === StripeMetadataEnum.SmallAddon
				);
				setSelectedAddon(subscriptionAddon || defaultAddon || null);
			}
			if (initialPlan) {
				setSelectedPlan(initialPlan);
			} else if (activeSubscription) {
				const activePlan = plans.find((p) => p.id === activeSubscription?.plan.product);
				const defaultPlan = plans.find(
					(p) => p.metadata.vs_id === StripeMetadataEnum.BasicPlan
				);
				setSelectedPlan(activePlan || defaultPlan || null);
			}
		}
	}, [
		isLoading,
		addons,
		plans,
		initialPlan,
		activeSubscription,
		activeAddon,
		initialAddon,
		activeSubscriptionLoading,
		selectedPlan,
	]);
	// TODO get initial offer expiry date/time from API when implemented
	useEffect(() => {
		setOfferExpiry(new Date(Date.now() + 5 * 60 * 1001).toISOString());
	}, []);

	if (isLoading || !selectedPlan) {
		return <Typography>Loading plans...</Typography>;
	}
	const handleIntervalClick = (label: 'month' | 'year') => {
		setBillingInterval(label);
	};

	const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const plan = plans?.find((p) => p.name === event.target.value);
		if (plan) {
			setSelectedPlan(plan);
		}
	};

	const handleAddonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const addonName = event.target.value;
		if (addonName === '') {
			setSelectedAddon(null);
			return;
		}
		const addon = addons?.find((a) => a.name === addonName) || null;

		if (selectedAddon?.name === addon?.name) {
			setSelectedAddon(null);
		} else {
			setSelectedAddon(addon);
		}
	};

	const formatPlanLabel = (plan: Product) => (
		<span>
			{plan.name} -{' '}
			<Typography component="span" fontWeight="normal" sx={{ color: '#8c8c8c' }}>
				{plan.description}
			</Typography>
		</span>
	);

	return (
		<Elements stripe={stripePromise} options={stripeOptions}>
			<Box className={styles.checkoutContainer}>
				<Box className={styles.containerLeft__Wrapper}>
					<Header isActiveSubscription={isSubscriptionActive} />
					<Box className={styles.containerLeft}>
						<Box display="flex" justifyContent="space-between" width={1}>
							<BillingToggle
								activeInterval={billingInterval}
								handleIntervalClick={handleIntervalClick}
							/>
							{offerExpiry && (
								<SpecialOfferTimer
									endTime={offerExpiry}
									offerExpired={offerExpired}
									setOfferExpired={setOfferExpired}
								/>
							)}
						</Box>
						<PlanSelectionV2
							disabled={showBillingPlans === 'show'}
							billingInterval={billingInterval}
							subscriptions={plans}
							selectedPlan={selectedPlan}
							handlePlanChange={handlePlanChange}
							formatPlanLabel={formatPlanLabel}
							activeSubscriptionId={activeSubscription?.plan.product}
						/>
						<AddonSelectionV2
							addons={addons}
							billingInterval={billingInterval}
							selectedAddon={selectedAddon}
							handleAddonChange={handleAddonChange}
							formatPlanLabel={formatPlanLabel}
							disabled={!selectedPlan}
							activeSubscriptionAddonId={activeAddon?.plan.product}
						/>

						<Box className={styles.quoteContainer}>
							<Box className={styles.avatarWrapper}>
								<Avatar
									src={quoteImage}
									className={styles.customAvatar}
									alt="Avatar"
								/>
							</Box>
							<Box display="flex" flexDirection="column" gap="8px">
								<Typography variant="h6" className={styles.quoteText}>
									"In 11 years, we've achieved 3,640 successful A/B tests and
									spent over 62 million, all thanks to AdUniverse's insights into
									ad spend and successful creatives!"
								</Typography>
								<Typography variant="h5" sx={{ ml: '16px', fontSize: '16px' }}>
									Chris Erthel
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box className={styles.divider} />
				<Box className={styles.containerRight}>
					<Box className={styles.step}>
						<Typography className={styles.stepTitle} variant="subtitle2">
							3. Billing Details
						</Typography>
						<CheckoutForm
							disabled={!selectedPlan || offerExpired}
							isActiveSubscription={isSubscriptionActive}
							addon={selectedAddon}
							plan={selectedPlan}
							activeSubscriptionLoading={activeSubscriptionLoading}
							billingInterval={billingInterval}
						/>
					</Box>
				</Box>
			</Box>
		</Elements>
	);
};

export default Checkout;
