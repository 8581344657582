import { billingAndPlansApiService } from '../../services/billing/BillingAndPlansApiService';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useUpdatePlanV2 = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (data: {
			user_id: number;
			paymentMethodId: string;
			planPriceId?: string;
			addonPriceId?: string;
			promoCode?: string;
		}) =>
			billingAndPlansApiService.updateSubscriptionV2(
				data.user_id,
				data.paymentMethodId,
				data.planPriceId,
				data.addonPriceId,
				data.promoCode
			),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['taactive_subscriptiongs'] });
		},
	});
};

export default useUpdatePlanV2;
