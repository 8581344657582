import { PayloadAction, createSlice } from '@reduxjs/toolkit';


// type ToggleOption = 'adTypes' | 'estimatedAdSpend';
type ToggleOption = 'estimatedAdSpend';

const initialState = {
	//TODO - use again when ad types is active
	// adTypes: true,
	estimatedAdSpend: true,
};

const toggleOptionsSlice = createSlice({
	name: 'toggleOptions',
	initialState,
	reducers: {
		toggleOption: (state, action: PayloadAction<{ option: ToggleOption }>) => {
			const { option } = action.payload;
			state[option] = !state[option];
		},
	},
});

export const { toggleOption } = toggleOptionsSlice.actions;
export default toggleOptionsSlice.reducer;