import { Box, Typography } from '@mui/material';

const renderFormatedDuration = (startDate?: string | Date, endDate?: string | Date) => {
	if (!startDate || !endDate) {
		return null;
	}

	const start = new Date(startDate);
	const end = new Date(endDate);

	const diffInMs = end.getTime() - start.getTime();

	const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

	if (diffInDays <= 60) {
		const days = Math.round(diffInDays);

		if (days === 0) {
			return (
				<Typography>
					<Box component="span" fontWeight="600">
						1
					</Box>{' '}
					day
				</Typography>
			);
		}

		const dayLabel = days === 1 ? 'day' : 'days';
		return (
			<Typography>
				<Box component="span" fontWeight="600">
					{days}
				</Box>{' '}
				{dayLabel}
			</Typography>
		);
	}

	if (diffInDays > 60 && diffInDays <= 365) {
		const weeks = Math.round(diffInDays / 7);
		const weekLabel = weeks === 1 ? 'week' : 'weeks';
		return (
			<Typography>
				<Box component="span" fontWeight="600">
					{weeks}
				</Box>{' '}
				{weekLabel}
			</Typography>
		);
	}

	if (diffInDays > 365) {
		const years = Math.floor(diffInDays / 365);
		return (
			<Typography>
				<Box component="span" fontWeight="600">
					+{years}
				</Box>{' '}
				year{years > 1 ? 's' : ''}
			</Typography>
		);
	}

	return null;
};

export default renderFormatedDuration;
