import { FC, lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';



import { PrivateRoute } from 'shared/components/PrivateRoute';
import { PublicRoute } from 'shared/components/PublicRoute';
import { FilterProvider } from 'shared/context/FilterContext';
import { RoutesEnum } from 'shared/enums/RoutesEnum';



import * as Sentry from "@sentry/react";
import { GoogleCallback, Login, Signup, VerifyEmail } from 'app/pages/auth';
import { useUser } from 'core/UserProvider/useUser';
import { ConfirmEmail } from './pages/auth/components/ConfirmEmail';
import PasswordResetFlow from './pages/auth/components/PasswordResetFlow/PasswordResetFlow';
import Checkout from './pages/checkout';
import ExternalTagFeature from './pages/externalTagFeature';
import PaymentStatus from './pages/paymentStatus';
import SubscriptionConfirmation from './pages/subscriptionConfirmation';


const TopTenAds = lazy(() => import('./pages/topTenAds'));
const SwipeFile = lazy(() => import('./pages/swipeFile'));
const Tracker = lazy(() => import('./pages/tracker'));
const BrandDetails = lazy(() => import('./pages/brandDetails'));
const TagFeature = lazy(() => import('./pages/tagFeature'));
const Settings = lazy(() => import('./pages/settings'));
const PublicAdDetails = lazy(() => import('./pages/publicAdDetails'));
const NewAd = lazy(() => import('./pages/newAd'));
const Discovery = lazy(() => import('./pages/discovery'));
const Overview = lazy(() => import('./pages/overview'));

const App: FC = () => {
	const location = useLocation();
	const { user } = useUser();

	const [contentScriptReady, setContentScriptReady] = useState(false);

	useEffect(() => {
		const handleContentScriptReady = (event: MessageEvent) => {
			if (
				event.source === window &&
				event.data &&
				event.data.type === 'CONTENT_SCRIPT_READY' &&
				event.data.source === 'vs-content-script'
			) {
				setContentScriptReady(true);
			}
		};

		window.addEventListener('message', handleContentScriptReady);

		return () => {
			window.removeEventListener('message', handleContentScriptReady);
		};
	}, []);

	useEffect(() => {
		if (user && contentScriptReady) {
			window.postMessage({ type: 'SET_USER', user, source: 'adUniverse' }, '*');
		}
	}, [user, contentScriptReady]);

	useEffect(() => {
		window.scrollTo(0, 0);
		const body = document.body;
		const noScrollbarRoutes = [
			'/login',
			'/signup',
			'/login/callback',
			'/verify-email',
			'/confirm_email',
			'/reset_password',
		];

		if (noScrollbarRoutes.includes(location.pathname)) {
			body.classList.remove('body-scrollbar-visible');
		} else {
			body.classList.add('body-scrollbar-visible');
		}
	}, [location.pathname]);

    function FallbackComponent() {
		return <div>An error has occurred</div>;
	}

    const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);


	return (
            <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>

		<SentryRoutes>
			<Route element={<PublicRoute />}>
				<Route path={RoutesEnum.LOGIN} element={<Login />} />
				<Route path={RoutesEnum.GOOGLE_CALLBACK} element={<GoogleCallback />} />
				<Route path={RoutesEnum.SIGNUP} element={<Signup />} />
				<Route path={RoutesEnum.VERIFYEMAIL} element={<VerifyEmail />} />
				<Route path={RoutesEnum.CONFIRM_EMAIL} element={<ConfirmEmail />} />
				<Route path={RoutesEnum.RESETPASSWORD} element={<PasswordResetFlow />} />
				<Route
					path={`${RoutesEnum.AD_PUBLIC_DETAILS}/:token`}
					element={<PublicAdDetails />}
				/>
				<Route
					path={`${RoutesEnum.TAG_PUBLIC_VIEW}/:token`}
					element={<ExternalTagFeature />}
				/>
			</Route>
			<Route element={<PrivateRoute />}>
				<Route path={RoutesEnum.HOME} element={<Overview />} />

				<Route
					path={RoutesEnum.SWIPE}
					element={
						<FilterProvider>
							<SwipeFile />
						</FilterProvider>
					}
				>
					<Route path={RoutesEnum.NEWAD} element={<NewAd />} />
				</Route>
				<Route
					path={RoutesEnum.DISCOVERY}
					element={
						<FilterProvider>
							<Discovery />
						</FilterProvider>
					}
				>
					<Route path={RoutesEnum.NEWAD} element={<NewAd />} />
				</Route>
				<Route
					path={RoutesEnum.TOPADS}
					element={
						<FilterProvider>
							<TopTenAds />
						</FilterProvider>
					}
				>
					<Route path={RoutesEnum.NEWAD} element={<NewAd />} />
				</Route>
				<Route path={RoutesEnum.TRACKER} element={<Tracker />} />

				<Route
					path={`${RoutesEnum.BRAND}/:id`}
					element={
						<FilterProvider>
							<BrandDetails />
						</FilterProvider>
					}
				></Route>
				<Route path={`${RoutesEnum.HOME}/:tagId`} element={<TagFeature />} />
				<Route
					path={`${RoutesEnum.TAGS}/:tagId`}
					element={
						<FilterProvider>
							<TagFeature />
						</FilterProvider>
					}
				>
					<Route path={RoutesEnum.NEWAD} element={<NewAd />} />
				</Route>
				<Route path={RoutesEnum.SETTINGS} element={<Settings />} />
				<Route path={RoutesEnum.PLANS} element={<>Plans section!</>} />
				<Route path={`${RoutesEnum.BRAND}/:id`} element={<BrandDetails />} />

				<Route path={RoutesEnum.CHECKOUT} element={<Checkout />} />
				<Route
					path={RoutesEnum.SUBSCRIPTION_CONFIRMATION}
					element={<SubscriptionConfirmation />}
				/>
				<Route path={RoutesEnum.PAYMENTSTATUS} element={<PaymentStatus />} />
			</Route>
			<Route path="*" element={<>404!</>} />
		</SentryRoutes>
            </Sentry.ErrorBoundary>
	);
};

export default App;