import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChevronDownIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="15" height="14" viewBox="0 0 15 14" fill="none" {...props}>
			<path
				d="M7.51744 10.398C7.41269 10.3985 7.3089 10.378 7.21215 10.3379C7.11541 10.2977 7.02766 10.2386 6.95405 10.1641L1.63907 4.84913C1.53306 4.69404 1.48522 4.5065 1.50399 4.31958C1.52276 4.13266 1.60693 3.95838 1.74166 3.82747C1.87639 3.69655 2.05301 3.61742 2.24039 3.60402C2.42777 3.59062 2.61386 3.64382 2.76584 3.75424L7.51744 8.47394L12.269 3.75424C12.4189 3.65709 12.597 3.61306 12.7748 3.62921C12.9527 3.64535 13.1199 3.72073 13.2498 3.84328C13.3797 3.96582 13.4647 4.12842 13.4912 4.30502C13.5177 4.48162 13.4841 4.662 13.3958 4.81724L8.08083 10.1322C8.00996 10.2125 7.92344 10.2775 7.82656 10.3232C7.72968 10.3689 7.62449 10.3944 7.51744 10.398Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
