import { FC, PropsWithChildren } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import theme from './theme';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const MuiProvider: FC<PropsWithChildren> = ({ children }) => {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={createTheme(theme)}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<CssBaseline />
					{children}
				</LocalizationProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default MuiProvider;
