import { useContext } from 'react';

import { tagsApiService } from 'shared/api/services/tags/TagsApiService';
import { TagsResponse } from 'shared/models/tags';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastContext } from 'core/toast/ToastProvider';

export const useUpdateTag = () => {
	const toast = useContext(ToastContext);
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data: { userId: number | undefined; tagId: number; tagName: string }) =>
			tagsApiService.updateTag(data.userId, data.tagId, data.tagName),

		onMutate: async (updatedTag) => {
			await queryClient.cancelQueries({
				queryKey: ['tags'],
			});

			const previousTags: TagsResponse | undefined = queryClient.getQueryData(['tags']);

			if (previousTags) {
				const updatedTags = previousTags.tags.map((tag) => {
					if (tag.id === updatedTag.tagId) {
						return { ...tag, name: updatedTag.tagName };
					} else {
						return tag;
					}
				});

				queryClient.setQueryData(['tags'], {
					tags: updatedTags,
					total: previousTags.total,
				});
			}

			return { previousTags };
		},
		onSuccess: (_, variables) => {
			toast.open('Success', `Tag "${variables.tagName}" updated successfully`, {
				severity: 'success',
			});
			queryClient.invalidateQueries({ queryKey: ['tags'] });
		},
		onError: (_, __, context: any) => {
			toast.open('Error', 'Error updating tag', {
				severity: 'error',
			});
			if (context?.previousTags) {
				queryClient.setQueryData(['tags'], context.previousTags);
			}
		},
	});
};
