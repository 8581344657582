/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useFetchAds } from 'shared/api/hooks/ads/useFetchAds';
import { useFetchFilters } from 'shared/api/hooks/ads/useFetchFilters';
import { FilterContext } from 'shared/context/FilterContext';
import { Ad } from 'shared/models/ads';

import { setSearchResults } from 'features/search/searchSlice';

export const useAdsData = (
	adsType: 'userAds' | 'publicAds',
	userId?: number,
	tagIds: number[] = [],
	brandId?: string,
	topAds?: boolean
) => {
	const location = useLocation();
	const prevPathname = useRef(location.pathname);
	const sortBy = useSelector((state: any) => state.sort.sortValue);
	const { filters, resetFilters } = useContext(FilterContext);
	const searchTerm = useSelector((state: any) => state.search.searchTerm);
	const [brands, setBrands] = useState<string[]>([]);
	const [filteredAds, setFilteredAds] = useState<Ad[] | undefined>([]);
	const dispatch = useDispatch();
	const [isReadyToFetch, setIsReadyToFetch] = useState(false);

	const fetchResult = useFetchAds(
		adsType,
		userId,
		sortBy,
		filters,
		tagIds,
		searchTerm,
		isReadyToFetch,
		brandId,
		topAds
	);

	const { data: filterData } = useFetchFilters(adsType, userId);

	useEffect(() => {
		if (filters.length > 0 && location.pathname !== prevPathname.current) {
			resetFilters();
			setIsReadyToFetch(false);
		} else {
			setIsReadyToFetch(true);
		}
		// eslint-disable-next-line
	}, [location.pathname, filters.length]);

	const allAds = useMemo(() => {
		const pages = fetchResult.data?.pages;
		return pages?.flatMap((page) => page.ads);
	}, [fetchResult.data?.pages]);

	useEffect(() => {
		if (filterData && filterData.brands.length < 1) return;
		const brandsArray: string[] = [];
		filterData?.brands.map((brand) => brandsArray.push(brand));
		setBrands(brandsArray);
	}, [filterData]);

	useEffect(() => {
		if (searchTerm) {
			dispatch(setSearchResults(allAds));
		} else {
			setFilteredAds(allAds);
			dispatch(setSearchResults([]));
		}
	}, [allAds, searchTerm, dispatch]);

	return {
		data: allAds,
		filteredAds,
		totalAdsCount: fetchResult.totalAdsCount,
		isLoading: !fetchResult.isFetched,
		hasNextPage: fetchResult.hasNextPage,
		isFetchingNextPage: fetchResult.isFetchingNextPage,
		error: fetchResult.error,
		fetchNextPage: fetchResult.fetchNextPage,
		isSuccess: fetchResult.isSuccess,
		isLoadingTotal: fetchResult.isLoadingTotal,
		filters,
		brands,
	};
};
