import { useContext } from 'react';

import { publicService } from 'shared/api/services/public/publicService';

import { useMutation } from '@tanstack/react-query';
import { ToastContext } from 'core/toast/ToastProvider';

export const useCopyPublicTagToken = (userId: number | undefined, tagId: number | undefined) => {
	const toast = useContext(ToastContext);

	return useMutation({
		mutationFn: async () => {
			if (!userId || !tagId) {
				throw new Error('User ID and Ad ID are required');
			}

			return await publicService.getPublicTagToken(userId, tagId);
		},

		onSuccess: async (data) => {
			const adLink = `${import.meta.env.VITE_APP_URL}/external/tags/${data.token}`;

			try {
				await navigator.clipboard.writeText(adLink);

				toast.open('Success', 'Folder link copied!', {
					severity: 'success',
				});
			} catch (err) {
				console.error('Failed to copy link!', err);
			}
		},

		onError: (err) => {
			console.error('Error fetching token:', err);

			toast.open('Error', 'Error copying link', { severity: 'error' });
		},
	});
};
