import { FC, PropsWithChildren, ReactNode } from 'react';

import { AppBar, Box, Toolbar } from '@mui/material';

import styles from './HeaderContainer.module.scss';

interface HeaderCompound {
	Left: FC<{ children: ReactNode }>;
	Center: FC<{ children?: ReactNode }>;
	Right: FC<{ children?: ReactNode }>;
}

interface HeaderProps extends PropsWithChildren {
	position?: 'fixed' | 'relative';
	external?: boolean;
}

const HeaderContainer: FC<HeaderProps> & HeaderCompound = ({ children, external }) => {
	return (
		<AppBar
			elevation={0}
			color="inherit"
			style={{
				background: external ? 'var(--background-external-nav)' : '#fff',
			}}
			className={styles.appBar}
		>
			<Toolbar className={styles.toolbar}>{children}</Toolbar>
		</AppBar>
	);
};

HeaderContainer.Left = ({ children }) => <Box className={styles.leftSection}>{children}</Box>;
HeaderContainer.Center = ({ children }) =>
	children ? <div className={styles.centerSection}>{children}</div> : null;
HeaderContainer.Right = ({ children }) =>
	children ? <div className={styles.rightSection}>{children}</div> : null;

export default HeaderContainer;
