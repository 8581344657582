import React, { useContext, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useNavigate } from 'react-router-dom';

import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	TextField,
	Typography,
} from '@mui/material';

import { useCalculatePriceV2 } from 'shared/api/hooks/billing/useCalculateTotal';
import { useGetPaymentMethods } from 'shared/api/hooks/billing/useGetPaymentMethods';
import { useGetPromoCode } from 'shared/api/hooks/billing/useGetPromoCode';
import useUpdateBillingInfo from 'shared/api/hooks/billing/useUpdateBillingInfo';
import useUpdatePlanV2 from 'shared/api/hooks/billing/useUpdatePlan';
import { AddressModeEnum, RoutesEnum } from 'shared/enums';
import {
	getPriceInEuros,
	getPriceIntervalId,
	isEmptyArrayOrUndefined,
} from 'shared/helpers/billingHelpers';
import { useGetUser } from 'shared/hooks/useGetUser';
import { InvoiceObject, Product } from 'shared/models/billing';

import PaymentMethodsDropdown from './PaymentMethodsSelect';
import {
	AddressElement,
	LinkAuthenticationElement,
	PaymentElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { Address, StripeAddressElementOptions } from '@stripe/stripe-js';
import { ToastContext } from 'core/toast/ToastProvider';

import styles from '../Checkout.module.scss';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fpr?: (...args: any[]) => void;
	}
}

interface CheckoutFormProps {
	isActiveSubscription: boolean;
	activeSubscriptionLoading: boolean;
	plan: Product;
	addon: Product | null;
	disabled: boolean;
	billingInterval: 'month' | 'year';
}

const options: StripeAddressElementOptions = {
	mode: AddressModeEnum.Billing,
	autocomplete: { mode: 'automatic' },
	display: {
		name: 'split',
	},
};

const vatRegex =
	/^(ATU\d{8}|BE0\d{9}|BG\d{9,10}|CY\d{8}L|CZ\d{8,10}|DE\d{9}|DK\d{8}|EE\d{9}|EL\d{9}|ES[A-Z]\d{7}[A-Z]|FI\d{8}|FR[A-HJ-NP-Z0-9]{2}\d{9}|GB(\d{9}|\d{12}|(GD|HA)\d{3})|HR\d{11}|HU\d{8}|IE\d{7}[A-W]|IT\d{11}|LT(\d{9}|\d{12})|LU\d{8}|LV\d{11}|MT\d{8}|NL\d{9}B\d{2}|PL\d{10}|PT\d{9}|RO\d{2,10}|SE\d{12}|SI\d{8}|SK\d{10})$/;

const CheckoutForm: React.FC<CheckoutFormProps> = ({
	isActiveSubscription,
	activeSubscriptionLoading,
	addon,
	plan,
	disabled,
	billingInterval,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const { data: user } = useGetUser();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const toast = useContext(ToastContext);
	const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string>('');
	const [billingAddress, setBillingAddress] = useState<Address | null | undefined>(null);
	const [vatFieldVisible, setVatFieldVisible] = useState(false);
	const [vatId, setVatId] = useState('');
	const [validatedVatId, setValidatedVatId] = useState('');
	const [vatError, setVatError] = useState('');
	const [promoCodeFieldVisible, setPromoCodeFieldVisible] = useState(false);
	const [promoCode, setPromoCode] = useState('');
	const [promoCodeError, setPromoCodeError] = useState('');
	const [appliedPromoCode, setAppliedPromoCode] = useState('');
	const [priceValue, setPriceValue] = useState<InvoiceObject | null>(null);
	const intervalAbbrevation = billingInterval === 'month' ? '/mo' : '/yr';

	const { isLoading: isTotalLoading, refetch: triggerCalculatePrice } = useCalculatePriceV2(
		user?.id,
		false,
		getPriceIntervalId(plan.prices, billingInterval),
		addon?.prices ? getPriceIntervalId(addon.prices, billingInterval) : undefined,
		appliedPromoCode
	);
	const { data: paymentMethodsData, isLoading: paymentMethodsLoading } = useGetPaymentMethods(
		user?.id
	);
	const { refetch: triggerFetchPromoCode } = useGetPromoCode(false, promoCode);
	const { mutateAsync: update } = useUpdatePlanV2();
	const { mutateAsync: updateBillingInfo } = useUpdateBillingInfo();
	const isNewPayment =
		selectedPaymentMethodId === 'new_card' || isEmptyArrayOrUndefined(paymentMethodsData);

	useEffect(() => {
		const fetchPrices = async () => {
			setLoading(true);
			try {
				if (!user || !plan.id) {
					setLoading(false);
					return;
				}

				const { data } = await triggerCalculatePrice();
				data && setPriceValue(data);
			} catch (error) {
				const errorMessage =
					error instanceof Error ? error.message : 'An unexpected error occurred';
				toast.open('Error', errorMessage || 'An unexpected error occurred', {
					severity: 'error',
				});
			} finally {
				setLoading(false);
			}
		};

		fetchPrices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [plan, addon, billingInterval, appliedPromoCode]);

	useEffect(() => {
		const updateBillingAndPrices = async () => {
			setLoading(true);
			try {
				if (!user || !billingAddress) {
					setLoading(false);
					return;
				}

				await updateBillingInfo({
					user_id: user.id,
					userAddress: billingAddress,
					taxId: validatedVatId,
				});

				const { data } = await triggerCalculatePrice();
				data && setPriceValue(data);
			} catch (error) {
				const errorMessage =
					error instanceof Error ? error.message : 'An unexpected error occurred';
				toast.open('Error', errorMessage || 'An unexpected error occurred', {
					severity: 'error',
				});
			} finally {
				setLoading(false);
			}
		};

		!loading && updateBillingAndPrices();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validatedVatId, billingAddress, selectedPaymentMethodId]);

	useEffect(() => {
		if (paymentMethodsData && paymentMethodsData.length > 0) {
			setSelectedPaymentMethodId(paymentMethodsData[0].id);
			setBillingAddress(paymentMethodsData[0].billing_details.address);
		}
	}, [paymentMethodsData]);

	const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedPaymentMethodId(event.target.value);
		setBillingAddress(
			paymentMethodsData?.find((paymentMethod) => paymentMethod.id === event.target.value)
				?.billing_details.address
		);
	};

	const handleVatCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setVatFieldVisible(event.target.checked);
		if (!event.target.checked) {
			setValidatedVatId('');
			setVatId('');
			setVatError('');
		}
	};

	const handleVatIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		setVatId(inputValue);

		if (vatRegex.test(inputValue)) {
			setVatError('');
			setValidatedVatId(inputValue);
		} else {
			setValidatedVatId('');
			setVatError('Invalid VAT ID format.');
		}
	};

	const validateVatId = () => {
		if (vatFieldVisible) {
			if (vatId && !vatRegex.test(vatId)) {
				setVatError('Invalid VAT ID format.');
				return false;
			}
			if (!vatId) {
				setVatError('Invalid VAT ID.');
				return false;
			}
		}
		setVatError('');
		return true;
	};

	const handlePromoCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPromoCodeFieldVisible(event.target.checked);
		if (!event.target.checked) {
			setPromoCodeError('');
		}
	};

	const handleApplyPromoCode = async () => {
		if (!promoCode) {
			setPromoCodeError('Please enter a valid promo code.');
			return;
		}

		try {
			const { data } = await triggerFetchPromoCode();

			if (data && data.coupon.valid) {
				setAppliedPromoCode(promoCode);
				setPromoCodeError('');
			} else {
				setPromoCodeError('Invalid promo code');
				setAppliedPromoCode('');
			}
		} catch (e) {
			toast.open('Error', 'An unexpected error occurred', {
				severity: 'error',
			});
			setAppliedPromoCode('');
		}
	};

	const handleRemovePromoCode = () => {
		setPromoCodeError('');
		setPromoCode('');
		setAppliedPromoCode('');
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		setLoading(true);
		event.preventDefault();

		if (!stripe || !elements || !user || !plan.id || !validateVatId()) {
			setLoading(false);
			return;
		}
		try {
			const { error: submitError } = await elements.submit();
			if (submitError) {
				setLoading(false);
				toast.open('Error', submitError.message || 'An unexpected error occurred', {
					severity: 'error',
				});
				return;
			}

			let paymentMethodId: string | null = null;

			if (isNewPayment) {
				const addressElement = elements.getElement(AddressElement);
				const addressResult = await addressElement?.getValue();

				if (!addressResult?.value.address) {
					throw new Error('An unexpected error occurred.');
				}

				await updateBillingInfo({
					user_id: user.id,
					userAddress: addressResult.value.address,
					taxId: vatId,
				});

				const { error, paymentMethod } = await stripe.createPaymentMethod({
					elements,
				});

				if (error) {
					setLoading(false);
					toast.open('Error', error.message || 'An unexpected error occurred', {
						severity: 'error',
					});
					return;
				}

				paymentMethodId = paymentMethod?.id || null;

				if (!paymentMethodId) {
					throw new Error('Failed to create payment method');
				}
			} else {
				paymentMethodId = selectedPaymentMethodId;
			}

			const result = await update({
				user_id: user.id,
				planPriceId: getPriceIntervalId(plan.prices, billingInterval),
				addonPriceId: addon?.prices
					? getPriceIntervalId(addon.prices, billingInterval)
					: undefined,
				paymentMethodId,
				...(promoCode && { promoCode }),
			});

			const { type } = result;

			if (type === 'success') {
				setLoading(false);
				toast.open('Success', 'Subscription updated successfully', {
					severity: 'success',
				});
				navigate(`${RoutesEnum.PAYMENTSTATUS}?redirect_status=updated`);
			} else {
				throw new Error('An unexpected error occurred');
			}
		} catch (error) {
			setLoading(false);
			const errorMessage =
				error instanceof Error ? error.message : 'An unexpected error occurred';
			navigate(`${RoutesEnum.PAYMENTSTATUS}?status=failed`, {
				state: { errorMessage: errorMessage },
			});
		}
	};

	const handleGoBack = () => {
		navigate(-1);
	};

	return (
		<form onSubmit={handleSubmit} className={styles.checkoutForm}>
			{activeSubscriptionLoading || paymentMethodsLoading ? (
				<Box display="flex" justifyContent="center" alignItems="center" width="100%">
					<CircularProgress sx={{ width: '24px', justifySelf: 'center' }} />
				</Box>
			) : !isEmptyArrayOrUndefined(paymentMethodsData) ? (
				<Box>
					<Typography variant="h6">Payment method</Typography>
					{paymentMethodsData && (
						<PaymentMethodsDropdown
							paymentMethods={paymentMethodsData}
							selectedPaymentMethodId={selectedPaymentMethodId}
							handlePaymentMethodChange={handlePaymentMethodChange}
						/>
					)}
				</Box>
			) : null}
			{isNewPayment && (
				<Box py="8px">
					<LinkAuthenticationElement />
					<AddressElement
						className={styles.addressElement}
						options={options}
						onChange={(e) =>
							e.value.address.country !== billingAddress?.country &&
							setBillingAddress(e.value.address)
						}
					/>
					<PaymentElement className={styles.paymentElement} />
				</Box>
			)}
			<Box>
				<FormControlLabel
					control={
						<Checkbox checked={vatFieldVisible} onChange={handleVatCheckboxChange} />
					}
					label="VAT ID (If not provided, VAT will be calculated as if it is not applicable.)"
				/>

				<AnimateHeight duration={200} height={vatFieldVisible ? 'auto' : 0}>
					<TextField
						placeholder="Enter your VAT ID"
						hiddenLabel
						value={vatId}
						onChange={handleVatIdChange}
						error={Boolean(vatError)}
						inputProps={{ className: styles.vatInput }}
						FormHelperTextProps={{ className: styles.vatHelperText }}
						helperText={vatError}
						className={styles.vatField}
						fullWidth
						autoComplete="off"
					/>
				</AnimateHeight>
			</Box>

			<Box>
				<FormControlLabel
					control={
						<Checkbox
							checked={promoCodeFieldVisible}
							onChange={handlePromoCheckboxChange}
						/>
					}
					label="Use coupon code"
				/>

				<AnimateHeight duration={200} height={promoCodeFieldVisible ? 'auto' : 0}>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<TextField
							placeholder="Coupon Code"
							value={promoCode}
							hiddenLabel
							className={styles.couponField}
							onChange={(e) => {
								setPromoCode(e.target.value),
									setPromoCodeError(''),
									setAppliedPromoCode('');
							}}
							FormHelperTextProps={{ className: styles.couponHelperText }}
							error={Boolean(promoCodeError)}
							inputProps={{ className: styles.couponInput }}
							helperText={promoCodeError}
							autoComplete="off"
						/>
						<Button
							variant="text"
							color={appliedPromoCode ? 'error' : 'primary'}
							onClick={
								appliedPromoCode ? handleRemovePromoCode : handleApplyPromoCode
							}
							sx={{ mb: '0.75rem' }}
						>
							{appliedPromoCode ? 'Remove' : 'Apply'}
						</Button>
					</Box>
				</AnimateHeight>
			</Box>

			<Box className={styles.selectedItems}>
				<Box className={styles.item}>
					<Box className={styles.itemText}>
						<Typography className={styles.itemLabel} variant="h5">
							Plan:
						</Typography>
						<Typography className={styles.itemValue} variant="h5">
							{plan.name}
						</Typography>
					</Box>
					<Typography className={styles.itemValue} variant="h5">
						€{getPriceInEuros(plan.prices, billingInterval)}
						{intervalAbbrevation}
					</Typography>
				</Box>
				{addon && (
					<Box className={styles.item}>
						<Box className={styles.itemText}>
							<Typography className={styles.itemLabel} variant="h5">
								Add-on:
							</Typography>
							<Typography className={styles.itemValue} variant="h5">
								{addon.name}
							</Typography>
						</Box>
						<Typography className={styles.itemValue} variant="h5">
							€{getPriceInEuros(addon.prices, billingInterval)}
							{intervalAbbrevation}
						</Typography>
					</Box>
				)}
				{isActiveSubscription && (
					<Box className={styles.prorationMessage}>
						<Typography variant="body2" color="textSecondary">
							The total amount may vary due to proration calculations based on the
							remaining time of your current subscription period.
						</Typography>
					</Box>
				)}
				<Box className={styles.sumDivider} />
				<Box className={styles.item}>
					<Typography className={styles.itemLabel} variant="h6">
						Tax amount:
					</Typography>
					<Typography className={styles.itemValue} variant="h6">
						{priceValue
							? `€${(Math.max(priceValue.tax, 0) / 100).toFixed(2)}${intervalAbbrevation}`
							: '---'}
					</Typography>
				</Box>
				<Box className={styles.item}>
					<Typography className={styles.itemLabel} variant="h6">
						Subtotal:
					</Typography>
					<Typography className={styles.itemValue} variant="h6">
						{priceValue
							? `€${(Math.max(priceValue.subtotal, 0) / 100).toFixed(2)}${intervalAbbrevation}`
							: '---'}
					</Typography>
				</Box>
				<Box className={styles.item}>
					<Typography className={styles.itemLabel} variant="h6">
						Discount:
					</Typography>
					<Typography className={styles.itemValue} variant="h6">
						{priceValue?.total_discount_amounts &&
						priceValue?.total_discount_amounts.length > 0
							? `€${(Math.max(priceValue.total_discount_amounts[0].amount, 0) / 100).toFixed(2)}${intervalAbbrevation}`
							: '---'}
					</Typography>
				</Box>
				<Box className={styles.item}>
					<Box>
						<Typography className={styles.itemLabel} variant="h6">
							Total:
						</Typography>
						<Typography className={styles.itemLabel} variant="body2">
							Pay Today
						</Typography>
					</Box>
					<Typography className={styles.itemValue} variant="h6">
						{priceValue
							? `€${(Math.max(priceValue.total, 0) / 100).toFixed(2)}${intervalAbbrevation}`
							: '---'}
					</Typography>
				</Box>
			</Box>

			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				disabled={!stripe || loading || disabled}
				sx={{ mt: '16px' }}
			>
				{loading || isTotalLoading ? (
					<CircularProgress size={16} sx={{ color: 'white' }} />
				) : isActiveSubscription ? (
					'Update'
				) : (
					'Subscribe'
				)}
			</Button>

			<Button
				type="button"
				fullWidth
				variant="outlined"
				sx={{ mt: '16px' }}
				onClick={handleGoBack}
			>
				Go Back
			</Button>
		</form>
	);
};

export default CheckoutForm;
