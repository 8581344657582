import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Stack, Typography } from '@mui/material';

import { RoutesEnum } from 'shared/enums';

import { Layout } from '../common/Layout';
import { usePostResendEmailVerify } from 'app/pages/auth/hooks';
import { useUser } from 'core/UserProvider/useUser';

import styles from './VerifyEmail.module.scss';

const VerifyEmail: FC = () => {
	const { user } = useUser();
	const location = useLocation();
	const navigate = useNavigate();
	const email = location.state?.email as string;
	const [hasAttemptedResend, setHasAttemptedResend] = useState(false);

	const { mutateAsync: handleResendEmailVerify, isPending: isPendingResendEmailVerify } =
		usePostResendEmailVerify();

	const handleNavigate = () => navigate('/signup');
	const onResendClick = async () => {
		await handleResendEmailVerify({ email });
		setHasAttemptedResend(true);
	};

	// Redirect to Home if the user is logged in
	useEffect(() => {
		if (user) {
			navigate(RoutesEnum.HOME);
		}
	}, [user, navigate]);

	// Stop unnecessary render if the user is logged in
	if (user) {
		return null;
	}

	return (
		<Box className={styles.containerVerifyEmail}>
			<Layout headerTitle="We've sent you an e-mail">
				<Typography className={styles.textLine}>
					Your security comes first, please follow the link on your e-mail to validate
					your access.
				</Typography>

				<Stack spacing={2} className={styles.btnGroup}>
					<Button
						variant="outlined"
						fullWidth
						disabled={isPendingResendEmailVerify}
						onClick={onResendClick}
					>
						Resend verification e-mail
					</Button>
				</Stack>
				{hasAttemptedResend && (
					<Box className={styles.goBack}>
						<Typography variant="h5">Haven't received it yet?</Typography>
						<Typography className={styles.textLine}>
							Please check your registration details, and try again.
						</Typography>

						<Button variant="outlined" onClick={handleNavigate} sx={{ width: '150px' }}>
							Register
						</Button>
						<Typography className={styles.textLine}>
							Or, contact our{' '}
							<a
								href="mailto:support@lynxict.com"
								className={styles.contact}
								rel="noopener noreferrer"
							>
								Support team
							</a>
							.
						</Typography>
					</Box>
				)}
			</Layout>
		</Box>
	);
};

export default VerifyEmail;
