import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, Divider, IconButton, InputAdornment, Stack, Typography } from '@mui/material';

import { FormInput } from 'shared/components/FormInput';
import { GoogleLoginButton } from 'shared/components/GoogleLoginButton';
import {
	CheckCircleIcon,
	CircleOnIcon,
	EyeIcon,
	EyeSlashIcon,
	TimesCircleIcon,
} from 'shared/components/Icons';
import { RoutesEnum } from 'shared/enums';

import { Layout } from '../common/Layout';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePostRegister } from 'app/pages/auth/hooks';
import { useUser } from 'core/UserProvider/useUser';
import * as yup from 'yup';

import styles from './Signup.module.scss';

interface CriteriaStatus {
	hasMinimumLengthEight: boolean;
	hasNumber: boolean;
	hasSymbol: boolean;
}

interface SignupFormValues {
	fullName: string;
	email: string;
	password: string;
}

const validationSchema = yup.object({
	fullName: yup
		.string()
		.trim()
		.required('Full name is required')
		.test('is-not-empty', 'This field is required', (value) => value !== ''),
	email: yup.string().required('Email is required').email('Please enter a valid email address'),
	password: yup
		.string()
		.min(8, 'Password must be at least 8 characters long')
		.matches(/\d/, 'Password must contain at least one number')
		.matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
		.required('Password is required'),
});

const Signup: FC = () => {
	const { user } = useUser();
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = React.useState(false);
	const criteriasForPassword = ['hasMinimumLengthEight', 'hasNumber', 'hasSymbol'];

	const methods = useForm<SignupFormValues>({
		defaultValues: {
			fullName: '',
			email: '',
			password: '',
		},
		resolver: yupResolver(validationSchema),
		reValidateMode: 'onChange',
	});

	const { isSubmitting } = methods.formState;
	const password = methods.watch('password');

	const criteriaStatus: CriteriaStatus = {
		hasMinimumLengthEight: password.trim().length >= 8,
		hasNumber: /\d/.test(password),
		hasSymbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
	};

	const { mutateAsync: handleRegister, isPending } = usePostRegister();

	const onSubmit = async (data: SignupFormValues) => {
		const nameArr = data.fullName.split(' ');
		const first_name = nameArr[0].trim();
		const last_name = nameArr[nameArr.length - 1].trim();
		const middle_name = nameArr.length > 2 ? nameArr[1].trim() : '';
		try {
			await handleRegister({
				first_name: first_name,
				middle_name: last_name,
				last_name: middle_name,
				email: data.email,
				password: data.password,
			});
		} catch (error) {
			console.log(error);
		}
	};

	// Redirect to Home if the user is logged in
	useEffect(() => {
		if (user) {
			navigate(RoutesEnum.HOME);
		}
	}, [user, navigate]);

	// Stop unnecessary render if the user is logged in
	if (user) {
		return null;
	}

	return (
		<Box className={styles.containerSignup}>
			<Layout headerTitle="Welcome to AdUniverse.ai!">
				<Typography className={styles.freeTrialTextLine}>
					Start your <span className={styles.linkSpan}>14-days-free trial!</span> No
					credit card required.
				</Typography>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Stack className={styles.fieldsWrapper}>
							<FormInput
								id="fullname"
								placeholder=" Full name, e.g. John Doe"
								hiddenLabel
								name="fullName"
								variant="outlined"
								autoComplete="off"
							/>
							<FormInput
								id="email"
								placeholder="Email"
								name="email"
								aria-label="Email"
								hiddenLabel={true}
								variant="outlined"
							/>
							<FormInput
								id="password"
								name="password"
								hiddenLabel={true}
								placeholder="Password"
								variant="outlined"
								type={showPassword ? 'text' : 'password'}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword(!showPassword)}
												edge="end"
											>
												{showPassword ? (
													<EyeSlashIcon
														sx={{ width: '14px', height: '14px' }}
													/>
												) : (
													<EyeIcon
														sx={{ width: '14px', height: '14px' }}
													/>
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<Stack spacing="0.62rem">
								{criteriasForPassword.map((criteria, index) => {
									return (
										<Stack
											spacing="1rem"
											direction="row"
											key={index}
											alignItems="center"
										>
											{!password.length ? (
												<CircleOnIcon
													sx={{ width: '14px', height: '14px' }}
												/>
											) : criteriaStatus[criteria as keyof CriteriaStatus] ? (
												<CheckCircleIcon
													sx={{ width: '14px', height: '14px' }}
												/>
											) : (
												<TimesCircleIcon
													sx={{ width: '14px', height: '14px' }}
												/>
											)}

											<Typography
												className={
													!password.length
														? styles.defaultColor
														: criteriaStatus[
																	criteria as keyof CriteriaStatus
																	// eslint-disable-next-line no-mixed-spaces-and-tabs
															  ]
															? styles.successColor
															: styles.errorColor
												}
											>
												{criteria === 'hasMinimumLengthEight'
													? 'Minimum 8 characters'
													: criteria === 'hasNumber'
														? 'At least one number'
														: 'At least one symbol'}
											</Typography>
										</Stack>
									);
								})}
							</Stack>
						</Stack>
						<Stack spacing="1.5rem" className={styles.btnGroup}>
							<Button
								variant="contained"
								fullWidth
								disabled={isSubmitting || isPending}
								type="submit"
							>
								Sign up
							</Button>
							<Divider className={styles.divider}>
								<div className={styles.text}>or</div>
							</Divider>
							<GoogleLoginButton label="Sign up with Google" />
							<Typography className={styles.defaultColor}>
								{' '}
								Already have an account?{' '}
								<Link to="/login" className={styles.linkSpan}>
									Login now
								</Link>
							</Typography>
						</Stack>
						<Typography className={styles.privacyText}>
							{' '}
							By signing up, you agree to our{' '}
							<Link to="/#" className={styles.linkSpan}>
								Terms of service
							</Link>{' '}
							and{' '}
							<Link to="/#" className={styles.linkSpan}>
								{' '}
								Privacy policy{' '}
							</Link>
						</Typography>
					</form>
				</FormProvider>
			</Layout>
		</Box>
	);
};

export default Signup;
