import { interceptRequest, interceptResponse, statusChecker } from '../helpers';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

class ApiService {
	private _httpClient = axios.create({
		baseURL: import.meta.env.VITE_APP_API_URL,
		timeout: 120000,
	});

	constructor() {
		interceptRequest(this._httpClient);
		interceptResponse(this._httpClient);
	}

	responseHandler<T = unknown>({ data }: AxiosResponse<T>) {
		return data;
	}

	async get<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return statusChecker(await this._httpClient.get<T>(url, config));
	}

	async post<T = unknown, B = unknown>(
		url: string,
		body: B,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return statusChecker(await this._httpClient.post<T>(url, body, config));
	}

	async put<T = unknown, B = unknown>(
		url: string,
		body: B,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return statusChecker(await this._httpClient.put<T>(url, body, config));
	}

	async patch<T = unknown, B = unknown>(
		url: string,
		body: B,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return statusChecker(await this._httpClient.patch<T>(url, body, config));
	}

	async delete<T = null>(url: string): Promise<AxiosResponse<T>> {
		return statusChecker(await this._httpClient.delete<T>(url));
	}
}

const apiService = new ApiService();

export default apiService;
