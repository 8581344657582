import { SvgIcon, SvgIconProps } from '@mui/material';

export const DollarIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.64836 13.685C7.74176 13.7784 7.86793 13.8316 8.00001 13.8333C8.13208 13.8316 8.25825 13.7784 8.35165 13.685C8.44505 13.5916 8.49828 13.4654 8.50001 13.3333V12.5H9C9.35035 12.5226 9.7017 12.4758 10.0339 12.3622C10.3661 12.2487 10.6726 12.0707 10.9359 11.8384C11.1991 11.6061 11.4139 11.3242 11.5679 11.0087C11.7219 10.6932 11.8121 10.3504 11.8333 10C11.8121 9.64957 11.7219 9.30678 11.5679 8.9913C11.4139 8.67582 11.1991 8.39385 10.9359 8.16158C10.6726 7.9293 10.3661 7.75129 10.0339 7.63776C9.7017 7.52423 9.35035 7.47742 9 7.5H8.50001V4.5H10.6667C10.7993 4.5 10.9265 4.44732 11.0202 4.35355C11.114 4.25978 11.1667 4.13261 11.1667 4C11.1667 3.86739 11.114 3.74021 11.0202 3.64645C10.9265 3.55268 10.7993 3.5 10.6667 3.5H8.50001V2.66666C8.50001 2.53405 8.44733 2.40687 8.35356 2.3131C8.25979 2.21933 8.13261 2.16666 8.00001 2.16666C7.8674 2.16666 7.74022 2.21933 7.64645 2.3131C7.55268 2.40687 7.50001 2.53405 7.50001 2.66666V3.5H7C6.64966 3.47742 6.29831 3.52423 5.96611 3.63776C5.6339 3.75129 5.32738 3.9293 5.06414 4.16158C4.8009 4.39385 4.58611 4.67582 4.4321 4.9913C4.27809 5.30678 4.18789 5.64957 4.16667 6C4.18789 6.35043 4.27809 6.69322 4.4321 7.0087C4.58611 7.32418 4.8009 7.60615 5.06414 7.83842C5.32738 8.0707 5.6339 8.24871 5.96611 8.36224C6.29831 8.47577 6.64966 8.52258 7 8.5H7.50001V11.5H4.66667C4.53406 11.5 4.40689 11.5527 4.31312 11.6464C4.21935 11.7402 4.16667 11.8674 4.16667 12C4.16667 12.1326 4.21935 12.2598 4.31312 12.3536C4.40689 12.4473 4.53406 12.5 4.66667 12.5H7.50001V13.3333C7.50173 13.4654 7.55497 13.5916 7.64836 13.685ZM7.50001 7.5V4.5H7C6.55898 4.46069 6.12009 4.5957 5.7774 4.87608C5.43471 5.15646 5.21548 5.55992 5.16667 6C5.21548 6.44008 5.43471 6.84354 5.7774 7.12392C6.12009 7.4043 6.55898 7.5393 7 7.5H7.50001ZM8.50001 8.5V11.5H9C9.44103 11.5393 9.87992 11.4043 10.2226 11.1239C10.5653 10.8435 10.7845 10.4401 10.8333 10C10.7845 9.55992 10.5653 9.15646 10.2226 8.87608C9.87992 8.5957 9.44103 8.46069 9 8.5H8.50001Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
