export interface BillingResponse {
	clientSecret: string;
	type: string;
}

export interface CheckoutSessionResponse {
	after_expiration: null | string;
	allow_promotion_codes: null | boolean;
	amount_subtotal: number;
	amount_total: number;
	automatic_tax: {
		enabled: boolean;
		liability: {
			type: string;
		};
		status: string;
	};
	billing_address_collection: null | string;
	cancel_url: string;
	client_reference_id: null | string;
	client_secret: null | string;
	consent: null | object;
	consent_collection: null | object;
	created: number; // timestamp
	currency: string;
	currency_conversion: null | object;
	custom_fields: any[]; // array of custom fields (can specify further)
	custom_text: {
		after_submit: null | string;
		shipping_address: null | string;
		submit: null | string;
		terms_of_service_acceptance: null | string;
	};
	customer: string;
	customer_creation: null | string;
	customer_details: {
		address: null | object;
		email: string;
		name: null | string;
		phone: null | string;
		tax_exempt: string;
	};
	customer_email: null | string;
	expires_at: number; // timestamp
	id: string;
	invoice: null | string;
	invoice_creation: null | object;
	livemode: boolean;
	locale: null | string;
	metadata: Record<string, any>; // key-value pair object
	mode: string;
	object: string;
	payment_intent: null | string;
	payment_link: null | string;
	payment_method_collection: string;
	payment_method_configuration_details: null | object;
	payment_method_options: {
		card: {
			request_three_d_secure: string;
		};
	};
	payment_method_types: string[];
	payment_status: string;
	phone_number_collection: {
		enabled: boolean;
	};
	recovered_from: null | string;
	saved_payment_method_options: {
		allow_redisplay_filters: string[];
		payment_method_remove: null | object;
		payment_method_save: null | object;
	};
	setup_intent: null | string;
	shipping_address_collection: null | object;
	shipping_cost: null | object;
	shipping_details: null | object;
	shipping_options: any[];
	status: string;
	submit_type: null | string;
	subscription: null | string;
	success_url: string;
	tax_id_collection: {
		enabled: boolean;
		required: string;
	};
	total_details: {
		amount_discount: number;
		amount_shipping: number;
		amount_tax: number;
	};
	ui_mode: string;
	url: string;
}

export interface Subscription {
	id: number;
	name: string;
	description: string;
	stripe_metadata_id: StripeMetadataEnum;
	price: number;
	subitems: {
		brands: number;
		teamMembers: number;
	};
	interval: string;
	item_type: string;
	status: string;
}

export interface SubscriptionResponse {
	id: number;
	user_id: number;
	subscription_id: number;
	addon_id: number | null;
	valid_until: string;
	cancel_at: string | null;
	stripe_subscription_id: string;
	next_addon_id: number | null;
	next_subscription_id: number | null;
	status: string;
	created_at: string;
	updated_at: string;
	subscription: Subscription;
	addon: Subscription | null;
}

export interface SubscriptionResponseV2 {
	id: string;
	object: string; // Always "subscription"
	application: string | null;
	application_fee_percent: number | null;
	automatic_tax: {
		enabled: boolean;
		liability: {
			type: string;
		};
	};
	billing_cycle_anchor: number;
	billing_cycle_anchor_config: string | null;
	billing_thresholds: string | null;
	cancel_at: number | null;
	cancel_at_period_end: boolean;
	canceled_at: number | null;
	cancellation_details: {
		comment: string | null;
		feedback: string | null;
		reason: string | null;
	};
	collection_method: 'charge_automatically' | 'send_invoice';
	created: number;
	currency: string;
	current_period_end: number;
	current_period_start: number;
	customer: string;
	days_until_due: number | null;
	default_payment_method: string | null;
	default_source: string | null;
	default_tax_rates: Array<any>;
	description: string | null;
	discount: string | null;
	discounts: Array<any>;
	ended_at: number | null;
	invoice_settings: {
		account_tax_ids: string | null;
		issuer: {
			type: string;
		};
	};
	items: {
		object: string; // list
		data: SubscriptionItem[];
		has_more: boolean;
		total_count: number;
	};
	latest_invoice: string;
	livemode: boolean;
	metadata: Record<string, string>;
	next_pending_invoice_item_invoice: number | null;
	on_behalf_of: string | null;
	pause_collection: string | null;
	payment_settings: {
		payment_method_options: string | null;
		payment_method_types: string | null;
	};
	pending_invoice_item_interval: string | null;
	pending_setup_intent: string | null;
	pending_update: string | null;
	plan: {
		id: string;
		object: string; // Always "plan"
		active: boolean;
		aggregate_usage: string | null;
		billing_scheme: string;
		created: number;
		currency: string;
		interval: string;
		interval_count: number;
		amount: number;
		amount_decimal: string;
	};
	quantity: number;
	schedule: string | null;
	start_date: number;
	status: string; // "active", "canceled", "incomplete", etc.
	test_clock: string | null;
	transfer_data: string | null;
	trial_end: number | null;
	trial_settings: {
		end_behavior: {
			missing_payment_method: string;
		};
	};
	trial_start: number | null;
}

export interface SubscriptionItem {
	id: string;
	object: string; // Always "subscription_item"
	billing_thresholds: string | null;
	created: number;
	discounts: Array<any>;
	metadata: Record<string, string>;
	plan: {
		id: string;
		object: string; // Always "plan"
		active: boolean;
		aggregate_usage: string | null;
		amount: number;
		amount_decimal: string;
		billing_scheme: string;
		created: number;
		currency: string;
		interval: 'month' | 'year';
		interval_count: number;
		livemode: boolean;
		metadata: Record<string, string>;
		meter: string | null;
		nickname: string | null;
		product: string; // Product ID
		tiers_mode: string | null;
		transform_usage: string | null;
		trial_period_days: number | null;
		usage_type: string; // e.g., "licensed"
	};
	price: PriceObject;
	quantity: number;
	subscription: string;
	tax_rates: Array<any>;
}

export interface BillingDashboardResponse {
	session_url: string;
}

export interface InvoiceObject {
	account_country: string;
	account_name: string;
	account_tax_ids: string | null;
	amount_due: number;
	amount_paid: number;
	amount_remaining: number;
	amount_shipping: number;
	application: string | null;
	application_fee_amount: number | null;
	attempt_count: number;
	attempted: boolean;
	automatic_tax: {
		enabled: boolean;
		liability: {
			type: string;
		};
		status: string;
	};
	automatically_finalizes_at: number | null;
	billing_reason: string;
	charge: string | null;
	collection_method: string;
	created: number;
	currency: string;
	custom_fields: string | null;
	customer: string;
	customer_address: {
		city: string;
		country: string;
		line1: string;
		line2: string;
		postal_code: string;
		state: string;
	};
	customer_email: string;
	customer_name: string;
	customer_phone: string | null;
	customer_shipping: string | null;
	customer_tax_exempt: string;
	customer_tax_ids: string[];
	default_payment_method: string | null;
	default_source: string | null;
	default_tax_rates: string[];
	description: string | null;
	discount: {
		id: string;
		object: string;
		checkout_session: string | null;
	} | null;
	discounts: string[];
	due_date: number | null;
	effective_at: number | null;
	ending_balance: number;
	footer: string | null;
	from_invoice: string | null;
	issuer: {
		type: string;
	};
	last_finalization_error: string | null;
	latest_revision: string | null;
	lines: {
		object: string;
		data: any[];
		has_more: boolean;
		total_count: number;
	};
	livemode: boolean;
	metadata: object;
	next_payment_attempt: number;
	number: string | null;
	object: string;
	on_behalf_of: string | null;
	paid: boolean;
	paid_out_of_band: boolean;
	payment_intent: string | null;
	payment_settings: {
		default_mandate: string | null;
		payment_method_options: string | null;
		payment_method_types: string | null;
	};
	period_end: number;
	period_start: number;
	post_payment_credit_notes_amount: number;
	pre_payment_credit_notes_amount: number;
	quote: string | null;
	receipt_number: string | null;
	rendering: string | null;
	rendering_options: string | null;
	shipping_cost: string | null;
	shipping_details: string | null;
	starting_balance: number;
	statement_descriptor: string | null;
	status: string;
	status_transitions: {
		finalized_at: number | null;
		marked_uncollectible_at: number | null;
		paid_at: number | null;
		voided_at: number | null;
	};
	subscription: string;
	subscription_details: {
		metadata: {
			userId: string;
		};
	};
	subscription_proration_date: number;
	subtotal: number;
	subtotal_excluding_tax: number;
	tax: number;
	test_clock: string | null;
	total: number;
	total_discount_amounts: {
		amount: number;
		discount: string;
	}[];
	total_excluding_tax: number;
	total_tax_amounts: {
		amount: number;
		inclusive: boolean;
		tax_rate: string;
	}[];
	transfer_data: string | null;
	webhooks_delivered_at: number | null;
}

export enum StripeMetadataEnum {
	FreeTrial = 'free_trial',
	BasicPlan = 'product_basic_plan',
	ProPlan = 'product_extra_plan',
	SmallAddon = 'addon_pack_small',
	MediumAddon = 'addon_pack_medium',
	LargeAddon = 'addon_pack_large',
}

export interface PriceObjectProduct {
	id: string;
	object: string;
	active: boolean;
	attributes: [];
	created: number;
	default_price: number | null;
	description: string;
	features: [];
	images: string[];
	livemode: boolean;
	marketing_features: [
		{
			name: string | null;
		},
	];
	metadata: StripeMetadataObject;
	name: string;
	package_dimensions: null;
	shippable: null;
	statement_descriptor: string | null;
	tax_code: string | null;
	type: string; // 'service' ??
	unit_label: string | null;
	updated: number;
	url: string | null;
}
export interface Product extends PriceObjectProduct {
	prices: PriceObject[];
}

export interface PriceObject {
	id: string;
	object: 'price';
	active: boolean;
	billing_scheme: 'per_unit' | 'tiered';
	created: number;
	currency: string; // Iso currency code in lowercase: 'eur', 'usd'
	custom_unit_amount: null;
	livemode: boolean;
	lookup_key: string | null;
	// metadata: Record<string, any>;
	nickname: string | null;
	product: PriceObjectProduct;
	recurring: {
		aggregate_usage: 'last_during_period' | 'last_ever' | 'max' | 'sum' | null;
		interval: 'day' | 'week' | 'month' | 'year';
		interval_count: number;
		meter: string | null;
		trial_period_days: null;
		usage_type: 'metered' | 'licensed';
	};
	tax_behavior: 'unspecified' | 'exclusive' | 'inclusive';
	tiers_mode: 'graduated' | 'volume' | null;
	transform_quantity: null;
	type: 'one_time' | 'recurring';
	unit_amount: number | null;
	unit_amount_decimal: string;
}

export interface StripeMetadataObject {
	brands: string;
	teamMembers: string;
	vs_display_on_web: string;
	vs_id: string;
	vs_item_type: 'subscription' | 'addon';
}

export interface StripePromoCode {
	active: boolean;
	code: string;
	created: number;
	customer: string | null;
	expires_at: number | null;
	id: string;
	coupon: StripeCoupon;
	livemode: boolean;
	metadata: Record<string, any>;
	max_redemptions: number | null;
	object: string; //'promotion_code'
	restrictions: {
		first_time_transaction: boolean;
		minimum_amount: number | null;
		minimum_amount_currency: string | null;
	};
	times_redeemed: number;
}

export interface StripeCoupon {
	amount_off: number | null;
	created: number;
	currency: string | null;
	duration: 'forever' | 'once' | 'repeating';
	duration_in_months: number | null;
	id: string;
	livemode: boolean;
	max_redemptions: number | null;
	metadata: Record<string, any>;
	name: string;
	object: string; //'coupon'
	percent_off: number;
	redeem_by: number | null;
	times_redeemed: number;
	valid: boolean;
}
