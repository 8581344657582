import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { authApiService } from 'app/pages/auth/service';
import { AxiosResponse } from 'axios';
import { useUser } from 'core/UserProvider/useUser';
import { LoginUserData, UserDTO } from 'core/api/models/user';
import { ToastContext } from 'core/toast/ToastProvider';
import { tokenService } from 'core/token/services';

export const usePostLogin = (): UseMutationResult<
	AxiosResponse<UserDTO>,
	unknown,
	LoginUserData,
	unknown
> => {
	const {
		actions: { setLoggedInUser },
	} = useUser();
	const toast = useContext(ToastContext);
	const navigate = useNavigate();

	return useMutation({
		mutationFn: authApiService.postLogin,
		onSuccess: (data) => {
			const user = data.data;
			setLoggedInUser(user);
			tokenService.token = user.access_token;
			tokenService.refreshToken = user.refresh_token;
			toast.open('Success', 'User Login successful', {
				severity: 'success',
			});
			navigate('/');
		},
		onError: (error: any) => {
			console.error('Login failed:', error.response?.data || error.message);
		},
	});
};

export default usePostLogin;
