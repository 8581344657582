import type { ValidTranslationKeys } from 'react-i18next';
import { initReactI18next } from 'react-i18next';

import { MultiLanguageSupportContext } from './contexts';
import i18n, { TOptions } from 'i18next';
import HttpApi from 'i18next-http-backend';

i18n.use(HttpApi)
	.use(initReactI18next)
	.init({
		lng: 'en',
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
	});

const t = (key: ValidTranslationKeys | ValidTranslationKeys[], options?: TOptions): string =>
	i18n.t(key, options);

export { t, MultiLanguageSupportContext };
