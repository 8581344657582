import type { AxiosInstance } from 'axios';
import { tokenService } from 'core/token/services';
import { appendToken } from '.';

import * as Sentry from "@sentry/react";

const interceptRequest = (httpClient: AxiosInstance): void => {
	httpClient.interceptors.request.use(
		(config) => {
            const method = config.method?.toUpperCase() || "UNKNOWN";
            const url = config.url || "UNKNOWN_URL";
			Sentry.addBreadcrumb({
				category: "http",
				message: `Outgoing request: ${method?.toUpperCase()} ${url}`,
				level: "info",
				data: {
					method: method,
					url: url,
					headers: config.headers,
				},
			});

			if (!config.headers?.['No-Auth']) {
				const userToken = tokenService.token;
				appendToken(config, userToken);
			} else {
				delete config.headers['No-Auth'];
			}
			return config;
		},
		(error) => {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	);
};

export default interceptRequest;
