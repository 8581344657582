import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ModalState {
	isOpen: boolean;
	ad_id?: string;
	adSource?: string;
}

const initialState: ModalState = {
	isOpen: false,
	ad_id: undefined,
	adSource: undefined,
};

const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		openModal(state, action: PayloadAction<{ ad_id: string; adSource: string }>) {
			state.isOpen = true;
			state.ad_id = action.payload.ad_id;
			state.adSource = action.payload.adSource;
		},
		closeModal(state) {
			state.isOpen = false;
			state.ad_id = undefined;
			state.adSource = undefined;
		},
	},
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
