import { InvoiceObject } from 'shared/models/billing';

import { billingAndPlansApiService } from '../../services/billing/BillingAndPlansApiService';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useCalculatePriceV2 = (
	user_id: number | undefined,
	calculatePrice: boolean,
	planPriceId?: string,
	addonPriceId?: string,
	promoCode?: string
): UseQueryResult<InvoiceObject> => {
	return useQuery({
		queryKey: ['calculate_total', user_id, planPriceId, addonPriceId],
		queryFn: async () =>
			await billingAndPlansApiService.getInvoiceObjectV2(
				user_id,
				planPriceId,
				addonPriceId,
				promoCode
			),
		enabled: !!user_id && !!planPriceId && calculatePrice,
		retry: false,
	});
};
