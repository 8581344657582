import { SvgIcon, SvgIconProps } from '@mui/material';

export const ElipsisIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.62499 9.99999C5.62499 10.8054 4.97208 11.4583 4.16666 11.4583C3.36125 11.4583 2.70833 10.8054 2.70833 9.99999C2.70833 9.19457 3.36125 8.54166 4.16666 8.54166C4.97208 8.54166 5.62499 9.19457 5.62499 9.99999ZM10 11.4583C10.8054 11.4583 11.4583 10.8054 11.4583 9.99999C11.4583 9.19457 10.8054 8.54166 10 8.54166C9.19458 8.54166 8.54166 9.19457 8.54166 9.99999C8.54166 10.8054 9.19458 11.4583 10 11.4583ZM15.8333 11.4583C16.6387 11.4583 17.2917 10.8054 17.2917 9.99999C17.2917 9.19457 16.6387 8.54166 15.8333 8.54166C15.0279 8.54166 14.375 9.19457 14.375 9.99999C14.375 10.8054 15.0279 11.4583 15.8333 11.4583Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
