import { SvgIcon, SvgIconProps } from '@mui/material';

export const HeartFillIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M16.0834 4.75818C15.7009 4.37152 15.2453 4.06481 14.7431 3.85593C14.241 3.64705 13.7023 3.54017 13.1584 3.54152C12.6145 3.54017 12.0758 3.64705 11.5736 3.85593C11.0715 4.06481 10.6159 4.37152 10.2334 4.75818L10 4.99985L9.76671 4.76652C8.99314 3.99302 7.94399 3.55848 6.85005 3.55848C5.7561 3.55848 4.70696 3.99302 3.93338 4.76652C3.16994 5.54539 2.74231 6.59255 2.74231 7.68318C2.74231 8.77382 3.16994 9.82098 3.93338 10.5999L9.57505 16.2582C9.69223 16.3752 9.85109 16.441 10.0167 16.441C10.1823 16.441 10.3412 16.3752 10.4584 16.2582L16.1 10.5999C16.8638 9.81843 17.29 8.76827 17.2869 7.67561C17.2838 6.58295 16.8516 5.53524 16.0834 4.75818Z"
				fill="#009EC2"
			/>
		</SvgIcon>
	);
};
