import { billingAndPlansApiService } from '../../services/billing/BillingAndPlansApiService';
import { Address } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useUpdateBillingInfo = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (data: {
			user_id: number;
			userAddress: Address | undefined | null;
			taxId?: string;
		}) => billingAndPlansApiService.updateBillingInfo(data.user_id, data.userAddress, data.taxId),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['calculate_total'] });
		},
	});
};

export default useUpdateBillingInfo;
