import { useCallback, useState } from 'react';

import { BillingTabType } from 'app/pages/settings/constants/manageTeamTabs';

export const useBillingModal = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [tabs, setTabs] = useState<BillingTabType[]>([]);
	const [tabOneContent, setTabOneContent] = useState<JSX.Element | undefined>();
	const [tabValue, setTabValue] = useState(0);

	const handleModalOpen = useCallback(() => {
		setIsOpen(true);
	}, []);

	const handleModalClose = useCallback(() => {
		setTabValue(0);
		setIsOpen(false);
	}, []);

	const handleNextTab = useCallback(() => {
		setTabValue((prev) => prev + 1);
	}, []);

	const memoizedSetTabs = useCallback((tabs: BillingTabType[]) => {
		setTabs(tabs);
	}, []);

	const memoizedSetTabOneContent = useCallback((content: JSX.Element | undefined) => {
		setTabOneContent(content);
	}, []);

	return {
		isOpen,
		tabs,
		tabOneContent,
		tabValue,
		handleModalClose,
		handleModalOpen,
		setTabs: memoizedSetTabs,
		setTabOneContent: memoizedSetTabOneContent,
		handleNextTab,
	};
};
