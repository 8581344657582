import * as Sentry from '@sentry/react';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useUser } from 'core/UserProvider/useUser';
import { UserDTO } from 'core/api/models/user';
import { userApiService } from 'shared/services/userApiServices';

export const useGetUser = (id?: number): UseQueryResult<UserDTO, Error> => {
  const { user } = useUser();

  return useQuery({
    queryKey: ['user', id],
    queryFn: async () => {
      try {
        const userId = id ?? (user?.id as number);
        if (!userId) {
          throw new Error('User ID is undefined');
        }
        const response = await userApiService.getUser(userId);
        return response;
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            queryKey: 'user',
          },
          extra: {
            userId: id ?? user?.id,
          },
        });
        throw error;
      }
    },
    enabled: !!user?.id,
  });
};
