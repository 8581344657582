import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Button, IconButton, InputAdornment, Stack, Typography } from '@mui/material';

import { FormInput } from 'shared/components/FormInput';
import {
	CheckCircleIcon,
	CircleOnIcon,
	EyeIcon,
	EyeSlashIcon,
	TimesCircleIcon,
} from 'shared/components/Icons';

import { Layout } from '../common/Layout';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePostResetPassword } from 'app/pages/auth/hooks';
import * as yup from 'yup';

import styles from './ResetPassword.module.scss';

interface CriteriaStatus {
	hasMinimumLengthEight: boolean;
	hasNumber: boolean;
	hasSymbol: boolean;
}

interface ResetPassProps {
	token: string;
	uid: string | null;
}

interface ResetPasswordFormValues {
	password: string;
	confirmPassword: string;
}

const validationSchema = yup.object({
	password: yup
		.string()
		.min(8, 'Password must be at least 8 characters long')
		.matches(/\d/, 'Password must contain at least one number')
		.matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
		.required('Password is required'),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('password'), undefined], 'Passwords must match')
		.required('This field is required'),
});

const ResetPassword: FC<ResetPassProps> = ({ token, uid }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const criteriasForPassword = ['hasMinimumLengthEight', 'hasNumber', 'hasSymbol'];
	const methods = useForm<ResetPasswordFormValues>({
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
		resolver: yupResolver(validationSchema),
		reValidateMode: 'onChange',
	});

	const { isSubmitting } = methods.formState;
	const password = methods.watch('password');

	const criteriaStatus: CriteriaStatus = {
		hasMinimumLengthEight: password.trim().length >= 8,
		hasNumber: /\d/.test(password),
		hasSymbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
	};

	const { mutateAsync: handleResetPassword, isPending } = usePostResetPassword();

	const onSubmit = async (data: ResetPasswordFormValues) => {
		try {
			await handleResetPassword({
				password: data.password,
				reset_password_token: token,
				uid: uid,
			});
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Box className={styles.containerResetPassword}>
			<Layout headerTitle="Set new password">
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Stack spacing={3} className={styles.passwordGroup}>
							<FormInput
								id="password"
								name="password"
								hiddenLabel
								placeholder="Password"
								variant="outlined"
								type={showPassword ? 'text' : 'password'}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword(!showPassword)}
												edge="end"
											>
												{showPassword ? (
													<EyeSlashIcon
														sx={{ width: '14px', height: '14px' }}
													/>
												) : (
													<EyeIcon
														sx={{ width: '14px', height: '14px' }}
													/>
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<FormInput
								id="confirmPassword"
								name="confirmPassword"
								hiddenLabel
								placeholder="Confirm password"
								variant="outlined"
								type={showConfirmPassword ? 'text' : 'password'}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() =>
													setShowConfirmPassword(!showConfirmPassword)
												}
												edge="end"
											>
												{showPassword ? (
													<EyeSlashIcon
														sx={{ width: '14px', height: '14px' }}
													/>
												) : (
													<EyeIcon
														sx={{ width: '14px', height: '14px' }}
													/>
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<Stack spacing="0.62rem">
								{criteriasForPassword.map((criteria, index) => {
									return (
										<Stack
											spacing="1rem"
											direction="row"
											key={index}
											alignItems="center"
										>
											{!password.length ? (
												<CircleOnIcon
													sx={{ width: '14px', height: '14px' }}
												/>
											) : criteriaStatus[criteria as keyof CriteriaStatus] ? (
												<CheckCircleIcon
													sx={{ width: '14px', height: '14px' }}
												/>
											) : (
												<TimesCircleIcon
													sx={{ width: '14px', height: '14px' }}
												/>
											)}

											<Typography
												className={
													!password.length
														? styles.defaultColor
														: criteriaStatus[
																	criteria as keyof CriteriaStatus
																	// eslint-disable-next-line no-mixed-spaces-and-tabs
															  ]
															? styles.successColor
															: styles.errorColor
												}
											>
												{criteria === 'hasMinimumLengthEight'
													? 'Minimum 8 characters'
													: criteria === 'hasNumber'
														? 'At least one number'
														: 'At least one symbol'}
											</Typography>
										</Stack>
									);
								})}
							</Stack>
						</Stack>

						<Button
							variant="contained"
							fullWidth
							disabled={isSubmitting || isPending}
							type="submit"
						>
							Set new password
						</Button>
					</form>
				</FormProvider>
			</Layout>
		</Box>
	);
};

export default ResetPassword;
