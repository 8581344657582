import { Box, Typography } from '@mui/material';

import logo from 'assets/images/logo-gradient.svg';

import styles from './Header.module.scss';

interface HeaderProps {
	isActiveSubscription: boolean;
}

const Header: React.FC<HeaderProps> = ({ isActiveSubscription }) => (
	<Box className={styles.header}>
		<Box className={styles.headerContent}>
			<img src={logo} alt="Ad Universe" width={46} />
			<Box className={styles.headerText}>
				<Typography variant="subtitle2">
					{isActiveSubscription ? 'Manage your plan' : 'Your Free Trial Expired'}
				</Typography>
				<Typography variant="subtitle1">
					{isActiveSubscription
						? 'Choose a Subscription to update your current plan.'
						: 'Choose a Subscription to continue using Ad Universe'}
				</Typography>
			</Box>
		</Box>
	</Box>
);

export default Header;
