import { FC, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import { useActiveSubscriptionInfo } from 'shared/api/hooks/billing/useActiveSubscriptionInfo';
import { RoutesEnum } from 'shared/enums';
import { getDaysLeftV2 } from 'shared/helpers/billingHelpers';
import { useGetUser } from 'shared/hooks/useGetUser';

import { ChevronDownIcon, ChevronRightIcon } from '../Icons';
import { useGetUsersTeams } from 'app/pages/settings/hooks/useGetUsersTeams';

import styles from './FreeTrialModal.module.scss';

const FreeTrialModal: FC = () => {
	const { data: user } = useGetUser();
	const {
		isSubscriptionTrialing,
		subscriptionEndsAt,
		isLoading: activeSubscriptionLoading,
	} = useActiveSubscriptionInfo(user?.id);
	const { data: userTeams } = useGetUsersTeams();
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [modalExpanded, setModalExpanded] = useState(false);

	if (
		//Check if user exists
		!user ||
		//Hide when activeSub is loading
		activeSubscriptionLoading ||
		//Check if user has free trial
		!isSubscriptionTrialing ||
		//Hide on manage plans tab in settings
		(location.pathname === '/settings' && location.search === '?tab=2') ||
		//Hide for invited users
		userTeams?.owner.length === 0
	) {
		return null;
	}
	const daysLeft = subscriptionEndsAt ? getDaysLeftV2(subscriptionEndsAt) : 'Expired';
	const trialExpired = daysLeft === 'Expired';
	const headerMessage = trialExpired ? daysLeft.toLowerCase() : daysLeft.replace(/\s+\S*$/, '');

	const handleExpand = () => {
		setModalExpanded(!modalExpanded);
	};

	const handleClick = () => {
		searchParams.set('tab', '2');
		setSearchParams(searchParams);
		handleExpand();
		navigate(`${RoutesEnum.SETTINGS}?${searchParams.toString()}`);
	};

	return (
		<Stack className={styles.container}>
			<Box className={styles.header}>
				<Typography variant="body1">
					{trialExpired ? (
						<span>Your trial has </span>
					) : (
						<span>Your trial expires in </span>
					)}
					<span style={{ fontWeight: '600' }}>{headerMessage}.</span>
				</Typography>
				{modalExpanded ? (
					<IconButton size="small" onClick={handleExpand}>
						<ChevronDownIcon sx={{ color: '#fff', width: '14px', height: '14px' }} />
					</IconButton>
				) : (
					<IconButton size="small" onClick={handleExpand}>
						<ChevronRightIcon sx={{ color: '#fff', width: '14px', height: '14px' }} />
					</IconButton>
				)}
			</Box>
			<AnimateHeight duration={300} height={modalExpanded ? 'auto' : 0}>
				<Stack className={styles.content}>
					<Typography variant="body1">
						To maintain access to these features, see all plans.
					</Typography>
					<Button
						className={styles.contentButton}
						variant="outlined"
						onClick={handleClick}
					>
						See plans
					</Button>
				</Stack>
			</AnimateHeight>
		</Stack>
	);
};

export default FreeTrialModal;
