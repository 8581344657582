import { useContext } from 'react';

import { Ad } from 'shared/models/ads';

import { notesApiService } from '../service/NotesApiService';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastContext } from 'core/toast/ToastProvider';

export const useDeleteNote = (adId: string | undefined, userId: number | undefined) => {
	const toast = useContext(ToastContext);
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (noteId: string) => notesApiService.deleteNote(userId, noteId),
		onMutate: async (targetNoteId) => {
			await queryClient.cancelQueries({
				queryKey: ['ad', userId, adId].filter(Boolean),
			});

			const previousAd = queryClient.getQueryData<Ad>(['ad', userId, adId]);

			if (previousAd) {
				queryClient.setQueryData<Ad>(['ad', userId, adId], {
					...previousAd,
					notes: previousAd.notes.filter((note) => note.id !== targetNoteId),
				});
			}

			return { previousAd };
		},
		onSuccess: () => {
			toast.open('Success', 'Note deleted successfully', { severity: 'success' });
		},
		onError: (_, __, context) => {
			toast.open('Error', 'Error deleting note', { severity: 'error' });

			if (context?.previousAd) {
				queryClient.setQueryData(['ad', userId, adId], context.previousAd);
			}
		},
	});
};

export default useDeleteNote;
