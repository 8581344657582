import { SvgIcon, SvgIconProps } from '@mui/material';

export const FileIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M15.4417 7.49999L10.8333 2.89166C10.7162 2.77442 10.5574 2.70847 10.3917 2.70833H6.66667C6.05888 2.70833 5.47598 2.94977 5.04621 3.37954C4.61644 3.80931 4.375 4.39221 4.375 4.99999V15C4.375 15.6078 4.61644 16.1907 5.04621 16.6204C5.47598 17.0502 6.05888 17.2917 6.66667 17.2917H13.3333C13.9411 17.2917 14.524 17.0502 14.9538 16.6204C15.3836 16.1907 15.625 15.6078 15.625 15V7.91666C15.6184 7.75966 15.553 7.6109 15.4417 7.49999ZM11.0417 4.84166L13.4917 7.29166H11.0417V4.84166ZM13.3333 16.0417H6.66667C6.3904 16.0417 6.12545 15.9319 5.9301 15.7366C5.73475 15.5412 5.625 15.2763 5.625 15V4.99999C5.625 4.72373 5.73475 4.45878 5.9301 4.26343C6.12545 4.06807 6.3904 3.95833 6.66667 3.95833H9.79167V7.91666C9.79382 8.08175 9.86037 8.23947 9.97711 8.35622C10.0939 8.47296 10.2516 8.5395 10.4167 8.54166H14.375V15C14.375 15.2763 14.2653 15.5412 14.0699 15.7366C13.8746 15.9319 13.6096 16.0417 13.3333 16.0417Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
