import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface MediaState {
	currentMediaIndex: number;
	currentSubformat: string;
}

const initialState: MediaState = {
	currentMediaIndex: 0,
	currentSubformat: 'image',
};

const mediaSlice = createSlice({
	name: 'media',
	initialState,
	reducers: {
		setCurrentMedia(state, action: PayloadAction<{ index: number; subformat: string }>) {
			state.currentMediaIndex = action.payload.index;
			state.currentSubformat = action.payload.subformat;
		},
	},
});

export const { setCurrentMedia } = mediaSlice.actions;
export default mediaSlice.reducer;
