import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

const CustomCheckbox = styled(Checkbox)(() => ({
	'& .MuiSvgIcon-root': {
		borderRadius: 6,
		border: '2px solid rgba(206, 212, 218, 1)',
		width: 22,
		height: 22,
		boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
		backgroundColor: '#fff',
		backgroundImage: 'none',
	},
	'&.Mui-checked .MuiSvgIcon-root': {
		backgroundColor: 'rgba(0, 158, 194, 1)',
		borderColor: 'rgba(59, 130, 246, 1)',
		color: '#fff',
		backgroundImage: 'none',
	},
}));

export default CustomCheckbox;
