import React, { FC, PropsWithChildren, createContext, useState } from 'react';

export interface DragContextProps {
	isDragging: boolean;
	setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DragContext = createContext<DragContextProps | undefined>(undefined);

const DragProvider: FC<PropsWithChildren> = ({ children }) => {
	const [isDragging, setIsDragging] = useState(false);

	return (
		<DragContext.Provider value={{ isDragging, setIsDragging }}>
			{children}
		</DragContext.Provider>
	);
};

export default DragProvider;
