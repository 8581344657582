import { useContext } from 'react';

import { tagsApiService } from 'shared/api/services/tags/TagsApiService';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastContext } from 'core/toast/ToastProvider';

export const useCreateTag = () => {
	const toast = useContext(ToastContext);
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data: {
			userId: number | undefined;
			tagName: string;
			level: number;
			parentId: number | null;
		}) => tagsApiService.createTag(data.userId, data.tagName, data.level, data.parentId),
		onMutate: async () => {
			await queryClient.cancelQueries({
				queryKey: ['tag'],
			});

			const previousTags = queryClient.getQueryData(['tags']);

			return { previousTags };
		},
		onSuccess: (_, variables) => {
			toast.open('Success', `Tag "${variables.tagName}" created successfully`, {
				severity: 'success',
			});
			queryClient.invalidateQueries({ queryKey: ['tags'] });
		},
		onError: (_, __, context) => {
			toast.open('Error', 'Error creating tag', {
				severity: 'error',
			});
			if (context?.previousTags) {
				queryClient.setQueryData(['tags'], context.previousTags);
			}
		},
	});
};

export default useCreateTag;
