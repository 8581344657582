import { FC, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Box, Chip, Typography } from '@mui/material';

import { AdNote } from 'shared/models/ads';

import './editorStyle.css';
import noNotes from 'assets/images/no-results.png';

import styles from './PublicNotes.module.scss';

interface NotesProps {
	notes: AdNote[] | undefined;
}

const PublicNotes: FC<NotesProps> = ({ notes }) => {
	const [notesArray, setNotesArray] = useState<AdNote[]>(notes || []);

	useEffect(() => {
		if (notes) {
			setNotesArray([...notes].reverse());
		}
	}, [notes]);

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return new Intl.DateTimeFormat('en-US', {
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		}).format(date);
	};

	return (
		<Box className={styles.notesWrapper}>
			{notesArray.length > 0 ? (
				notesArray.map((noteItem) => (
					<Box
						key={noteItem.id}
						className={styles.noteItem}
						sx={{
							backgroundColor: '#F4FCF7',
						}}
					>
						<Box className={styles.noteContent}>
							<Box className={styles.noteHeader}>
								<Box className={styles.noteInfo}>
									<img
										src="https://static.vecteezy.com/system/resources/previews/024/766/960/non_2x/default-female-avatar-profile-icon-social-media-user-free-vector.jpg"
										alt="profile"
									/>
									<Typography>{noteItem.owner_full_name}</Typography>
									<Chip
										label={'public'}
										size="small"
										style={{
											backgroundColor: '#22C55E',
											color: '#fff',
											borderRadius: '6px',
										}}
									/>
								</Box>
								<Typography>{formatDate(noteItem.created_at)}</Typography>
							</Box>
							<Box className={styles.note}>
								<ReactQuill
									value={noteItem.text}
									readOnly={true}
									theme="bubble"
									className="read-only"
								/>
							</Box>
						</Box>
					</Box>
				))
			) : (
				<Box className={styles.noNotes} p={2}>
					<img src={noNotes} alt="no notes" className={styles.noNotesImg} />
					<Typography variant="subtitle2">No notes yet</Typography>
					<Typography variant="body2">
						Once new notes are added, they will be displayed here.
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default PublicNotes;
