import React, { ForwardedRef } from 'react';

import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';

import {
	BuildingIcon,
	ChevronRightIcon,
	Copy07Icon,
	HeartRoundedIcon,
	TimesIcon,
} from 'shared/components/Icons';
import useScrollLock from 'shared/hooks/useScrollLock';
import { Ad } from 'shared/models/ads';

interface MoreOptionsMenuProps {
	ad: Ad | undefined;
	anchorEl: HTMLElement | null;
	handleClose: () => void;
	handleSaveAdClick: (event: React.MouseEvent<HTMLLIElement>) => void;
	handleCopyLink: () => void;
	handleDelete: () => void;
	handleTrackBrand: () => void;
	submenuOpen: boolean;
	setSubmenuOpen: (arg: boolean) => void;
}

const MoreOptionsMenu = React.forwardRef(function MoreOptionsMenu(
	{
		ad,
		anchorEl,
		handleClose,
		handleSaveAdClick,
		handleCopyLink,
		handleDelete,
		handleTrackBrand,
		submenuOpen,
		setSubmenuOpen,
	}: MoreOptionsMenuProps,
	ref: ForwardedRef<HTMLDivElement | null>
) {
	const enableLinkCopy = ad?.user_ad_id;

	useScrollLock(anchorEl);

	return (
		<Menu
			id="more-options-menu"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={() => {
				setSubmenuOpen(false);
				handleClose();
			}}
			slotProps={{
				paper: {
					ref: ref,
					sx: {
						width: 240,
					},
				},
			}}
			disableScrollLock
		>
			<MenuItem
				onClick={handleSaveAdClick}
				sx={{
					'backgroundColor': submenuOpen ? 'rgba(0, 172, 195, 0.05)' : 'inherit',
					'color': submenuOpen ? 'rgba(0, 172, 195, 1)' : 'inherit',
					'&:hover': {
						backgroundColor: submenuOpen
							? 'rgba(0, 172, 195, 0.05)'
							: 'rgba(0, 0, 0, 0.04)',
					},
				}}
			>
				<ListItemIcon>
					<HeartRoundedIcon
						sx={{
							width: '14px',
							height: '14px',
							color: submenuOpen ? 'rgba(0, 172, 195, 1) !important' : 'inherit',
						}}
					/>
				</ListItemIcon>
				<ListItemText
					primaryTypographyProps={{
						sx: { color: submenuOpen ? 'rgba(0, 172, 195, 1)' : '#6c757d' },
					}}
					primary="Save Ad to favorites"
				/>
				<ChevronRightIcon sx={{ width: '14px', height: '14px' }} />
			</MenuItem>
			{ad?.platform !== 'aduniverse' && ad?.brand_id && (
				<MenuItem onClick={handleTrackBrand}>
					{' '}
					<ListItemIcon>
						<BuildingIcon sx={{ width: '14px', height: '14px' }} />
					</ListItemIcon>
					<ListItemText primary="Track Brand" />
				</MenuItem>
			)}
			{enableLinkCopy && (
				<MenuItem onClick={handleCopyLink}>
					<ListItemIcon>
						<Copy07Icon sx={{ width: '14px', height: '14px' }} />
					</ListItemIcon>
					<ListItemText primary="Copy & Share Ad Link" />
				</MenuItem>
			)}

			{ad?.user_ad_id && (
				<MenuItem onClick={handleDelete}>
					<ListItemIcon>
						<TimesIcon sx={{ width: '14px', height: '14px' }} />
					</ListItemIcon>
					<ListItemText primary={`Delete from "My collection"`} />
				</MenuItem>
			)}
		</Menu>
	);
});

export default MoreOptionsMenu;
