import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowCircleUpIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 17.5C8.51664 17.5 7.0666 17.0601 5.83323 16.236C4.59986 15.4119 3.63856 14.2406 3.07091 12.8701C2.50325 11.4997 2.35472 9.99168 2.64411 8.53682C2.9335 7.08196 3.64781 5.74559 4.6967 4.6967C5.7456 3.6478 7.08197 2.9335 8.53683 2.64411C9.99168 2.35472 11.4997 2.50325 12.8701 3.0709C14.2406 3.63856 15.4119 4.59985 16.236 5.83322C17.0601 7.06659 17.5 8.51664 17.5 10C17.5 11.9891 16.7098 13.8968 15.3033 15.3033C13.8968 16.7098 11.9891 17.5 10 17.5ZM10 3.75C8.76387 3.75 7.5555 4.11656 6.52769 4.80331C5.49988 5.49007 4.6988 6.46619 4.22576 7.60823C3.75271 8.75027 3.62894 10.0069 3.8701 11.2193C4.11125 12.4317 4.70651 13.5453 5.58059 14.4194C6.45466 15.2935 7.56831 15.8888 8.78069 16.1299C9.99307 16.3711 11.2497 16.2473 12.3918 15.7742C13.5338 15.3012 14.5099 14.5001 15.1967 13.4723C15.8834 12.4445 16.25 11.2361 16.25 10C16.25 8.3424 15.5915 6.75268 14.4194 5.58058C13.2473 4.40848 11.6576 3.75 10 3.75ZM13.3334 10.625C13.2512 10.6254 13.1699 10.6094 13.094 10.5779C13.0182 10.5464 12.9494 10.5001 12.8917 10.4417L10.625 8.17497V13.3333C10.6228 13.4984 10.5563 13.6561 10.4396 13.7729C10.3228 13.8896 10.1651 13.9562 10 13.9583C9.83491 13.9562 9.67719 13.8896 9.56044 13.7729C9.4437 13.6561 9.37716 13.4984 9.375 13.3333V8.17502L7.10836 10.4417C6.98988 10.5521 6.83317 10.6122 6.67126 10.6093C6.50934 10.6065 6.35485 10.5409 6.24034 10.4264C6.12583 10.3118 6.06024 10.1574 6.05738 9.99543C6.05452 9.83352 6.11462 9.67681 6.22502 9.55833L9.5424 6.24095C9.54751 6.23546 9.55273 6.23005 9.55806 6.22472C9.67527 6.10751 9.83424 6.04167 10 6.04167C10.1658 6.04167 10.3247 6.10751 10.4419 6.22472C10.447 6.22981 10.452 6.23497 10.4569 6.24021L13.775 9.55833C13.8921 9.67552 13.9578 9.83437 13.9578 10C13.9578 10.1656 13.8921 10.3245 13.775 10.4417C13.7173 10.5001 13.6485 10.5464 13.5727 10.5779C13.4968 10.6094 13.4155 10.6254 13.3334 10.625Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
