import { SvgIcon, SvgIconProps } from '@mui/material';

export const Copy07Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="15" height="14" viewBox="0 0 15 14" fill="none" {...props}>
			<path
				d="M6.62533 1.16813C6.23155 1.17347 5.99515 1.19627 5.804 1.29366C5.58448 1.40552 5.406 1.58399 5.29415 1.80351C5.19676 1.99466 5.17396 2.23106 5.16862 2.62484M11.8753 1.16813C12.2691 1.17347 12.5055 1.19627 12.6966 1.29366C12.9162 1.40552 13.0946 1.58399 13.2065 1.80351C13.3039 1.99466 13.3267 2.23105 13.332 2.62483M13.332 7.87483C13.3267 8.26861 13.3039 8.50501 13.2065 8.69616C13.0946 8.91568 12.9162 9.09416 12.6966 9.20601C12.5055 9.30341 12.2691 9.3262 11.8753 9.33154M13.3337 4.6665V5.83317M8.66702 1.1665H9.83363M3.53366 12.8332H7.96699C8.62039 12.8332 8.94708 12.8332 9.19665 12.706C9.41617 12.5942 9.59465 12.4157 9.7065 12.1962C9.83366 11.9466 9.83366 11.6199 9.83366 10.9665V6.53317C9.83366 5.87978 9.83366 5.55308 9.7065 5.30351C9.59465 5.08399 9.41617 4.90552 9.19665 4.79366C8.94708 4.6665 8.62039 4.6665 7.96699 4.6665H3.53366C2.88026 4.6665 2.55357 4.6665 2.304 4.79366C2.08448 4.90552 1.906 5.08399 1.79415 5.30351C1.66699 5.55308 1.66699 5.87978 1.66699 6.53317V10.9665C1.66699 11.6199 1.66699 11.9466 1.79415 12.1962C1.906 12.4157 2.08448 12.5942 2.304 12.706C2.55357 12.8332 2.88026 12.8332 3.53366 12.8332Z"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
};
