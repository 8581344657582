import { useContext } from 'react';

import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { authApiService } from 'app/pages/auth/service';
import { ToastContext } from 'core/toast/ToastProvider';

export const usePostResendEmailVerify = (): UseMutationResult<
	unknown,
	unknown,
	{ email: string },
	unknown
> => {
	const toast = useContext(ToastContext);

	return useMutation({
		mutationFn: ({ email }) => authApiService.postResendEmailVerify(email),
		onSuccess: (response: any) => {
			toast.open('Success', response.message, {
				severity: 'success',
			});
		},
		onError: () => {
			toast.open('Error', 'Email Verification error', {
				severity: 'error',
			});
		},
	});
};

export default usePostResendEmailVerify;
