import { SvgIcon, SvgIconProps } from '@mui/material';

export const ElipsisVertIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.0001 5.62501C10.8055 5.62501 11.4584 4.97209 11.4584 4.16668C11.4584 3.36126 10.8055 2.70834 10.0001 2.70834C9.19467 2.70834 8.54175 3.36126 8.54175 4.16668C8.54175 4.97209 9.19467 5.62501 10.0001 5.62501ZM10.0001 11.4583C10.8055 11.4583 11.4584 10.8054 11.4584 10C11.4584 9.1946 10.8055 8.54168 10.0001 8.54168C9.19467 8.54168 8.54175 9.1946 8.54175 10C8.54175 10.8054 9.19467 11.4583 10.0001 11.4583ZM11.4584 15.8333C11.4584 16.6388 10.8055 17.2917 10.0001 17.2917C9.19467 17.2917 8.54175 16.6388 8.54175 15.8333C8.54175 15.0279 9.19467 14.375 10.0001 14.375C10.8055 14.375 11.4584 15.0279 11.4584 15.8333Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
