export enum PaymentModeEnum {
	Subscription = 'subscription',
	Payment = 'payment',
	Setup = 'setup',
}

export enum StripeThemesEnum {
	Night = 'night',
	Stripe = 'stripe',
	Flat = 'flat',
}

export enum AddressModeEnum {
	Shipping = 'shipping',
	Billing = 'billing',
}
