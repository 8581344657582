import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { AmexIcon, MasterCardIcon, VisaIcon } from 'shared/components/Icons';

import { PaymentMethod } from '@stripe/stripe-js';

import styles from '../Checkout.module.scss';

interface PaymentMethodsDropdownProps {
	paymentMethods: PaymentMethod[];
	selectedPaymentMethodId: string;
	handlePaymentMethodChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const cardBrandIcons: { [key: string]: JSX.Element } = {
	visa: <VisaIcon sx={{ width: '40px', height: '40px' }} />,
	mastercard: <MasterCardIcon sx={{ width: '40px', height: '40px' }} />,
	amex: <AmexIcon sx={{ width: '40px', height: '40px' }} />,
};

const PaymentMethodsDropdown: React.FC<PaymentMethodsDropdownProps> = ({
	paymentMethods,
	selectedPaymentMethodId,
	handlePaymentMethodChange,
}) => {

	return (
		<Box className={styles.step}>
			<RadioGroup value={selectedPaymentMethodId} onChange={handlePaymentMethodChange}>
				{paymentMethods.map(
					(method) =>
						method.card && (
							<FormControlLabel
								sx={{
									'& .MuiFormControlLabel-label': {
										width: '100%',
									},
								}}
								key={method.id}
								value={method.id}
								control={<Radio />}
								label={
									<Box
										display="flex"
										justifyContent="space-between"
										alignItems="center"
										pr="8px"
									>
										<Typography variant="subtitle1">
											**** **** **** {method.card.last4}
										</Typography>
										{cardBrandIcons[method.card.brand]}
									</Box>
								}
								className={styles.planOption}
							/>
						)
				)}
				<FormControlLabel
					sx={{
						'& .MuiFormControlLabel-label': {
							width: '100%',
						},
					}}
					key="new_card"
					value="new_card"
					control={<Radio />}
					label={<Typography variant="subtitle2">+ New card</Typography>}
					className={styles.planOption}
				/>
			</RadioGroup>
		</Box>
	);
};

export default PaymentMethodsDropdown;
