import { Box, CircularProgress, Typography } from '@mui/material';

import LoadingImg from 'assets/images/loaderImg.png';

import styles from './LoadingState.module.scss';

const LoadingState = () => {
	return (
		<Box className={styles.container}>
			<img src={LoadingImg} alt="Loading..." loading="lazy" width={226} />
			<Typography variant="h5">Scraping data</Typography>
			<Typography variant="body1">
				Please be patient as it may take some time for all the data to scrape. You can
				monitor the progress in the progress bar below.
			</Typography>
			<CircularProgress />
		</Box>
	);
};

export default LoadingState;
