import { createSlice } from '@reduxjs/toolkit';

export const sortSlice = createSlice({
	name: 'sort',
	initialState: {
		sortValue: '{"reach.eu_total_reach":-1}',
		sortTitle: 'AdSpend - High to Low',
	},
	reducers: {
		setSortBy: (state, action) => {
			state.sortValue = action.payload;
		},
		setSortTitle: (state, action) => {
			state.sortTitle = action.payload;
		},
	},
});

export const { setSortBy, setSortTitle } = sortSlice.actions;
export default sortSlice.reducer;
