import { createSelector } from 'reselect';
import { RootState } from 'app/store/store';

const selectUserAdsState = (state: RootState) => state.ads.userAds;
const selectPublicAdsState = (state: RootState) => state.ads.publicAds;

export const selectUserAdsFiltered = createSelector(
  [selectUserAdsState],
  (userAdsState) => userAdsState.filteredAds || []
);

export const selectPublicAdsFiltered = createSelector(
  [selectPublicAdsState],
  (publicAdsState) => publicAdsState.filteredAds || []
);

export const selectFilteredAds = createSelector(
  [selectUserAdsFiltered, selectPublicAdsFiltered, (_, adSource: string) => adSource],
  (userAds, publicAds, adSource) => {
    return adSource === 'userAds' ? userAds : publicAds;
  }
);