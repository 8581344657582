import { FC } from 'react';

import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Close from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button/Button';

import { HeaderContainer } from 'shared/components';
import { CopyIcon } from 'shared/components/Icons';

import styles from './DetailsHeader.module.scss';

interface DetailsHeaderProps {
	id: number | string | undefined;
	userAd: string | undefined;
	handleClose: VoidFunction;
	handlePrevAd: VoidFunction;
	handleNextAd: VoidFunction;
	handleCopyAdLink?: VoidFunction;
	isNextDisabled: boolean;
	isPrevDisabled: boolean;
}

const DetailsHeader: FC<DetailsHeaderProps> = ({
	id,
	userAd,
	handleClose,
	handlePrevAd,
	handleNextAd,
	handleCopyAdLink,
	isPrevDisabled,
	isNextDisabled,
}) => {
	const enableLinkCopy = userAd;

	return (
		<HeaderContainer>
			<HeaderContainer.Left>
				<Button
					disabled={isPrevDisabled}
					variant="text"
					onClick={handlePrevAd}
					startIcon={<ArrowBack fontSize="medium" />}
				>
					Previous Ad
				</Button>
				<Button
					disabled={isNextDisabled}
					variant="text"
					onClick={handleNextAd}
					endIcon={<ArrowForward fontSize="large" />}
				>
					Next Ad
				</Button>
			</HeaderContainer.Left>
			<HeaderContainer.Center>
				<Typography variant="h5">{`Ad ID: ${id}`}</Typography>
			</HeaderContainer.Center>
			<HeaderContainer.Right>
				{enableLinkCopy && (
					<Button
						variant="contained"
						onClick={handleCopyAdLink}
						color="primary"
						endIcon={<CopyIcon sx={{ width: '16px', height: '16px' }} />}
					>
						Copy Ad link
					</Button>
				)}
				{/* TODO */}
				{/* <Button
					className={styles.copyEmbed}
					variant="outlined"
					color="primary"
					endIcon={<Code />}
				>
					Copy embed code
				</Button> */}
				<Button
					variant="outlined"
					color="primary"
					className={styles.close}
					aria-label="close"
					onClick={() => handleClose()}
				>
					<Close fontSize="small" />
				</Button>
			</HeaderContainer.Right>
		</HeaderContainer>
	);
};

export default DetailsHeader;
