import { FC, useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Card } from '@mui/material';

import useDeleteAd from 'shared/api/hooks/ads/useDeleteAd';
import useSaveAd from 'shared/api/hooks/ads/useSaveAd';
import { useUpdateAdTags } from 'shared/api/hooks/ads/useUpdateAdTags';
import { useCopyPublicAdLink } from 'shared/api/hooks/public/useCopyPublicAdLink';
import { DnDIcon } from 'shared/components/Icons';
import { useOutsideClickMultipleRefs } from 'shared/hooks/useOutsideClickMultipleRefs';
import { Ad } from 'shared/models/ads';

import {
	CardFooter,
	CardInfo,
	CardMediaWrapper,
	CardUserActions,
	MoreOptionsMenu,
	SaveAdSubMenu,
} from './components';
import { RootState } from 'app/store/store';
import { useDragContext } from 'core/DragProvider/useDrag';
import { useUser } from 'core/UserProvider/useUser';
import { ToastContext } from 'core/toast/ToastProvider';
import { openModal } from 'features/detailsModal/detailsModalSlice';

import styles from './AdCard.module.scss';

interface AdCardProps {
	adSource?: string;
	ad: Ad | undefined;
	actions?: boolean;
	footer?: boolean;
	detail?: boolean;
	publicView?: boolean;
}

const AdCard: FC<AdCardProps> = React.memo(
	({ adSource, ad, actions, footer, detail, publicView }) => {
		const { user } = useUser();
		const location = useLocation();
		const saveMutation = useSaveAd();
		const dispatch = useDispatch();
		const toast = useContext(ToastContext);
		const { isOpen } = useSelector((state: RootState) => state.detailsModal);

		const menuRef = useRef<HTMLDivElement>(null);
		const subMenuRef = useRef<HTMLDivElement>(null);

		const updateAdTags = useUpdateAdTags();
		const deleteMutation = useDeleteAd();
		const [searchTerm, setSearchTerm] = useState('');
		const [submenuOpen, setSubmenuOpen] = useState(false);
		const [isHovered, setIsHovered] = useState(false);
        const [currentSubFormat, setCurrentSubFormat] = useState<string | undefined>(
			ad?.mediaFiles && ad?.mediaFiles.length > 0
				? ad.sub_format[0]
				: ''
		);

		// The state keeps track of the initialized status for each media of the video format in the ad.
		// Since the ad can contain multiple media items (e.g., in a carousel),
		// we use an array of booleans and all start as false (not initialized).
		const [playerInitialized, setPlayerInitialized] = useState<boolean[]>(() =>
			ad?.mediaFiles ? ad.mediaFiles.map(() => false) : []
		);

		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
		const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
		const [checkedTagIds, setCheckedTagIds] = useState<number[]>([]);
		const copyLinkMutation = useCopyPublicAdLink(user?.id, ad?._id);

        useEffect(() => {
			if (ad && ad.mediaFiles.length > 0) {
				setCurrentSubFormat(ad.sub_format[0]);
			}
		}, [ad]);

		const dragDisabled =
			isOpen ||
			location.pathname.startsWith('/external')
		const { setIsDragging } = useDragContext();
		const [{ isDragging }, drag] = useDrag(
			() => ({
				type: 'AD_CARD',
				item: () => {
					setIsDragging(true);
					return {
						adId: ad?._id,
						userAdId: ad?.user_ad_id,
						tagData: ad?.tags ?? ad?.user_ad_tags ?? [],
					};
				},
				collect: (monitor) => ({
					isDragging: monitor.isDragging(),
				}),
				end: () => {
					setIsDragging(false);
				},
			}),
			[ad]
		);

		const navigate = useNavigate();

		const closeAll = () => {
			setAnchorEl(null);
			setSubMenuAnchorEl(null);
			setSubmenuOpen(false);
		};

		const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(event.target.value);
		};

		const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 's') {
				event.stopPropagation();
			}
		};

		const handleSaveClick = () => {
			if (ad && user) {
				if (ad.user_ad_id) {
					updateAdTags.mutate({
						userId: user.id,
						adId: ad.user_ad_id,
						tagData: checkedTagIds,
					});
				} else {
					saveMutation.mutate({ userId: user.id, adId: ad._id, tagData: checkedTagIds });
				}
			}
			setCheckedTagIds([]);
			closeAll();
		};

		const handleCloseMenu = () => {
			setAnchorEl(null);
		};

		const handleSaveAdClick = (event: React.MouseEvent<HTMLLIElement>) => {
			setSubMenuAnchorEl(event.currentTarget);
			setSubmenuOpen(true);
		};

		const handleCloseSubMenu = () => {
			setSubMenuAnchorEl(null);
			setSubmenuOpen(false);
		};

		const handleDelete = () => {
			if (!user) {
				navigate('/login');
			}
			if (user && ad && ad.user_ad_id) {
				deleteMutation.mutate({ userId: user.id, adId: ad.user_ad_id, publicAdId: ad._id });
			}
		};

		const handleViewDetails = (adId: string | undefined) => {
			adId && adSource && dispatch(openModal({ ad_id: adId, adSource: adSource }));
		};
		const handleMoreOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		};

		const handleToggleSaved = () => {
			if (!user) {
				navigate('/login');
				return;
			}
			if (user && ad) {
				if (ad.user_ad_id) {
					handleDelete();
				} else {
					saveMutation.mutate(
						{ userId: user.id, adId: ad._id },
						{
							onSuccess: () => {
								toast.open('Success', 'Ad saved successfully', {
									severity: 'success',
								});
							},
							onError: () => {
								toast.open('Error', 'Error saving ad', {
									severity: 'error',
								});
							},
						}
					);
				}
			}
		};

		const handleCopyLink = () => {
			copyLinkMutation.mutate();
			handleCloseMenu();
		};

		const handleTrackBrandClick = () => {
			if (!ad?.brand_id) {
				toast.open('Error', 'Unable to track this brand', {
					severity: 'error',
				});

				return;
			}

			const link = `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${ad.brand_id}&search_type=page&media_type=all`;

			toast.open('Success', 'You will be redirected to tracker', {
				severity: 'success',
			});

			setTimeout(() => {
				navigate('/tracker', { state: { brandUrl: link } });
			}, 1000);
		};

		useOutsideClickMultipleRefs([menuRef, subMenuRef], closeAll, !!anchorEl);

		return (
			<Card
				className={styles.cardContainer}
				ref={dragDisabled || ad?.format === 'video' || currentSubFormat === 'video' ? null : drag}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				style={{
					pointerEvents: isDragging ? 'none' : 'auto',
					cursor: dragDisabled ? 'default' : isDragging ? 'grabbing' : 'grab',
					boxShadow: detail
						? 'none'
						: isHovered
							? '4px 4px 20px 0px rgba(0, 0, 0, 0.15)'
							: 'none',
				}}
			>
				{!dragDisabled && (
					<DnDIcon sx={{ width: '10px', height: '16px' }} className={styles.dragIcon} />
				)}
				<CardInfo ad={ad} ref={ad?.format === 'video' || currentSubFormat === 'video' ? drag : null} />
				{actions && (
					<CardUserActions
						publicView={publicView}
						handleMoreOptionsClick={handleMoreOptionsClick}
						handleToggleSaved={handleToggleSaved}
						handleViewDetails={handleViewDetails}
						isLoading={saveMutation.isPending || updateAdTags.isPending || deleteMutation.isPending}
						ad={ad}
					/>
				)}
				<CardMediaWrapper
					ad={ad}
					detail={detail}
					playerInitialized={playerInitialized}
					setPlayerInitialized={setPlayerInitialized}
                    setCurrentSubFormat={setCurrentSubFormat}
				/>
				{footer && <CardFooter ad={ad} />}
				{actions && !detail && (
					<>
						<MoreOptionsMenu
							ad={ad}
							anchorEl={anchorEl}
							handleSaveAdClick={handleSaveAdClick}
							handleCopyLink={handleCopyLink}
							handleDelete={handleDelete}
							handleClose={handleCloseMenu}
							submenuOpen={submenuOpen}
							setSubmenuOpen={setSubmenuOpen}
							handleTrackBrand={handleTrackBrandClick}
							ref={menuRef}
						/>
						<SaveAdSubMenu
							checkedTagIds={checkedTagIds}
							setCheckedTagIds={setCheckedTagIds}
							ad={ad}
							subMenuAnchorEl={subMenuAnchorEl}
							handleCloseSubMenu={handleCloseSubMenu}
							searchTerm={searchTerm}
							handleSearchChange={handleSearchChange}
							handleKeyDown={handleKeyDown}
							handleSaveClick={handleSaveClick}
							ref={subMenuRef}
						/>
					</>
				)}
			</Card>
		);
	}
);

export default AdCard;
