import { SvgIcon, SvgIconProps } from '@mui/material';

export const ImageIndentLeftIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M17.5 7.7085H12.5M17.5 3.3335H2.5M17.5 12.2918H12.5M17.5 16.6668H2.5M3.83333 13.3335H7.83333C8.30004 13.3335 8.5334 13.3335 8.71166 13.2427C8.86846 13.1628 8.99594 13.0353 9.07584 12.8785C9.16667 12.7002 9.16667 12.4669 9.16667 12.0002V8.00016C9.16667 7.53345 9.16667 7.3001 9.07584 7.12184C8.99594 6.96504 8.86846 6.83755 8.71166 6.75766C8.5334 6.66683 8.30004 6.66683 7.83333 6.66683H3.83333C3.36662 6.66683 3.13327 6.66683 2.95501 6.75766C2.79821 6.83755 2.67072 6.96504 2.59083 7.12184C2.5 7.3001 2.5 7.53345 2.5 8.00016V12.0002C2.5 12.4669 2.5 12.7002 2.59083 12.8785C2.67072 13.0353 2.79821 13.1628 2.95501 13.2427C3.13327 13.3335 3.36662 13.3335 3.83333 13.3335Z"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
};
