import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { AdsContent } from 'shared/components';
import { ExternalPageHeader } from 'shared/components/ExternalPageHeader';
import { usePublicAdsData } from 'shared/hooks/usePublicAdsData';

import { TagAdDetails } from 'app/pages/adDetails';
import { RootState } from 'app/store/store';
import { resetRequestNextPagePublicAds, setPublicAdsData } from 'features/ads/adsSlice';
import { setSearchTerm } from 'features/search/searchSlice';
import useDisableScroll from 'shared/hooks/useDisableScroll';

const ExternalTagFeature = () => {
	const { token } = useParams();
	const location = useLocation();
	const sortBy = useSelector((state: RootState) => state.sort.sortValue);
	const dispatch = useDispatch();
	const { isOpen, ad_id } = useSelector((state: RootState) => state.detailsModal);
    useDisableScroll(isOpen);

	const requestNextPageFlag = useSelector(
		(state: RootState) => state.ads.publicAds.requestNextPage
	);
	const {
		filteredAds,
		totalAds,
		tagName,
		tagOwner,
		isLoading,
		error,
		hasNextPage,
		isFetchingNextPage,
		isSuccess,
		fetchNextPage,
	} = usePublicAdsData(token ?? '', sortBy);

	useEffect(() => {
		dispatch(
			setPublicAdsData({
				filteredAds,
				hasNextPage,
				isFetchingNextPage,
				isLoading,
				totalAdsCount: totalAds,
			})
		);
	}, [dispatch, filteredAds, hasNextPage, isFetchingNextPage, isLoading, totalAds]);

	useEffect(() => {
		dispatch(setSearchTerm(''));
		return () => {
			dispatch(setSearchTerm(''));
		};
	}, [dispatch, location.pathname]);

	useEffect(() => {
		if (requestNextPageFlag) {
			fetchNextPage();
			dispatch(resetRequestNextPagePublicAds());
		}
	}, [requestNextPageFlag, fetchNextPage, dispatch]);

	useEffect(() => {
		const body = document.body;
		if (isOpen) {
			body.style.overflow = 'hidden';
		} else {
			body.style.overflow = 'auto';
		}

		return () => {
			body.style.overflow = 'auto';
		};
	}, [isOpen]);

	return (
		<>
			<ExternalPageHeader tagCount={totalAds} tagName={tagName} ownerName={tagOwner} />
			<AdsContent
				totalAds={totalAds}
				adSource="publicAds"
				isLoading={isLoading}
				error={error}
				searchBox
				cardStyle
				publicView
				hasNextPage={hasNextPage}
				isFetchingNextPage={isFetchingNextPage}
				isSuccess={isSuccess}
				externalView
			/>
			<Outlet />
			{isOpen && ad_id && <TagAdDetails token={token} />}
			<Outlet />
		</>
	);
};

export default ExternalTagFeature;
