import { useMemo } from 'react';

import { Product } from 'shared/models/billing';

import { useGetProducts } from './useGetPlans';

export const usePlansAndAddOns = (billingInterval: 'month' | 'year') => {
	const { data: productsData, isLoading, isError } = useGetProducts();

	const { plans, addons } = useMemo(() => {
		if (!productsData) {
			return { plans: undefined, addons: undefined };
		}

		const filteredByInterval = (products: Product[]) =>
			products.filter((product) =>
				product.prices.some((price) => price.recurring?.interval === billingInterval)
			);

		const plans = filteredByInterval(
			productsData.filter((product) => product.metadata.vs_item_type === 'subscription')
		);

		const addons = filteredByInterval(
			productsData.filter((product) => product.metadata.vs_item_type === 'addon')
		);

		return { plans, addons };
	}, [productsData, billingInterval]);

	return { plans, addons, isLoading, isError };
};
