import { LocalStorageKeysEnum } from 'shared/enums';
import { getItemFromLocalStorage, setItemToLocalStorage } from 'shared/helpers';

class TokenService {
	get token(): string | null {
		return getItemFromLocalStorage(LocalStorageKeysEnum.token);
	}

	set token(value: string | null) {
		setItemToLocalStorage(LocalStorageKeysEnum.token, value);
	}

	get refreshToken(): string | null {
		return getItemFromLocalStorage(LocalStorageKeysEnum.refreshToken);
	}

	set refreshToken(value: string | null) {
		setItemToLocalStorage(LocalStorageKeysEnum.refreshToken, value);
	}

	clear() {
		this.token = null;
		this.refreshToken = null;
	}
}

const tokenService = new TokenService();

export default tokenService;
