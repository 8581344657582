import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface GridZoomState {
	zoom: number;
	breakpointCols: { [key: string]: number };
}

const initialState: GridZoomState = {
	zoom: 100,
	breakpointCols: { default: 5, '2200': 4, '1750': 3, '1440': 2, '1100': 1 },
};

export const gridZoomSlice = createSlice({
	name: 'gridZoom',
	initialState,
	reducers: {
		setZoom: (state, action: PayloadAction<number>) => {
			if (state) {
				state.zoom = action.payload;
			}
		},
		setBreakpointCols: (state, action: PayloadAction<{ [key: string]: number }>) => {
			if (state) {
				state.breakpointCols = action.payload;
			}
		},
	},
});

export const { setZoom, setBreakpointCols } = gridZoomSlice.actions;

export default gridZoomSlice.reducer;
