import type { AxiosRequestConfig } from 'axios';

const appendToken = (config: AxiosRequestConfig, token: string | null): void => {
	//TODO Refactor refresh tokens once its defined
	if (token && config.headers) {
		config.headers = {
			accesstoken: `${token}`,
		};
	}
};

export default appendToken;
