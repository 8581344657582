import React from 'react';

import { Box, Button } from '@mui/material';

import NoResults from 'assets/images/no-results.png';

import styles from 'app/pages/swipeFile/SwipeFile.module.scss';

interface NoSearchResultsProps {
	resetSearch: VoidFunction;
}

const NoSearchResults: React.FC<NoSearchResultsProps> = ({ resetSearch }) => {
	return (
		<Box className={styles.noResults} p={2}>
			<Box mb={2}>
				<img src={NoResults} alt="No search results" loading="lazy" />
			</Box>
			<Box mb={2}>No results found.</Box>
			<Button variant="contained" onClick={resetSearch}>
				Reset Search
			</Button>
		</Box>
	);
};

export default NoSearchResults;
