import { Tag } from 'shared/models/tags';

const getSimplifiedTagByIds = (tags: Tag[], ids: number[]): { id: number; name: string }[] => {
	const simplifiedTags: { id: number; name: string }[] = [];

	const traverseTags = (tags: Tag[]) => {
		tags.forEach((tag) => {
			if (ids.includes(tag.id)) {
				simplifiedTags.push({ id: tag.id, name: tag.name });
			}
			if (tag.subTags && tag.subTags.length > 0) {
				traverseTags(tag.subTags);
			}
		});
	};

	traverseTags(tags);
	return simplifiedTags;
};

export default getSimplifiedTagByIds;
