import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { adsApiService } from 'shared/api/services/ads/AdsApiService';
import { getSimplifiedTagByIds } from 'shared/helpers';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RootState } from 'app/store/store';
import { ToastContext } from 'core/toast/ToastProvider';
import { updateAdTags, updatePublicAdId } from 'features/ads/adsSlice';

export const useSaveAd = () => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const toast = useContext(ToastContext);
	const { tags: userTags } = useSelector((state: RootState) => state.tags);

	return useMutation({
		mutationFn: (data: { userId: number; adId: string; tagData?: number[] }) =>
			adsApiService.saveAd(data.userId, data.adId, data.tagData),
		onSuccess: (data, variables) => {
			const updatedTagObjects = variables.tagData
				? getSimplifiedTagByIds(userTags, variables.tagData)
				: [];

			queryClient.invalidateQueries({ queryKey: ['tags'] });

			dispatch(updatePublicAdId({ adId: variables.adId, user_ad_id: data.id }));

			updatedTagObjects.length > 0 &&
				dispatch(updateAdTags({ user_ad_id: data.id, tagData: updatedTagObjects }));

			toast.open('Success', 'Ad saved successfully', {
				severity: 'success',
			});
		},
		onError: () => {
			toast.open('Error', 'Error saving ad', {
				severity: 'error',
			});
		},
	});
};

export default useSaveAd;
