import { UserDTO } from 'core/api/models/user';

export const getFullName = (user: UserDTO | undefined) => {
	if (!user?.first_name && !user?.last_name) {
		return '';
	} else if (!user.middle_name) {
		return `${user?.first_name} ${user?.last_name}`;
	} else {
		return `${user?.first_name} ${user?.middle_name} ${user?.last_name}`;
	}
};
