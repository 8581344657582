import ChevronIcon from '@mui/icons-material/ExpandMore';
import { ThemeOptions } from '@mui/material';

import { getCssVariable } from 'core/utils';

const theme: ThemeOptions = {
	palette: {
		info: {
			main: getCssVariable('--gray-medium'),
		},
		warning: {
			main: '#f1b212',
		},
		primary: {
			main: getCssVariable('--primary'),
		},
		background: {
			default: getCssVariable('--white'),
			paper: getCssVariable('--white'),
		},
		success: {
			main: getCssVariable('--toast-success-bckg'),
			contrastText: getCssVariable('--toast-success-text'),
		},
		error: {
			main: getCssVariable('--danger'),
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1152,
		},
	},
	typography: {
		fontFamily: getCssVariable('--font-primary'),
		link: {
			fontSize: getCssVariable('--text-body-size'),
			fontWeight: getCssVariable('--font-weight-regular'),
			lineHeight: getCssVariable('--line-height-base'),
			letterSpacing: getCssVariable('--letter-spacing-normal'),
			color: getCssVariable('--text-inverse-main'),
		},
		small: {
			fontSize: getCssVariable('--text-body-small'),
			fontWeight: getCssVariable('--font-weight-regular'),
			lineHeight: getCssVariable('--text-small-size'),
			color: getCssVariable('--text-secondary'),
		},
		body2: {
			fontSize: getCssVariable('--text-body-small'),
			fontWeight: getCssVariable('--font-weight-regular'),
			lineHeight: getCssVariable('--text-small-size'),
			color: getCssVariable('--text-secondary'),
		},
		body1: {
			fontSize: getCssVariable('--text-body-size'),
			fontWeight: getCssVariable('--font-weight-regular'),
			lineHeight: getCssVariable('--line-height-base'),
			letterSpacing: getCssVariable('--letter-spacing-normal'),
			color: getCssVariable('--text-secondary'),
		},
		subtitle1: {
			fontSize: getCssVariable('--text-body-size'),
			lineHeight: getCssVariable('--input-line-height'),
			fontWeight: getCssVariable('--font-weight-regular'),
			color: getCssVariable('--text-secondary'),
		},
		subtitle2: {
			fontSize: getCssVariable('--text-body-size'),
			fontWeight: getCssVariable('--font-weight-semibold'),
			lineHeight: getCssVariable('--line-height-caption'),
			color: getCssVariable('--gray-medium'),
		},
		h3: {
			fontSize: getCssVariable('--h3-font-size'),
			fontWeight: getCssVariable('--font-weight-regular'),
			lineHeight: getCssVariable('--line-height-loose'),
			color: getCssVariable('--text-heading'),
		},
		h4: {
			fontSize: getCssVariable('--h4-font-size'),
			fontWeight: getCssVariable('--font-weight-semibold'),
			lineHeight: getCssVariable('--line-height-medium'),
			color: getCssVariable('--text-heading'),
		},
		h5: {
			fontSize: getCssVariable('--h5-font-size'),
			fontWeight: getCssVariable('--font-weight-regular'),
			lineHeight: getCssVariable('--line-height-base'),
			color: getCssVariable('--text-heading'),
		},
		caption: {
			fontSize: getCssVariable('--text-body-small'),
			fontWeight: getCssVariable('--font-weight-regular'),
			lineHeight: getCssVariable('--text-body-small'),
			color: getCssVariable('--gray-medium'),
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundColor: getCssVariable('--background-default'),
					lineHeight: getCssVariable('--text-body-size'),
				},
			},
		},

		MuiToolbar: {
			styleOverrides: {
				root: {
					[`&.MuiToolbar-root`]: {
						minHeight: 'unset',
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: getCssVariable('--border-radius'),
					boxShadow: 'none',
					textTransform: 'unset',
					padding: '12px 18px',
					fontSize: getCssVariable('--text-body-size'),
					fontWeight: getCssVariable('--font-weight-semibold'),
					lineHeight: getCssVariable('--text-body-size'),
				},
				containedPrimary: {
					'&.Mui-disabled': {
						backgroundColor: '#009EC2',
						color: '#fff',
						opacity: 0.4,
					},
				},
				startIcon: {
					'& > *:nth-of-type(1)': {
						fontSize: '16px',
					},
				},
				endIcon: {
					'& > *:nth-of-type(1)': {
						fontSize: '16px',
					},
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					padding: '8px',
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 'unset',
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					'border': `1px solid ${getCssVariable('--border-color')}`,
					'boxShadow': 'none',
					'borderRadius': getCssVariable('--border-radius'),
					'&.MuiCard-root': { margin: '0' },
				},
			},
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					padding: '0',
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: '12px 12px 8px 20px',
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					padding: '0 4px',
				},
			},
		},
		MuiSelect: {
			defaultProps: {
				IconComponent: ChevronIcon,
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					border: `1px solid ${getCssVariable('--input-border-color')}`,
					background: getCssVariable('--white'),
					lineHeight: getCssVariable('--input-line-height'),
					borderRadius: getCssVariable('--border-radius'),
					input: {
						'height': 'unset',
						'padding': 10,
						'&::placeholder': {
							fontWeight: getCssVariable(''),
							color: getCssVariable('--gray-medium'),
							lineHeight: getCssVariable('--input-line-height'),
							fontSize: getCssVariable('--text-body-size'),
						},
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					'color': 'rgba(0, 0, 0, 0.38)',
					'&.Mui-focused': {
						color: 'rgba(0, 0, 0, 0.38)',
					},
					'&.MuiFormLabel-filled': {
						color: 'rgba(0, 0, 0, 0.38)',
					},
					'&.MuiInputLabel-shrink': {
						transform: 'translate(14px, -6px) scale(0.75)',
						transformOrigin: 'top left',
					},
					'transform': 'translateY(50%)',
					'top': '0',
					'left': '0',
					'paddingLeft': '10px',

					'&::before': {
						content: '""',
						display: 'block',
						position: 'absolute',
						top: '0',
						left: '50%',
						transform: 'translateX(-50%)',
						width: '100%',
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'borderRadius': getCssVariable('--border-radius'),
					'&.Mui-error': {
						border: '1px solid red',
					},
				},
				input: {
					display: 'flex',
				},
				notchedOutline: {
					border: 'none',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
                root: {
                    boxShadow: '0px 2px 12px 0px #0000001A',
                },
				elevation8: {
					marginTop: '4px',
					width: '200px',
					borderRadius: getCssVariable('--border-radius'),
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						'background': 'transparent',
						'&:hover': {
							background: 'transparent',
						},
					},
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					borderBottom: `2px solid ${getCssVariable('--tabs-border-color')}`,
					overflow: 'visible',
				},
				indicator: {
					bottom: '-2px',
				},
				scroller: {
					'&&': {
						overflow: 'visible !important',
						flex: '0',
					},
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					minWidth: '155px',
					textTransform: 'capitalize',
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					'border': 0,
					'borderBottom': `1px solid ${getCssVariable('--border-color')}`,
					'&::before': {
						display: 'none',
					},
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
						transform: 'rotate(90deg)',
					},
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					padding: '16px',
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: ({ theme, ownerState }) => ({
					...(ownerState.severity === 'success' && {
						backgroundColor: theme.palette.success.main,
						color: theme.palette.success.contrastText,
						fontWeight: getCssVariable('--font-weight-regular'),
						fontSize: getCssVariable('--text-body-size'),
					}),
				}),
				icon: ({ theme, ownerState }) => ({
					...(ownerState.severity === 'success' && {
						color: theme.palette.success.contrastText,
					}),
				}),
			},
		},
	},
};

export default theme;
