import { PublicAds } from 'shared/models/ads';

import { apiService } from 'core/api/services';

export class PublicService {
	async getPublicTagToken(
		userId: number | undefined,
		tagId: number | undefined
	): Promise<{ token: string }> {
		return apiService.responseHandler(
			await apiService.get<{ token: string }>(`api/client/user/${userId}/tag/${tagId}/public`)
		);
	}

	async getPublicTagAds(
		token: string,
		page: number = 1,
		sort: string = '{""reach.eu_total_reach":-1}',
		search: string = ''
	): Promise<PublicAds> {
		const limit = 30;
		const offset = (page - 1) * limit;

		let queryParams = `token=${token}&offset=${offset}&limit=${limit}&sort=${sort}`;

		if (search) {
			queryParams += `&search=${encodeURIComponent(search)}`;
		}

		const url = `api/public/tag/?${queryParams}`;
		return apiService.responseHandler(
			await apiService.get<PublicAds>(url, { headers: { 'No-Auth': true } })
		);
	}

	async getPublicAdToken(
		userId: number | undefined,
		adId: string | undefined
	): Promise<{ token: string }> {
		return apiService.responseHandler(
			await apiService.get<{ token: string }>(`api/client/user/${userId}/ads/${adId}/public`)
		);
	}
}

export const publicService = new PublicService();
