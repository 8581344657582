import { SvgIcon, SvgIconProps } from '@mui/material';

export const HeartRoundedIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="15" height="14" viewBox="0 0 15 14" fill="none" {...props}>
			<path
				d="M9.89847 1.75C11.9531 1.75 13.3337 3.70563 13.3337 5.53C13.3337 9.22469 7.60403 12.25 7.50033 12.25C7.39662 12.25 1.66699 9.22469 1.66699 5.53C1.66699 3.70563 3.04755 1.75 5.10218 1.75C6.28181 1.75 7.0531 2.34719 7.50033 2.87219C7.94755 2.34719 8.71884 1.75 9.89847 1.75Z"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
};
