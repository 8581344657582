import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';

import styles from '../Checkout.module.scss';

interface SpecialOfferTimerProps {
	endTime: string;
	offerExpired: boolean;
	setOfferExpired: Dispatch<SetStateAction<boolean>>;
}

interface TimeLeft {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

const SpecialOfferTimer = ({ endTime, offerExpired, setOfferExpired }: SpecialOfferTimerProps) => {
	const calculateTimeLeft = (): TimeLeft => {
		const difference = +new Date(endTime) - +new Date();
		let timeLeft: TimeLeft = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
		};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			const newTimeLeft = calculateTimeLeft();
			setTimeLeft(newTimeLeft);

			if (
				newTimeLeft.days === 0 &&
				newTimeLeft.hours === 0 &&
				newTimeLeft.minutes === 0 &&
				newTimeLeft.seconds === 0
			) {
				clearInterval(timer);
				setOfferExpired(true);
			}
		}, 1000);

		return () => clearInterval(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endTime]);

	const formatTime = (time: number): string => {
		return time < 10 ? `0${time}` : `${time}`;
	};

	const timerString =
		timeLeft.hours > 0
			? `${formatTime(timeLeft.hours)}:${formatTime(timeLeft.minutes)}:${formatTime(timeLeft.seconds)}`
			: `${formatTime(timeLeft.minutes)}:${formatTime(timeLeft.seconds)}`;

	return (
		<Stack className={styles.timerContainer}>
			{offerExpired ? (
				<Typography variant="body2" color="error" className={styles.timerText}>
					Offer expired!
				</Typography>
			) : (
				<>
					<Typography variant="body2" color="primary" className={styles.timerText}>
						Offer expires in:
					</Typography>

					{timeLeft.days > 0 && (
						<Typography variant="body2">
							{timeLeft.days} {timeLeft.days === 1 ? 'day' : 'days'}
						</Typography>
					)}
					<Typography variant="body2" color="primary" className={styles.timerText}>
						{timerString}
					</Typography>
				</>
			)}
		</Stack>
	);
};

export default SpecialOfferTimer;
