import { useContext } from 'react';

import { SidebarContext, SidebarContextType } from './SidebarProvider';

export const useSidebar = (): SidebarContextType => {
	const context = useContext(SidebarContext);
	if (!context) {
		throw new Error('useUser must be used within a UserProvider');
	}
	return context;
};
