import { BrowserRouter } from 'react-router-dom';

import { DragProvider } from './DragProvider';
import { MuiProvider } from './MuiProvider';
import { ReactQueryProvider } from './ReactQueryProvider';
import ReduxStoreProvider from './ReduxStoreProvider';
import { SidebarProvider } from './SidebarProvider';
import { UserProvider } from './UserProvider';
import { ToastProvider } from './toast';
import './translations';
import { MultiLanguageSupportProvider } from './translations/contexts/MultiLanguageSupportContext';
import { combineContextProviders } from './utils';
import { VideoPlayerProvider } from './VideoPlayerProvider';

const providers = [
	MuiProvider,
	BrowserRouter,
	ReactQueryProvider,
	ToastProvider,
	MultiLanguageSupportProvider,
	UserProvider,
	SidebarProvider,
	DragProvider,
	ReduxStoreProvider,
    VideoPlayerProvider
];

export const CoreProvider = combineContextProviders(...providers);
