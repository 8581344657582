import { FC, ReactNode } from 'react';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import styles from '../MainInfo.module.scss';

interface InfoItemProps {
	spentInfo?: boolean;
	impressionsInfo?: boolean;
	spent?: string;
	reach?: number | undefined;
	icon: ReactNode;
	title: string;
	children: ReactNode;
	primary?: boolean;
	impressions?: boolean;
}

const InfoItem: FC<InfoItemProps> = ({
	icon,
	title,
	children,
	primary,
	spent,
	impressions,
	spentInfo,
	impressionsInfo,
}) => {
	const getClassName = () => {
		if (primary) {
			return styles.detailWide;
		}
		if ((impressionsInfo && !spent) || (spentInfo && !impressions)) {
			return styles.detailMax;
		}
		return styles.detail;
	};

	return (
		<Paper className={getClassName()}>
			<Box className={styles.detailHeader}>
				<Box className={styles.deatilTitle}>
					{icon}
					<Typography variant="subtitle1">{title}</Typography>
				</Box>
				{primary && (
					<Tooltip
						title="Placeholder text"
						PopperProps={{
							sx: {
								zIndex: 2000,
							},
						}}
					>
						<IconButton size="small" style={{ marginLeft: 'auto' }}>
							<InfoOutlined />
						</IconButton>
					</Tooltip>
				)}
			</Box>
			<Divider />
			<Box className={styles.detailInfo}>
				<Box className={styles.deatilInfo__content}>{children}</Box>
			</Box>
		</Paper>
	);
};

export default InfoItem;
