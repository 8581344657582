import { AxiosResponse } from 'axios';
import { EmailVerify, LoginUserData, RegisterUserData, UserDTO } from 'core/api/models/user';
import { apiService } from 'core/api/services';

export class AuthApiService {
	async postRegister(userData: RegisterUserData) {
		return apiService.responseHandler(
			await apiService.post<unknown, RegisterUserData>(`api/client/auth/register`, userData)
		);
	}

	async postLogin(userData: LoginUserData): Promise<AxiosResponse<UserDTO>> {
		const response: AxiosResponse<UserDTO> = await apiService.post<UserDTO, LoginUserData>(
			`api/client/auth/login`,
			userData
		);
		return response;
	}

	async postLoginGoogle(userData: any) {
		return apiService.responseHandler(
			await apiService.post<unknown, unknown>(`/auth/google/callback`, userData)
		);
	}

	async postEmailVerify(data: EmailVerify) {
		return apiService.responseHandler(
			await apiService.post<unknown, EmailVerify>(`api/client/auth/verify-email`, data)
		);
	}

	async postResendEmailVerify(email: any) {
		return apiService.responseHandler(
			await apiService.post<unknown, unknown>(`api/client/auth/resend-verify-token`, {
				email,
			})
		);
	}

	async postForgotPassword(data: any) {
		return apiService.responseHandler(
			await apiService.post<unknown, unknown>(`api/client/auth/forgot-password`, data)
		);
	}
	async postResetPassword(newPassword: any) {
		return apiService.responseHandler(
			await apiService.post<unknown, unknown>(
				`api/client/auth/save-new-password`,
				newPassword
			)
		);
	}
}

export const authApiService = new AuthApiService();
