import { SvgIcon, SvgIconProps } from '@mui/material';

export const CircleOnIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
			<g clipPath="url(#clip0_902_24963)">
				<path
					d="M7 14.2842C10.866 14.2842 14 11.1502 14 7.28418C14 3.41819 10.866 0.28418 7 0.28418C3.13401 0.28418 0 3.41819 0 7.28418C0 11.1502 3.13401 14.2842 7 14.2842Z"
					fill="#6C757D"
					fillOpacity="0.5"
				/>
			</g>
			<defs>
				<clipPath id="clip0_902_24963">
					<rect width="14" height="14" fill="white" transform="translate(0 0.28418)" />
				</clipPath>
			</defs>
		</SvgIcon>
	);
};
