import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { AppBar, Box, Button, Stack, Toolbar, Typography } from '@mui/material';

import { BadgeEnum, RoutesEnum } from 'shared/enums';

import { HashtagIcon } from '../Icons';
import { InfoBadge } from '../InfoBadge';
import Logo from 'assets/images/logo.svg';

import styles from './ExternalPageHeader.module.scss';

interface ExternalPageHeaderProps {
	tagName: string;
	tagCount: number;
	ownerName: string;
}

const ExternalPageHeader: React.FC<ExternalPageHeaderProps> = ({
	tagCount,
	tagName,
	ownerName,
}) => {
	const navigate = useNavigate();
	const subtitle = `This Tag/board is shared with you by ${ownerName ?? 'user'} using `;

	const handleLogin = () => {
		navigate(RoutesEnum.LOGIN);
	};
	const handleSignup = () => {
		navigate(RoutesEnum.SIGNUP);
	};

	return (
		<AppBar position="static" className={styles.appBar}>
			<Toolbar>
				<Box mr="auto" className={styles.flexGrow}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<HashtagIcon sx={{ width: '20px', height: '20px' }} />
						<Typography variant="h5" noWrap className={styles.titleColor}>
							{tagName}
						</Typography>
						<InfoBadge label={tagCount} type={BadgeEnum.COUNT} />
					</Stack>
					<Typography variant="small" noWrap className={styles.subtitleColor}>
						{subtitle}
						<Link to="/" className={styles.linkSpan}>
							AdUniverse.ai
						</Link>
					</Typography>
				</Box>
				<Box className={styles.flexGrow} order={2}>
					<img src={Logo} alt="Logo" />
				</Box>
				<Stack
					direction="row-reverse"
					alignItems="center"
					spacing={1}
					className={styles.flexGrow}
					order={3}
				>
					<Button variant="contained" color="primary" onClick={handleSignup}>
						Sign up
					</Button>
					<Button variant="outlined" color="primary" onClick={handleLogin}>
						Log in
					</Button>
				</Stack>
			</Toolbar>
		</AppBar>
	);
};

export default ExternalPageHeader;
