import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box, Button, InputBase } from '@mui/material';

import { SearchIcon } from '../Icons';

import styles from './Search.module.scss';

interface SearchProps {
	onSearch: (arg: string) => void;
	resetSearch: () => void;
	searchConducted: boolean;
}

const Search: FC<SearchProps> = ({ onSearch, resetSearch, searchConducted }) => {
	const [inputValue, setInputValue] = useState('');

	const handleSearchClick = useCallback(() => {
		onSearch(inputValue);
	}, [inputValue, onSearch]);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (e.key === 'Enter') {
				handleSearchClick();
			}
		},
		[handleSearchClick]
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setInputValue(newValue);

		if (newValue === '') {
			resetSearch();
		}
	};

	useEffect(() => {
		if (!searchConducted) {
			setInputValue('');
		}
	}, [searchConducted]);

	return (
		<Box className={styles.search}>
			<InputBase
				type="search"
				className={styles.searchInput}
				placeholder="Search Ads"
				inputProps={{ 'aria-label': 'search ads' }}
				value={inputValue}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
			/>

			<Button
				variant="contained"
				className={styles.searchButton}
				type="submit"
				disabled={!inputValue.trim()}
				aria-label="search"
				onClick={handleSearchClick}
			>
				<SearchIcon className={styles.searchIcon} />
			</Button>
		</Box>
	);
};

const MemoizedSearch = React.memo(Search);
MemoizedSearch.displayName = 'Search';

export default MemoizedSearch;
