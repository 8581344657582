import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { Ad } from 'shared/models/ads';

import { RootState } from 'app/store/store';
import placeholder from 'assets/images/placeholder.avif';
import { useVideoPlayerContext } from 'core/VideoPlayerProvider/useVideoPlayerContext';
import { setCurrentMedia } from 'features/media/mediaSlice';
import AdPlayer from './AdPlayer';

import styles from '../AdCard.module.scss';

interface CardMediaWrapperProps {
	ad: Ad | undefined;
	detail?: boolean;
	playerInitialized: boolean[];
	setPlayerInitialized: React.Dispatch<React.SetStateAction<boolean[]>>;
	setCurrentSubFormat: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const CardMediaWrapper: FC<CardMediaWrapperProps> = React.memo(
	({ ad, detail, playerInitialized, setPlayerInitialized, setCurrentSubFormat }) => {
		const { setActiveVideoIndex } = useVideoPlayerContext();
		const dispatch = useDispatch();
		const { isOpen } = useSelector((state: RootState) => state.detailsModal);

		const [currentSlide, setCurrentSlide] = useState(0);
		const [imageLoaded, setImageLoaded] = useState(false);
		const [fileErrors, setFileErrors] = useState<boolean[]>([]);

		const mediaFiles = useMemo(() => ad?.mediaFiles || [], [ad]);
		const hasMediaFiles = useMemo(() => mediaFiles.length > 0, [mediaFiles]);

		const handleImageLoad = useCallback(() => setImageLoaded(true), []);
		const handleError = useCallback((index: number) => {
			setFileErrors((prevErrors) => {
				const newErrors = [...prevErrors];
				newErrors[index] = true;
				return newErrors;
			});
		}, []);

		const handleNext = useCallback(() => {
			const length = mediaFiles.length;
			if (length > 1) {
				setPlayerInitialized([false]);
				setCurrentSlide((prev) => {
					const nextSlide = (prev + 1) % length;
					setCurrentSubFormat(ad?.sub_format[nextSlide]);
					return nextSlide;
				});
			}
		}, [mediaFiles.length, setPlayerInitialized, setCurrentSubFormat, ad?.sub_format]);

		const handlePrev = useCallback(() => {
			const length = mediaFiles.length;
			if (length > 1) {
				setPlayerInitialized([false]);
				setCurrentSlide((prev) => {
					const prevSlide = (prev - 1 + length) % length;
					setCurrentSubFormat(ad?.sub_format[prevSlide]);
					return prevSlide;
				});
			}
		}, [mediaFiles.length, setPlayerInitialized, setCurrentSubFormat, ad?.sub_format]);

		const showControls =
			(ad?.format === 'carousel' || ad?.format === 'dco' || ad?.format === 'dpa') &&
			mediaFiles.length > 1;

		const handleMediaChange = useCallback(
			(index: number, subformat: string) => {
				dispatch(setCurrentMedia({ index, subformat }));
			},
			[dispatch]
		);

		useEffect(() => {
			const subformat =
				ad?.format === 'carousel' || ad?.format === 'dco' || ad?.format === 'dpa'
					? ad?.sub_format?.[currentSlide]
					: ad?.format;

			handleMediaChange(currentSlide, subformat || 'image');
		}, [currentSlide, ad?.format, ad?.sub_format, handleMediaChange]);

		useEffect(() => {
			setCurrentSlide(0);
			setFileErrors(new Array(mediaFiles.length).fill(false));
		}, [ad, mediaFiles.length]);

		useEffect(() => {
			if (isOpen) {
				setPlayerInitialized((prev) => prev.map(() => false));
			}
		}, [isOpen, setPlayerInitialized]);

		useEffect(() => {
			setPlayerInitialized(new Array(mediaFiles.length).fill(false));
		}, [mediaFiles.length]);

		const handleVideoClick = useCallback(
			(index: number) => {
				const uniqueId = `${ad?._id}-${index}`;

				setPlayerInitialized((prev) => prev.map((_, i) => i === index));

				setActiveVideoIndex(uniqueId);
			},
			[ad?._id, setActiveVideoIndex]
		);

		const renderMediaByFormat = () => {
			switch (ad?.format) {
				case 'video':
					return renderSingleVideo();
				case 'carousel':
				case 'dco':
				case 'dpa':
					return renderCarousel();
				default:
					return renderImage();
			}
		};

		const renderSingleVideo = () => (
			<>
				{!playerInitialized[0] ? (
					<div
						style={{
							position: 'relative',
							width: '100%',
							height: '100%',
							maxHeight: detail ? '100%' : '500px',
							cursor: 'pointer',
						}}
						onClick={() => handleVideoClick(0)}
					>
						<img
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'contain',
								maxHeight: 'unset',
								display: 'block',
							}}
							src={
								!fileErrors[0] && mediaFiles[0]?.thumbnailUrl
									? mediaFiles[0].thumbnailUrl
									: placeholder
							}
							alt="Video Thumbnail"
							onLoad={handleImageLoad}
							onError={() => handleError(0)}
						/>
						<PlayArrowIconStyled />
					</div>
				) : (
					<AdPlayer
						index={0}
						uniqueId={`${ad?._id}-0`}
						playerInitialized={playerInitialized}
						setPlayerInitialized={setPlayerInitialized}
						url={!fileErrors[0] ? mediaFiles[0].mediaUrl || placeholder : placeholder}
						handleError={() => handleError(0)}
						detail={detail}
						scrollPosition={window.scrollY}
					/>
				)}
			</>
		);

		const renderImage = () => (
			<img
				style={{
					maxHeight: '500px',
					minHeight: '200px',
					width: '100%',
					height: 'auto',
					objectFit: 'contain',
					display: 'block',
				}}
				src={
					!fileErrors[0] && hasMediaFiles
						? mediaFiles[0]?.thumbnailUrl || mediaFiles[0].mediaUrl
						: placeholder
				}
				alt={ad?.brand_name}
				onLoad={handleImageLoad}
				onError={() => handleError(0)}
			/>
		);

		const renderCarousel = () => {
			if (!hasMediaFiles || ad?.sub_format.length === 0) {
				return (
					<div
						style={{
							width: '100%',
							maxHeight: detail ? '100%' : '500px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<img
							style={{
								width: '100%',
								height: 'auto',
								objectFit: 'contain',
								display: 'block',
							}}
							src={placeholder}
							alt="Placeholder"
						/>
					</div>
				);
			}

			return (
				<>
					{mediaFiles.map((file, index) => {
						const formatType = ad?.sub_format?.[index];
						const displaySrc = !fileErrors[index]
							? file?.thumbnailUrl || file?.mediaUrl
							: placeholder;

						return (
							<div
								key={index}
								style={{
									width: '100%',
									display: currentSlide === index ? 'block' : 'none',
									opacity: currentSlide === index && imageLoaded ? 1 : 0,
									transition: 'opacity 0.3s ease-in-out',
									maxHeight: detail ? '100%' : '500px',
									position: 'relative',
									cursor: formatType === 'video' ? 'pointer' : 'default',
								}}
								onClick={() => {
									if (formatType === 'video') {
										setPlayerInitialized((prev) => {
											const newState = [...prev];
											newState[index] = true;
											return newState;
										});
									}
								}}
							>
								{formatType === 'video' && !playerInitialized[index] ? (
									<>
										<img
											style={{
												width: '100%',
												height: '100%',
												objectFit: 'contain',
												maxHeight: detail ? '100%' : '500px',
												display: 'block',
											}}
											src={displaySrc}
											alt={`Video Thumbnail ${index}`}
											onLoad={handleImageLoad}
											onError={() => handleError(index)}
										/>
										<PlayArrowIconStyled />
									</>
								) : formatType === 'video' && playerInitialized[index] ? (
									<AdPlayer
										uniqueId={`${ad?._id}-${index}`}
										playerInitialized={playerInitialized}
										setPlayerInitialized={setPlayerInitialized}
										index={index}
										url={
											!fileErrors[index]
												? file.mediaUrl || placeholder
												: placeholder
										}
										handleError={() => handleError(index)}
										detail={detail}
										scrollPosition={window.scrollY}
									/>
								) : (
									<img
										style={{
											width: '100%',
											height: 'auto',
											maxHeight: detail ? '100%' : '500px',
											objectFit: 'contain',
											display: 'block',
										}}
										src={displaySrc}
										alt={`Slide ${index}`}
										onError={() => handleError(index)}
										onLoad={handleImageLoad}
									/>
								)}
							</div>
						);
					})}
					{showControls && <CarouselControls onNext={handleNext} onPrev={handlePrev} />}
				</>
			);
		};

		return (
			<Box
				sx={{
					position: 'relative',
					width: '100%',
					height: imageLoaded ? 'calc(100% - 56px)' : '350px',
					maxHeight: 'unset',
				}}
			>
				{renderMediaByFormat()}
			</Box>
		);
	}
);

const PlayArrowIconStyled = () => (
	<Box
		sx={{
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			color: '#009EC2',
			background: 'rgba(0, 0, 0, 0.7)',
			borderRadius: '50%',
			padding: '10px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 2,
		}}
	>
		<PlayArrow style={{ fontSize: '2rem' }} />
	</Box>
);

const CarouselControls: FC<{ onNext: () => void; onPrev: () => void }> = ({ onNext, onPrev }) => (
	<Box
		className={styles.controls}
		sx={{
			position: 'absolute',
			width: '100%',
			top: '50%',
			transform: 'translateY(-50%)',
			display: 'flex',
			justifyContent: 'space-between',
			p: 2,
		}}
	>
		<IconButton sx={{ width: '32px', height: '32px', cursor: 'pointer' }} onClick={onPrev}>
			<ArrowBackIosNewIcon />
		</IconButton>
		<IconButton sx={{ width: '32px', height: '32px', cursor: 'pointer' }} onClick={onNext}>
			<ArrowForwardIosIcon />
		</IconButton>
	</Box>
);

export default CardMediaWrapper;
