import { SvgIcon, SvgIconProps } from '@mui/material';

export const CopyIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
			<path
				d="M14.6785 4.71795L10.1411 0.180513C10.0258 0.0650729 9.8694 0.000143632 9.70624 0H7.67957C7.08114 0 6.50721 0.237728 6.08405 0.660887C5.66089 1.08405 5.42316 1.65797 5.42316 2.25641V3.28205H4.39752C3.79909 3.28205 3.22516 3.51978 2.802 3.94294C2.37884 4.3661 2.14111 4.94002 2.14111 5.53846V13.7436C2.14111 14.342 2.37884 14.916 2.802 15.3391C3.22516 15.7623 3.79909 16 4.39752 16H10.1411C10.7396 16 11.3135 15.7623 11.7366 15.3391C12.1598 14.916 12.3975 14.342 12.3975 13.7436V12.7179H12.6027C13.2011 12.7179 13.775 12.4802 14.1982 12.0571C14.6213 11.6339 14.8591 11.06 14.8591 10.4615V5.12821C14.8526 4.97362 14.7882 4.82715 14.6785 4.71795ZM10.3462 2.10051L12.7585 4.51282H10.3462V2.10051ZM11.1668 13.7436C11.1668 14.0156 11.0587 14.2765 10.8664 14.4688C10.674 14.6612 10.4131 14.7692 10.1411 14.7692H4.39752C4.12551 14.7692 3.86463 14.6612 3.67229 14.4688C3.47994 14.2765 3.37188 14.0156 3.37188 13.7436V5.53846C3.37188 5.26644 3.47994 5.00557 3.67229 4.81322C3.86463 4.62088 4.12551 4.51282 4.39752 4.51282H5.42316V10.4615C5.42316 11.06 5.66089 11.6339 6.08405 12.0571C6.50721 12.4802 7.08114 12.7179 7.67957 12.7179H11.1668V13.7436ZM12.6027 11.4872H7.67957C7.40756 11.4872 7.14668 11.3791 6.95434 11.1868C6.76199 10.9944 6.65393 10.7336 6.65393 10.4615V2.25641C6.65393 1.98439 6.76199 1.72352 6.95434 1.53117C7.14668 1.33883 7.40756 1.23077 7.67957 1.23077H9.11547V5.12821C9.1176 5.29075 9.18312 5.44605 9.29806 5.561C9.41301 5.67595 9.56831 5.74146 9.73086 5.74359H13.6283V10.4615C13.6283 10.7336 13.5202 10.9944 13.3279 11.1868C13.1355 11.3791 12.8747 11.4872 12.6027 11.4872Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
