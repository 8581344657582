import { MutableRefObject, useEffect } from 'react';

type RefType = MutableRefObject<HTMLDivElement | null>;

export const useOutsideClickMultipleRefs = (
	refs: RefType[],
	callback: () => void,
	activeMenu: boolean
) => {
	useEffect(() => {
		if (!activeMenu) {
			return;
		}
		const handleClickOutside = (event: MouseEvent) => {
			if (refs.every((ref) => ref.current && !ref.current.contains(event.target as Node))) {
				callback();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [refs, callback, activeMenu]);
};
