import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import Logo from 'assets/images/logoDark.svg';

import styles from './Header.module.scss';

interface HeaderProps {
	title?: string;
}

const Header: FC<HeaderProps> = ({ title }) => {
	return (
		<React.Fragment>
			<Stack direction="row" spacing={1} className={styles.logo}>
				<img src={Logo} alt="Mark" loading="lazy" />
			</Stack>
			{title && (
				<Typography variant="h4" className={styles.title}>
					{title}
				</Typography>
			)}
		</React.Fragment>
	);
};

export default Header;
