import { configureStore } from '@reduxjs/toolkit';
import adsReducer from 'features/ads/adsSlice';
import detailsModalReducer from 'features/detailsModal/detailsModalSlice';
import mediaSlice from 'features/media/mediaSlice';
import modalManagementReducer from 'features/modal/modalManagementSlice';
import searchReducer from 'features/search/searchSlice';
import sortReducer from 'features/sort/sortSlice';
import tagsReducer from 'features/tags/tagsSlice';
import toggleOptionsReducer from 'features/toggleOptions/toggleOptionsSlice';
import zoomReducer from 'features/zoom/gridZoomSlice';

export const store = configureStore({
	reducer: {
		search: searchReducer,
		gridZoom: zoomReducer,
		modalManagement: modalManagementReducer,
		toggleOptions: toggleOptionsReducer,
		tags: tagsReducer,
		sort: sortReducer,
		ads: adsReducer,
		detailsModal: detailsModalReducer,
		media: mediaSlice,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
