import { StripePromoCode } from 'shared/models/billing';

import { billingAndPlansApiService } from '../../services/billing/BillingAndPlansApiService';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useGetPromoCode = (
	fetchPromoCode: boolean,
	promoCode?: string
): UseQueryResult<StripePromoCode> => {
	return useQuery({
		queryKey: ['promoCode', promoCode],
		queryFn: async () => await billingAndPlansApiService.getPromoCode(promoCode),
		enabled: !!promoCode && fetchPromoCode,
		retry: false,
	});
};
