import { SvgIcon, SvgIconProps } from '@mui/material';

export const CommentIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="21" height="16" viewBox="0 0 21 16" fill="none" {...props}>
			<path
				d="M4.12383 15.4285C4.02103 15.4303 3.91934 15.4065 3.82744 15.3592C3.73553 15.3118 3.65615 15.2423 3.59608 15.1566C3.53601 15.0709 3.49704 14.9715 3.48248 14.8669C3.46793 14.7623 3.47824 14.6557 3.51253 14.5561L4.87097 9.97589C4.5753 9.18839 4.42557 8.35102 4.42947 7.50695C4.42812 6.57845 4.60708 5.659 4.95587 4.80246C5.29801 3.98281 5.78771 3.23691 6.39922 2.60397C7.01377 1.96979 7.74361 1.46588 8.54726 1.12087C9.37839 0.758299 10.2721 0.571442 11.175 0.571442C12.0779 0.571442 12.9716 0.758299 13.8027 1.12087C15.0203 1.65679 16.0589 2.54686 16.7905 3.6815C17.5221 4.81615 17.9149 6.14586 17.9205 7.50695C17.9152 9.3452 17.2043 11.1071 15.9423 12.4099C15.3263 13.0383 14.6004 13.5415 13.8027 13.893C12.1974 14.5839 10.3951 14.6151 8.768 13.9803L4.31061 15.3761C4.25099 15.4023 4.18813 15.42 4.12383 15.4285ZM11.1708 1.90604C10.0954 1.91082 9.04493 2.23882 8.1488 2.8496C7.25266 3.46038 6.55004 4.32725 6.12753 5.34336C5.56619 6.74018 5.56619 8.30862 6.12753 9.70544C6.17811 9.8492 6.17811 10.0066 6.12753 10.1504L5.08323 13.7883L8.60669 12.6891C8.74659 12.6371 8.89979 12.6371 9.03969 12.6891C9.71248 12.9761 10.4338 13.1239 11.1623 13.1239C11.8907 13.1239 12.612 12.9761 13.2848 12.6891C13.9477 12.4023 14.5491 11.984 15.0544 11.458C15.5597 10.9321 15.959 10.3089 16.2295 9.62405C16.5001 8.93925 16.6364 8.20633 16.6309 7.46729C16.6253 6.72824 16.4778 5.99759 16.197 5.31719C15.7754 4.30601 15.0746 3.44399 14.1813 2.83772C13.288 2.23146 12.2413 1.90755 11.1708 1.90604Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
