import React from 'react';

import { Typography } from '@mui/material';

import { BadgeEnum } from 'shared/enums';

import styles from './InfoBadge.module.scss';

interface InfoBadgeProps {
	label: string | number;
	type: BadgeEnum;
	bgColor?: string;
	color?: string;
}

const badgeStyles: Record<BadgeEnum, string> = {
	[BadgeEnum.PRO]: styles.pro,
	[BadgeEnum.PREMIUM]: styles.premium,
	[BadgeEnum.COUNT]: styles.count,
	[BadgeEnum.EMPTY]: styles.empty,
};

const InfoBadge: React.FC<InfoBadgeProps> = ({ label, type }) => {
	const style = badgeStyles[type] || styles.count;

	return (
		<Typography variant="caption" sx={{ color: 'red' }} className={style}>
			{label}
		</Typography>
	);
};

export default InfoBadge;
