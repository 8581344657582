import { FC, useState } from 'react';
import { useDrop } from 'react-dnd';

import {
	Box,
	Button,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Popover,
	Tooltip,
	Typography,
} from '@mui/material';

import { CopyIcon, ElipsisIcon, PencilIcon, TrashIcon } from 'shared/components/Icons';
import { useCopyFolderLink } from 'shared/hooks/useCopyFolderLink';
import { DropAdItem } from 'shared/models/ads';
import { Tag } from 'shared/models/tags';

import { DeleteDialog } from '../../DeleteDialog';
import { InputEditWrapper } from '../../InputEditWrapper';

import styles from '../../TagManagement.module.scss';

interface SubTagItemProps {
	subTag: Tag;
	updateTagName: (subTagId: number, newSubTagName: string) => void;
	deleteTag: (tagId: number, tagName: string) => void;
	handleClose: VoidFunction;
	adCount: number;
	onSubTagClick: (subTagId: number) => void;
	handleDropItem: (item: DropAdItem, tagId: number) => void;
}

const SubTagItem: FC<SubTagItemProps> = ({
	subTag,
	updateTagName,
	deleteTag,
	handleClose,
	adCount,
	onSubTagClick,
	handleDropItem,
}) => {
	const [anchorElSubTag, setAnchorElSubTag] = useState<null | HTMLElement>(null);
	const [isEditingSubTag, setIsEditingSubTag] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	const { handleCopyFolderLink } = useCopyFolderLink(subTag.id);
	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: 'AD_CARD',
			drop: (item: DropAdItem) => handleDropItem(item, subTag.id),
			collect: (monitor: any) => ({
				isOver: monitor.isOver(),
			}),
		}),
		[subTag]
	);

	const handleSubTagMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElSubTag(event.currentTarget);
	};

	const handleSubTagMenuClose = () => {
		setAnchorElSubTag(null);
	};

	const handleSaveEditedSubTagName = (newName: string) => {
		updateTagName(subTag.id, newName);
		setIsEditingSubTag(false);
		handleSubTagMenuClose();
	};

	const handleDeleteClick = () => {
		setIsDeleteDialogOpen(true);
		handleSubTagMenuClose();
	};

	const handleConfirmDelete = () => {
		deleteTag(subTag.id, subTag.name);
		setIsDeleteDialogOpen(false);
	};

	return (
		<Box
			key={subTag.id}
			ref={drop}
			style={{
				opacity: isOver ? '.4' : '1',
			}}
		>
			{isEditingSubTag ? (
				<InputEditWrapper
					isSubTag
					isEditing
					prefix="#"
					value={subTag.name}
					onSave={handleSaveEditedSubTagName}
					onCancel={() => {
						setIsEditingSubTag(false);
						handleSubTagMenuClose();
					}}
				/>
			) : (
				<Box className={styles.singleTagContent} onMouseLeave={handleClose}>
					<Box onClick={() => onSubTagClick(subTag.id)} className={styles.tagName}>
						<span>#</span>
						<Tooltip title={subTag.name} placement="top" arrow>
							<Typography className={styles.ellipsis} variant="subtitle1">
								{subTag.name}
							</Typography>
						</Tooltip>
					</Box>

					<IconButton
						onClick={handleSubTagMenuClick}
						className={styles.moreButton}
						size="small"
						sx={{ visibility: 'hidden' }}
					>
						<ElipsisIcon
							sx={{ width: '20px', height: '20px' }}
							className={styles.moreIcon}
						/>
					</IconButton>
					<Popover
						disableScrollLock={true}
						open={Boolean(anchorElSubTag)}
						anchorEl={anchorElSubTag}
						onClose={handleSubTagMenuClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						sx={{ zIndex: 2000 }}
						slotProps={{
							paper: {
								style: {
									position: 'absolute',
								},
							},
						}}
					>
						<List component="nav" aria-label="subtag actions">
							<Button
								onClick={() => setIsEditingSubTag(true)}
								className={styles.menuButton}
							>
								<ListItem className={styles.menuList}>
									<ListItemIcon>
										<PencilIcon sx={{ width: '16px', height: '16px' }} />
									</ListItemIcon>
									<ListItemText primary="Rename" />
								</ListItem>
							</Button>
							<Button onClick={handleDeleteClick} className={styles.menuButton}>
								<ListItem className={styles.menuList}>
									<ListItemIcon>
										<TrashIcon sx={{ width: '16px', height: '16px' }} />
									</ListItemIcon>
									<ListItemText primary="Delete" />
								</ListItem>
							</Button>
							<Divider />
							<Button
								onClick={() => {
									handleCopyFolderLink();
									handleSubTagMenuClose();
								}}
								className={styles.menuButton}
							>
								<ListItem className={styles.menuList}>
									<ListItemIcon>
										<CopyIcon sx={{ width: '16px', height: '16px' }} />
									</ListItemIcon>
									<ListItemText primary="Copy folder" />
								</ListItem>
							</Button>
						</List>
					</Popover>
					<Typography className={styles.tagCount} sx={{ ml: 1 }}>
						{adCount}
					</Typography>
				</Box>
			)}
			<DeleteDialog
				dialogType="tag"
				open={isDeleteDialogOpen}
				onClose={() => setIsDeleteDialogOpen(false)}
				onConfirm={handleConfirmDelete}
				itemName={subTag.name}
			/>
		</Box>
	);
};

export default SubTagItem;
