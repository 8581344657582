import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { getPriceInEuros } from 'shared/helpers/billingHelpers';
import { Product } from 'shared/models/billing';

import styles from './SubscriptionSelection.module.scss';

interface AddonSelectionProps {
	addons: Product[] | undefined;
	selectedAddon: Product | null;
	handleAddonChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	formatPlanLabel: (plan: Product) => JSX.Element;
	disabled: boolean;
	billingInterval: 'month' | 'year';
	activeSubscriptionAddonId?: string;
}

const AddonSelectionV2 = ({
	addons,
	selectedAddon,
	handleAddonChange,
	formatPlanLabel,
	disabled,
	billingInterval,
	activeSubscriptionAddonId,
}: AddonSelectionProps) => {
	const getSelectedAddonValue = () => {
		if (selectedAddon?.id === activeSubscriptionAddonId) {
			return selectedAddon?.name || '';
		} else if (selectedAddon?.name) {
			return selectedAddon?.name;
		} else return '';
	};

	return (
		<Box className={styles.step} sx={{ mb: '24px' }}>
			<Typography className={styles.stepTitle} variant="subtitle2">
				2. Add-ons
			</Typography>
			<RadioGroup value={getSelectedAddonValue()} onChange={handleAddonChange}>
				{addons?.map((addon) => (
					<FormControlLabel
						sx={{
							'& .MuiFormControlLabel-label': {
								width: '100%',
							},
						}}
						key={addon.id}
						disabled={disabled}
						value={addon.name}
						control={<Radio />}
						label={
							<Box className={styles.planLabel}>
								<Box className={styles.planDetails}>
									<Typography variant="subtitle2">
										{formatPlanLabel(addon)}
										{addon.id === activeSubscriptionAddonId && (
											<Typography
												component="span"
												fontWeight="bold"
												sx={{ color: 'green' }}
											>
												{' '}
												(Active Subscription)
											</Typography>
										)}
									</Typography>
									{addon.metadata && (
										<Typography variant="subtitle1">
											Includes {addon.metadata.teamMembers} team seats and{' '}
											{addon.metadata.brands} brands
										</Typography>
									)}
								</Box>
								<Typography variant="subtitle1" className={styles.planPrice}>
									{`€${getPriceInEuros(addon.prices, billingInterval)}/${billingInterval === 'month' ? `mo` : 'yr'}`}
								</Typography>
							</Box>
						}
						style={{
							backgroundColor:
								addon.id === activeSubscriptionAddonId ? '#e6e6e6' : '#fff',
						}}
						className={styles.planOption}
					/>
				))}
				<FormControlLabel
					sx={{
						'& .MuiFormControlLabel-label': {
							width: '100%',
						},
					}}
					key="no-addon"
					disabled={disabled}
					value=""
					control={<Radio />}
					label={<Typography variant="subtitle2">No add-on</Typography>}
					className={styles.planOption}
				/>
			</RadioGroup>
		</Box>
	);
};

export default AddonSelectionV2;
