import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Add from '@mui/icons-material/Add';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import { RoutesEnum } from 'shared/enums';
import { useTags } from 'shared/hooks/useTags';

import { InputEditWrapper } from './InputEditWrapper';
import { TagItem } from './TagItem';
import { useUser } from 'core/UserProvider/useUser';
import { setSelectedTags, setTags } from 'features/tags/tagsSlice';

import styles from './TagManagement.module.scss';

const TagManagement = () => {
	const navigate = useNavigate();
	const { user } = useUser();
	const dispatch = useDispatch();
	const { tags, addTag, updateTagName, deleteTag } = useTags(user?.id);
	const [newTagName, setNewTagName] = useState('');
	const [addingTag, setAddingTag] = useState(false);

	useEffect(() => {
		if (tags.length > 0) {
			dispatch(setTags(tags));
		}
	}, [tags, dispatch]);

	const handleTagClick = (tagId: number) => {
		const clickedTag = tags.find((tag) => tag.id === tagId);
		let tagIdsToSet: number[] = [];

		if (clickedTag) {
			if (clickedTag.subTags && clickedTag.subTags.length > 0) {
				tagIdsToSet = [clickedTag.id, ...clickedTag.subTags.map((subTag) => subTag.id)];
			} else {
				tagIdsToSet = [clickedTag.id];
			}

			dispatch(setSelectedTags(tagIdsToSet));
		}

		navigate(`${RoutesEnum.TAGS}/${tagId}`, { state: { tagName: clickedTag?.name } });
	};

	const handleAddTagFinal = (name: string) => {
		if (name) {
			addTag(name, 0, null);
			setNewTagName('');
			setAddingTag(false);
		}
	};

	return (
		<Box className={styles.tagsWrapper}>
			<Box
				className={styles.tagsHeader}
				display="flex"
				alignItems="center"
				justifyContent="space-between"
			>
				<Typography variant="subtitle1" className={styles.tagsTitle}>
					FAVORITES
				</Typography>
				<Tooltip title="Add new folder" placement="top">
					<IconButton onClick={() => setAddingTag(true)}>
						<Add className={styles.tagAddIcon} />
					</IconButton>
				</Tooltip>
			</Box>
			{addingTag && (
				<InputEditWrapper
					prefix="#"
					value={newTagName}
					onSave={handleAddTagFinal}
					onCancel={() => setAddingTag(false)}
				/>
			)}
			<Box className={styles.tags}>
				{tags?.map((tag) => (
					<TagItem
						onTagClick={handleTagClick}
						adCount={tag.user_ads_count}
						key={tag.id}
						tag={tag}
						deleteTag={deleteTag}
						addTag={addTag}
						updateTagName={updateTagName}
					/>
				))}
			</Box>
		</Box>
	);
};

export default TagManagement;
