import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AttachMoneyOutlined from '@mui/icons-material/AttachMoneyOutlined';
import CommentOutlined from '@mui/icons-material/CommentOutlined';
import LightModeOutlined from '@mui/icons-material/LightModeOutlined';
import ThumbUpOutlined from '@mui/icons-material/ThumbUpOutlined';
import Box from '@mui/material/Box/Box';
import Link from '@mui/material/Link/Link';
import Typography from '@mui/material/Typography/Typography';

import {
	ArrowCircleUpIcon,
	BuildingIcon,
	CalendarIcon,
	DesktopIcon,
	FileIcon,
	LanguageIcon,
} from 'shared/components/Icons';
import { AdDetails } from 'shared/models/ads';

import { InfoItem } from './InfoItem';
import meta from 'assets/images/logos/facebook.svg';
import instagram from 'assets/images/logos/instagram.svg';
import linkedin from 'assets/images/logos/linkedin.svg';
import tiktok from 'assets/images/logos/tiktok.svg';
import dayjs from 'dayjs';
import { closeModal } from 'features/detailsModal/detailsModalSlice';

import styles from './MainInfo.module.scss';

interface MainInfoProps {
	details: AdDetails;
	adSpend: string;
}

const MainInfo: FC<MainInfoProps> = ({ details, adSpend }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const getPlatformLogo = (platformIdentifier: string) => {
		const logos = {
			tiktok: tiktok,
			meta: meta,
			instagram: instagram,
			linkedin: linkedin,
		};

		if (/tiktok/i.test(platformIdentifier)) return logos.tiktok;
		if (/meta/i.test(platformIdentifier)) return logos.meta;
		if (/instagram/i.test(platformIdentifier)) return logos.instagram;

		return meta;
	};

	const sharedTypographySx = {
		color: 'rgba(73, 80, 87, 1)',
		fontWeight: 600,
		lineHeight: '22.4px',
	};

	const handleNavigateWithBrand = (brandName: string) => {
		navigate('/swipe-file', { state: { brandName } });
		dispatch(closeModal());
	};

	const renderTextOrLink = (text: string) => {
		return (
			<Link
				component="button"
				onClick={() => handleNavigateWithBrand(text)}
				sx={{ textDecoration: 'none', cursor: 'pointer' }}
			>
				<Typography
					variant="body1"
					component="span"
					sx={{
						fontWeight: 700,
						color: 'rgba(0, 158, 194, 1)',
						lineHeight: '19.36px',
						borderBottom: '1px solid rgba(0, 158, 194, 1)',
					}}
				>
					{text}
				</Typography>
			</Link>
		);
	};

	const hasImpressions = !!details.ad_impressions;
	const hasSpendInfo = !!adSpend;

	return (
		<Box className={styles.detailsContainer}>
			{hasImpressions && (
				<InfoItem
					spent={adSpend}
					impressionsInfo
					primary={hasSpendInfo}
					icon={<LightModeOutlined color="info" />}
					title="Impressions"
				>
					<Typography
						sx={{ color: 'rgba(73, 80, 87, 1)' }}
						fontSize="32px"
						lineHeight="32px"
						fontWeight={500}
						variant="body1"
					>
						{details.ad_impressions}
					</Typography>
				</InfoItem>
			)}

			{hasSpendInfo && (
				<InfoItem
					spentInfo
					impressions={hasImpressions}
					primary={hasImpressions}
					icon={<AttachMoneyOutlined color="info" />}
					title="Ad spend"
				>
					<Typography
						sx={{ color: 'rgba(73, 80, 87, 1)' }}
						fontSize="32px"
						lineHeight="32px"
						fontWeight={500}
						variant="body1"
					>
						{adSpend}
					</Typography>
				</InfoItem>
			)}
			{details?.brand_name && (
				<InfoItem
					icon={<BuildingIcon sx={{ width: '20px', height: '20px' }} />}
					title="Brand"
				>
					{details.brand_name && renderTextOrLink(details.brand_name)}
				</InfoItem>
			)}
			{details.format && (
				<InfoItem icon={<FileIcon sx={{ width: '20px', height: '20px' }} />} title="Format">
					<Typography variant="body1" sx={sharedTypographySx}>
						{details.format}
					</Typography>
				</InfoItem>
			)}
			{details.platform && (
				<InfoItem
					icon={<DesktopIcon sx={{ width: '20px', height: '20px' }} />}
					title="Platform"
				>
					<img src={getPlatformLogo(details.platform)} alt={details.platform} />
					<Typography variant="body1" sx={sharedTypographySx}>
						{details.platform}
					</Typography>
				</InfoItem>
			)}
			{details.language && (
				<InfoItem
					icon={<LanguageIcon sx={{ width: '20px', height: '20px' }} />}
					title="Language"
				>
					<Typography variant="body1" sx={sharedTypographySx}>
						{details.language}
					</Typography>
				</InfoItem>
			)}
			{details.activityStart && (
				<InfoItem
					icon={<CalendarIcon sx={{ width: '20px', height: '20px' }} />}
					title="Activity"
				>
					<Typography variant="body1" sx={sharedTypographySx}>
						{dayjs(details.activityStart).format('MMM, DD YYYY')} -<br />
						{details.activityEnd
							? dayjs(details.activityEnd).format('MMM, DD YYYY')
							: 'Present'}
					</Typography>
				</InfoItem>
			)}
			<InfoItem
				icon={<ArrowCircleUpIcon sx={{ width: '20px', height: '20px' }} />}
				title="CTA button"
			>
				<Typography variant="body1" sx={sharedTypographySx}>
					{details.ctaButton ? details.ctaButton : 'None'}
				</Typography>
			</InfoItem>
			{details.total_likes && (
				<InfoItem icon={<ThumbUpOutlined color="info" />} title="Likes">
					<Typography variant="body1" sx={sharedTypographySx}>
						{details.total_likes}
					</Typography>
				</InfoItem>
			)}
			{details.comments && (
				<InfoItem icon={<CommentOutlined color="info" />} title="Comments">
					<Typography variant="body1" sx={sharedTypographySx}>
						{details.comments}
					</Typography>
				</InfoItem>
			)}
		</Box>
	);
};

export default MainInfo;
