import { SvgIcon, SvgIconProps } from '@mui/material';

export const DatabaseIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="13" height="14" viewBox="0 0 13 14" fill="none" {...props}>
			<path
				d="M11.75 2.91669C11.75 3.88319 9.39949 4.66669 6.5 4.66669C3.6005 4.66669 1.25 3.88319 1.25 2.91669M11.75 2.91669C11.75 1.95019 9.39949 1.16669 6.5 1.16669C3.6005 1.16669 1.25 1.95019 1.25 2.91669M11.75 2.91669V11.0834C11.75 12.0517 9.41667 12.8334 6.5 12.8334C3.58333 12.8334 1.25 12.0517 1.25 11.0834V2.91669M11.75 7.00002C11.75 7.96835 9.41667 8.75002 6.5 8.75002C3.58333 8.75002 1.25 7.96835 1.25 7.00002"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
};
