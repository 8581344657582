import { SvgIcon, SvgIconProps } from '@mui/material';

export const SaveIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.1667 17.2917H5.83341C5.22563 17.2917 4.64273 17.0502 4.21296 16.6204C3.78319 16.1907 3.54175 15.6078 3.54175 15V4.99999C3.54175 4.39221 3.78319 3.80931 4.21296 3.37954C4.64273 2.94977 5.22563 2.70833 5.83341 2.70833H12.0834C12.2491 2.70847 12.408 2.77442 12.5251 2.89166L16.2751 6.66666C16.3923 6.78376 16.4583 6.94262 16.4584 7.10833V15C16.4584 15.6078 16.217 16.1907 15.7872 16.6204C15.3574 17.0502 14.7745 17.2917 14.1667 17.2917ZM5.83341 3.95833C5.55715 3.95833 5.2922 4.06807 5.09685 4.26343C4.90149 4.45878 4.79175 4.72373 4.79175 4.99999V15C4.79175 15.2763 4.90149 15.5412 5.09685 15.7366C5.2922 15.9319 5.55715 16.0417 5.83341 16.0417H6.04175V11.25C6.04175 10.9737 6.15149 10.7088 6.34684 10.5134C6.5422 10.3181 6.80715 10.2083 7.08341 10.2083H12.9167C13.193 10.2083 13.458 10.3181 13.6533 10.5134C13.8487 10.7088 13.9584 10.9737 13.9584 11.25V16.0417H14.1667C14.443 16.0417 14.708 15.9319 14.9033 15.7366C15.0987 15.5412 15.2084 15.2763 15.2084 15V7.34166L11.8251 3.95833H11.4584V6.20833C11.4595 6.3495 11.4328 6.48951 11.3798 6.62035C11.3268 6.7512 11.2485 6.87032 11.1494 6.97092C11.0504 7.07152 10.9325 7.15162 10.8025 7.20665C10.6725 7.26169 10.5329 7.29057 10.3917 7.29166H7.1084C6.96722 7.29057 6.82765 7.26169 6.69764 7.20665C6.56763 7.15162 6.44974 7.07152 6.35069 6.97092C6.25164 6.87032 6.17337 6.7512 6.12036 6.62035C6.06735 6.48951 6.04063 6.3495 6.04173 6.20833V3.95833H5.83341ZM7.29173 3.95833H10.2084V6.04166H7.29173V3.95833ZM7.29175 11.4583V16.0417H12.7084V11.4583H7.29175Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
