import React, { useEffect, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { CheckIcon, HashtagIcon } from 'shared/components/Icons';
import useScrollLock from 'shared/hooks/useScrollLock';
import { Tag } from 'shared/models/tags';

import styles from '../AdsControls.module.scss';

interface TagsControlProps {
	tag: Tag;
	selectedTags: number[];
	onSelect: (tagId: number) => void;
}

const TagsControl: React.FC<TagsControlProps> = ({ tag, selectedTags, onSelect }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [openSubtags, setOpenSubtags] = React.useState<boolean>(true);
	const [currentSelectedTags, setCurrentSelectedTags] = useState<number[]>([]);

	useScrollLock(anchorEl);

	useEffect(() => {
		setCurrentSelectedTags(selectedTags);
	}, [selectedTags]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleToggleSubtags = (event: React.MouseEvent) => {
		event.stopPropagation();
		setOpenSubtags(!openSubtags);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = (tagId: number) => {
		onSelect(tagId);
	};

	return (
		<>
			<IconButton onClick={handleClick} className={styles.tagsDropDown}>
				<Box className={styles.tagsTitle}>
					<HashtagIcon
						sx={{ margin: '0 4px 0 2px;', width: '14px', height: '14px' }}
						className={styles.hashIcon}
					/>
					<Typography className={styles.tagHeader} variant="body1">
						Tag/s:
					</Typography>{' '}
					<Typography className={styles.tagValue} variant="body1">
						{tag.name}
					</Typography>
				</Box>
				<KeyboardArrowDown />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				disableScrollLock={true}
			>
				<MenuItem onClick={() => handleSelect(tag.id)} className={styles.tagItem}>
					<Box className={styles.tagInfo}>
						{tag.subTags &&
							tag.subTags.length > 0 &&
							(openSubtags ? (
								<ExpandLess onClick={handleToggleSubtags} />
							) : (
								<ExpandMore onClick={handleToggleSubtags} />
							))}
						<HashtagIcon
							sx={{ margin: '0 4px 0 2px;', width: '14px', height: '14px' }}
							className={styles.hashIcon}
						/>
						<ListItemText primary={tag.name} />
					</Box>
					<CheckIcon
						sx={{
							width: '14px',
							height: '14px',
							visibility: currentSelectedTags.includes(tag.id) ? 'visible' : 'hidden',
							marginRight: '8px',
						}}
					/>
				</MenuItem>
				{openSubtags && (
					<Box className={styles.subtags}>
						{tag.subTags?.map((subTag) => (
							<MenuItem
								key={subTag.id}
								onClick={() => handleSelect(subTag.id)}
								className={styles.subtagItem}
							>
								<Box className={styles.subtagInfo}>
									<HashtagIcon
										sx={{
											margin: '0 4px 0 2px;',
											width: '14px',
											height: '14px',
										}}
										className={styles.hashIcon}
									/>
									<ListItemText
										primary={
											<Typography className={styles.subTagValue}>
												{subTag.name}
											</Typography>
										}
									/>
								</Box>
								<CheckIcon
									sx={{
										width: '14px',
										height: '14px',
										visibility: currentSelectedTags.includes(subTag.id)
											? 'visible'
											: 'hidden',
										marginRight: '8px',
									}}
								/>
							</MenuItem>
						))}
					</Box>
				)}
			</Menu>
		</>
	);
};

export default TagsControl;
