import { SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';

import { CheckIcon } from 'shared/components/Icons';
import useScrollLock from 'shared/hooks/useScrollLock';

import { setSortBy, setSortTitle } from 'features/sort/sortSlice';

import styles from '../AdsControls.module.scss';

const sortOptionKeys = [
	'Newest',
	'Oldest',
	'AdSpend - Low to High',
	'AdSpend - High to Low',
] as const;
type SortOptionKey = (typeof sortOptionKeys)[number];

const sortOptionMapping: Record<SortOptionKey, string> = {
	'Newest': '{"campaign_start_date":-1}',
	'Oldest': '{"campaign_start_date":1}',
	'AdSpend - High to Low': '{"reach.eu_total_reach":-1}',
	'AdSpend - Low to High': '{"reach.eu_total_reach":1}',
};

const SortControl = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	useScrollLock(anchorEl);

	const dispatch = useDispatch();
	const sortTitle = useSelector((state: any) => state.sort.sortTitle);

	const handleSortChange = (event: SelectChangeEvent) => {
		const sortTitle = event.target.value as SortOptionKey;
		const sortValue = sortOptionMapping[sortTitle];
		dispatch(setSortBy(sortValue));
		dispatch(setSortTitle(sortTitle));
	};

	const handleMenuOpen = (event: SyntheticEvent<Element, Event>) => {
		setAnchorEl(event.currentTarget as HTMLElement);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	return (
		<FormControl
			variant="outlined"
			size="small"
			className={`${styles.formControl} ${styles.sortControl}`}
		>
			<InputLabel id="sort-by-label" className={styles.sortLabel}>
				Sort by:
			</InputLabel>
			<Select
				labelId="sort-by-label"
				value={sortTitle}
				MenuProps={{
					disableScrollLock: true,
				}}
				onChange={handleSortChange}
				onOpen={handleMenuOpen}
				onClose={handleMenuClose}
				renderValue={(selected) => <Box className={styles.sortValue}>{`${selected}`}</Box>}
			>
				{Object.keys(sortOptionMapping).map((optionTitle) => (
					<MenuItem key={optionTitle} value={optionTitle}>
						<Box
							display="flex"
							justifyContent="space-between"
							width="100%"
							alignItems="center"
						>
							<Typography
								style={{
									fontWeight: sortTitle === optionTitle ? 'bold' : 'regular',
								}}
							>
								{optionTitle}
							</Typography>
							{sortTitle === optionTitle && (
								<CheckIcon
									sx={{
										width: '14px',
										height: '14px',
									}}
								/>
							)}
						</Box>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SortControl;
