import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, LinearProgress, Typography } from '@mui/material';

import logo from 'assets/images/logo.svg';
import { useUser } from 'core/UserProvider/useUser';
import { UserDTO } from 'core/api/models/user';
import { tokenService } from 'core/token/services';
import { jwtDecode } from 'jwt-decode';

import styles from './GoogleCallback.module.scss';

const GoogleCallback: React.FC = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [errorMsg, setErrorMsg] = useState<string | null>(null);
	const {
		actions: { setLoggedInUser },
	} = useUser();

	const urlParams = new URLSearchParams(window.location.search);
	const accessToken = urlParams.get('access_token');
	const refreshToken = urlParams.get('refresh_token');
	const error = urlParams.get('error');

	useEffect(() => {
		if (error) {
			setLoading(false);
			setErrorMsg('Authentication failed. Please try again.');
			setTimeout(() => {
				setLoading(false);
				setErrorMsg(null);
				navigate('/login');
			}, 1200);
			return;
		}

		if (accessToken) {
			try {
				const decodedToken = jwtDecode<UserDTO>(accessToken);
				const userinfo = decodedToken;
				tokenService.token = userinfo.access_token;
				tokenService.refreshToken = refreshToken;
				setTimeout(() => {
					setLoading(false);
					setLoggedInUser(userinfo);
					navigate('/');
				}, 1500);
			} catch (error) {
				setLoading(false);
				setErrorMsg('Authentication failed. Please try again.');
				setTimeout(() => {
					setLoading(false);
					setErrorMsg(null);
					navigate('/login');
				}, 1200);
			}
		} else {
			setLoading(false);
			setErrorMsg('Authentication failed. Please try again.');
			setTimeout(() => {
				setLoading(false);
				setErrorMsg(null);
				navigate('/login');
			}, 1200);
			navigate('/error');
		}
	}, [accessToken, error, loading, navigate, refreshToken, setLoggedInUser]);

	return (
		<Box className={styles.callback_container}>
			<LinearProgress
				sx={{
					'height': 8,
					'backgroundColor': 'lightgrey',
					'& .MuiLinearProgress-bar': { backgroundColor: 'var(--link-active-color)' },
					'width': '100%',
					'position': 'absolute',
					'top': 0,
					'left': 0,
				}}
			/>
			<Box className={styles.content}>
				<img className={styles.logo} src={logo} alt="AdUniverse" />
				{loading ? (
					<Typography variant="subtitle2" className={styles.loader}>
						Processing Google authentication...
					</Typography>
				) : errorMsg ? (
					<Typography variant="subtitle2" style={{ color: 'red', marginTop: '20px' }}>
						{errorMsg}
					</Typography>
				) : (
					<Typography variant="subtitle2" className={styles.loader}>
						Redirecting...
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default GoogleCallback;
