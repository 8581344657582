import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Typography,
} from '@mui/material';

import { FormInput } from 'shared/components/FormInput';
import { GoogleLoginButton } from 'shared/components/GoogleLoginButton';
import { EyeIcon, EyeSlashIcon } from 'shared/components/Icons';
import { RoutesEnum } from 'shared/enums';

import { Layout } from '../common/Layout';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePostLogin } from 'app/pages/auth/hooks';
import { useUser } from 'core/UserProvider/useUser';
import * as yup from 'yup';

import styles from './Login.module.scss';

interface LoginFormValues {
	email: string;
	password: string;
}

const validationSchema = yup.object({
	email: yup.string().required('Email is required').email('Please enter a valid email address'),
	password: yup
		.string()
		.trim()
		.required('Password is required')
		.test('is-not-empty', 'This field is required', (value) => value !== ''),
});

const Login: FC = () => {
	const { user } = useUser();
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = React.useState(false);
	const [rememberMe, setRememberMe] = useState(false);

	const methods = useForm<LoginFormValues>({
		defaultValues: {
			email: '',
			password: '',
		},
		resolver: yupResolver(validationSchema),
		reValidateMode: 'onChange',
	});
	const { errors, isValid, isSubmitting } = methods.formState;

	const { mutateAsync, isPending } = usePostLogin();

	const onSubmit = async (data: LoginFormValues) => {
		try {
			await mutateAsync({ email: data.email, password: data.password });
		} catch {
			methods.setError('password', {
				type: 'manual',
				message: 'Incorrect email or password. Please try again.',
			});
		}
	};

	// Redirect to Home if the user is logged in
	useEffect(() => {
		if (user) {
			navigate(RoutesEnum.HOME);
		}
	}, [user, navigate]);
	
	// Stop unnecessary render if the user is logged in
	if (user) {
		return null;
	}

	return (
		<Box className={styles.containerLogin}>
			<Layout headerTitle="Welcome back!">
				<Typography className={styles.signupTextLine}>
					Don't have an account yet?{' '}
					<Link to="/signup" className={styles.linkSpan}>
						Sign up now
					</Link>
				</Typography>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Box className={styles.formFieldsWrapper}>
							<Box className={styles.emailInput}>
								<FormInput
									id="email"
									placeholder="Email"
									name="email"
									aria-label="Email"
									hiddenLabel={true}
									variant="outlined"
								/>
							</Box>
							<Box className={styles.passwordInput}>
								<FormInput
									id="password"
									name="password"
									hiddenLabel={true}
									placeholder="Password"
									variant="outlined"
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-error': {
												'borderColor':
													errors.password &&
													errors.password.type === 'manual'
														? '#ced4da'
														: '',
												'&:hover fieldset': {
													borderColor:
														errors.password &&
														errors.password.type === 'manual'
															? '#ced4da'
															: '',
												},
												'& fieldset': {
													borderColor:
														errors.password &&
														errors.password.type === 'manual'
															? '#ced4da'
															: 'red',
												},
											},
										},
									}}
									type={showPassword ? 'text' : 'password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setShowPassword(!showPassword)}
													edge="end"
												>
													{showPassword ? (
														<EyeSlashIcon
															sx={{ width: '14px', height: '14px' }}
														/>
													) : (
														<EyeIcon
															sx={{ width: '14px', height: '14px' }}
														/>
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Box>
							<Box className={styles.rememberCheckbox}>
								<FormControlLabel
									control={
										<Checkbox
											sx={{ padding: '0 9px' }}
											checked={rememberMe}
											onChange={(e) => setRememberMe(e.target.checked)}
										/>
									}
									className={styles.label}
									label="Remember me"
								/>
								<Link to="/reset_password" className={styles.linkSpan}>
									Forgot password?
								</Link>
							</Box>
						</Box>
						<Button
							variant="contained"
							sx={{ height: '38px' }}
							fullWidth
							disabled={!isValid || isSubmitting || isPending}
							type="submit"
						>
							Login
						</Button>
						<Divider className={styles.divider}>
							<div className={styles.text}>or</div>
						</Divider>
						<GoogleLoginButton label="Login with Google" />
					</form>
				</FormProvider>
			</Layout>
		</Box>
	);
};

export default Login;
