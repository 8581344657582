import { FC, useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import CloseOutlined from '@mui/icons-material/CloseOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Avatar, Box, Button, Checkbox, Chip, FormControlLabel, Typography } from '@mui/material';

import { PencilIcon, SaveIcon, TrashIcon } from 'shared/components/Icons';
import { DeleteDialog } from 'shared/components/TagManagement/DeleteDialog';
import { AdNote } from 'shared/models/ads';

import './editorStyle.css';
import useDeleteNote from 'app/pages/adDetails/hooks/useDeleteNote';
import usePatchNote from 'app/pages/adDetails/hooks/usePatchNote';
import usePostNote from 'app/pages/adDetails/hooks/usePostNote';
import noNotes from 'assets/images/no-results.png';
import { useUser } from 'core/UserProvider/useUser';
import { ToastContext } from 'core/toast/ToastProvider';
import Quill from 'quill';

import styles from './Notes.module.scss';

const Link = Quill.import('formats/link');

class CustomLink extends Link {
	static create(value: string) {
		const node = super.create(value);
		value = this.sanitize(value);
		node.setAttribute('href', value);
		node.setAttribute('target', '_blank');
		node.setAttribute('rel', 'noopener noreferrer');
		return node;
	}

	static sanitize(url: string) {
		if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
			return `http://${url}`;
		}
		return url;
	}
}

Quill.register(CustomLink, true);

interface NotesProps {
	notes: AdNote[] | undefined;
	adId: string | undefined;
}

const Notes: FC<NotesProps> = ({ notes, adId }) => {
	const { user } = useUser();
	const userId = user?.id;
	const toast = useContext(ToastContext);
	const postNoteMutation = usePostNote(adId, userId);
	const patchNoteMutation = usePatchNote(adId, userId);
	const deleteNoteMutation = useDeleteNote(adId, userId);

	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [newNoteContent, setNewNoteContent] = useState('');
	const [currentEditContent, setCurrentEditContent] = useState('');
	const [isPublic, setIsPublic] = useState(false);
	const [notesArray, setNotesArray] = useState<any[]>(notes || []);
	const [editingId, setEditingId] = useState<string | undefined>();
	const [isEditPublic, setIsEditPublic] = useState(false);
	const [expandedNotes, setExpandedNotes] = useState<Record<string, boolean>>({});
	const [selectedNoteId, setSelectedNoteId] = useState<string>();

	useEffect(() => {
		if (notes) {
			setNotesArray(
				[...notes].sort((a, b) => {
					const dateA = new Date(a.created_at).getTime();
					const dateB = new Date(b.created_at).getTime();
					return dateB - dateA;
				})
			);
		}
	}, [notes]);

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return new Intl.DateTimeFormat('en-US', {
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		}).format(date);
	};

	const toggleExpanded = (noteId: string) => {
		setExpandedNotes((prevExpandedNotes) => ({
			...prevExpandedNotes,
			[noteId]: !prevExpandedNotes[noteId],
		}));
	};

	const handleSave = () => {
		if (newNoteContent.trim() !== '' && typeof userId === 'number' && adId) {
			postNoteMutation.mutate(
				{
					userId: userId,
					content: newNoteContent,
					adId: adId,
					is_private: !isPublic,
				},
				{
					onSuccess: (data: any) => {
						const newNote = {
							text: data.content,
							created_at: data.created_at,
							id: data.id,
							is_private: data.is_private,
							owner_avatar_url: user?.avatar_url,
							owner_full_name: `${user?.first_name} ${user?.last_name}`,
							owner_id: userId,
						};

						setNotesArray((prevNotes) => [newNote, ...prevNotes]);
						setNewNoteContent('');
						setIsPublic(false);
					},
				}
			);
		}
	};

	const handleEdit = (noteId: string) => {
		const noteToEdit = notesArray.find((note) => note.id === noteId);
		if (noteToEdit) {
			setCurrentEditContent(noteToEdit.text);
			setIsEditPublic(!noteToEdit.is_private);
			setEditingId(noteId);
		}
	};

	const handleSaveEdit = () => {
		if (currentEditContent.trim() !== '' && typeof userId === 'number' && editingId) {
			patchNoteMutation.mutate(
				{
					userId: userId,
					content: currentEditContent,
					noteId: editingId,
					is_private: !isEditPublic,
				},
				{
					onSuccess: (data) => {
						setNotesArray((prevNotes) =>
							prevNotes.map((note) =>
								note.id === editingId
									? { ...note, text: data.content, is_private: !isEditPublic }
									: note
							)
						);
						setEditingId(undefined);
						setCurrentEditContent('');
						setIsEditPublic(false);
					},
				}
			);
		}
	};

	const handleCancelEdit = () => {
		setEditingId(undefined);
		setCurrentEditContent('');
	};

	const handleDeleteClick = (noteId: string) => {
		if (noteId) {
			setSelectedNoteId(noteId);
			setDeleteDialogOpen(true);
		} else {
			toast.open('Error', 'Error deleting note', { severity: 'error' });
		}
	};

	const handleConfirmDelete = () => {
		if (selectedNoteId) {
			deleteNoteMutation.mutate(selectedNoteId, {
				onSuccess: () => {
					setNotesArray((currentNotes) =>
						currentNotes.filter((note) => note.id !== selectedNoteId)
					);
				},
				onSettled: () => {
					setDeleteDialogOpen(false);
				},
			});
		}
	};

	const isNoteEmpty = (htmlString: string) => {
		const textContent = htmlString.replace(/<[^>]*>/g, '').trim();
		return textContent === '';
	};

	const getChipColor = (isPublic: boolean) => {
		return isPublic ? '#22C55E' : '#3B82F6';
	};

	return (
		<Box className={styles.notesWrapper}>
			<Box className={styles.notesContainer}>
				{user && (
					<Box>
						<Box className={styles.notesHeader}>
							<PencilIcon sx={{ width: '24px', height: '24px' }} />
							<Typography variant="subtitle2">Add note</Typography>
						</Box>
						<ReactQuill
							theme="snow"
							value={newNoteContent}
							onChange={setNewNoteContent}
							className="editor"
							placeholder="Write note here..."
						/>
						<Box className={styles.actionsContainer}>
							<Button
								variant="contained"
								onClick={handleSave}
								disabled={isNoteEmpty(newNoteContent)}
							>
								Save note
							</Button>
							<FormControlLabel
								control={
									<Checkbox
										checked={isPublic}
										onChange={(e) => setIsPublic(e.target.checked)}
									/>
								}
								label="Make public"
							/>
						</Box>
					</Box>
				)}
				<Box className={styles.notesListWrapper}>
					<Typography variant="subtitle2">All notes</Typography>
					<Box className={styles.notesListContainer}>
						{notesArray.length > 0 ? (
							notesArray.map((noteItem) => (
								<Box
									key={noteItem.id}
									className={styles.noteItem}
									sx={{
										backgroundColor: noteItem.is_private
											? '#F5F9FF'
											: '#F4FCF7',
									}}
								>
									<Box className={styles.noteContent}>
										<Box
											className={`${styles.noteHeader} ${
												noteItem.id === editingId &&
												currentEditContent &&
												styles.wider
											}`}
										>
											<Box className={styles.noteInfo}>
												<Avatar
													src={noteItem.owner_avatar_url}
													sx={{
														width: '28px',
														height: '28px',
														borderRadius: '6px',
														objectFit: 'cover',
													}}
												/>
												<Typography>{noteItem.owner_full_name}</Typography>
												<Chip
													label={
														noteItem.is_private ? 'private' : 'public'
													}
													size="small"
													style={{
														backgroundColor: getChipColor(
															!noteItem.is_private
														),
														color: '#fff',
														borderRadius: '6px',
													}}
												/>
											</Box>
											<Typography>
												{formatDate(noteItem.created_at)}
											</Typography>
										</Box>
										<Box
											className={`${styles.note} ${
												expandedNotes[noteItem.id] || currentEditContent
													? styles.expanded
													: ''
											} ${currentEditContent && styles.wider}`}
											ref={(el: HTMLDivElement) => {
												if (el) {
													const isContentTooLong =
														el.scrollHeight > 173 &&
														!currentEditContent;

													if (
														isContentTooLong &&
														!Object.prototype.hasOwnProperty.call(
															expandedNotes,
															noteItem.id
														)
													) {
														setExpandedNotes((prev) => ({
															...prev,
															[noteItem.id]: false,
														}));
													}
												}
											}}
										>
											{editingId === noteItem.id && (
												<ReactQuill
													theme="snow"
													value={currentEditContent}
													onChange={setCurrentEditContent}
													className="editor"
												/>
											)}
											{editingId !== noteItem.id && (
												<ReactQuill
													value={noteItem.text}
													readOnly={true}
													theme="bubble"
													className="read-only"
												/>
											)}
										</Box>
										{Object.prototype.hasOwnProperty.call(
											expandedNotes,
											noteItem.id
										) &&
											!currentEditContent && (
												<Box className={styles.showMore}>
													<Button
														onClick={() => toggleExpanded(noteItem.id)}
													>
														{expandedNotes[noteItem.id]
															? 'Show Less'
															: 'Show More'}
													</Button>
													{expandedNotes[noteItem.id] ? (
														<KeyboardArrowUp />
													) : (
														<KeyboardArrowDown />
													)}
												</Box>
											)}
										{editingId === noteItem.id && (
											<Box className={styles.editActions}>
												<Box className={styles.editButtons}>
													<Button
														variant="contained"
														onClick={handleSaveEdit}
														endIcon={
															<SaveIcon
																sx={{
																	width: '16px',
																	height: '16px',
																}}
															/>
														}
													>
														Save changes
													</Button>
													<Button
														variant="outlined"
														onClick={handleCancelEdit}
														endIcon={<CloseOutlined />}
													>
														Cancel edit
													</Button>
												</Box>
												<FormControlLabel
													control={
														<Checkbox
															checked={isEditPublic}
															onChange={(e) =>
																setIsEditPublic(e.target.checked)
															}
														/>
													}
													label="Make public"
												/>
											</Box>
										)}
									</Box>
									{user &&
										editingId !== noteItem.id &&
										user.id === noteItem.owner_id && (
											<Box className={styles.noteActions}>
												<Button
													className={styles.actionButton}
													variant="outlined"
													color="primary"
													onClick={() => handleEdit(noteItem.id)}
												>
													<PencilIcon
														sx={{ width: '16px', height: '16px' }}
													/>
												</Button>

												<Button
													className={styles.actionButton}
													variant="outlined"
													color="error"
													onClick={() => handleDeleteClick(noteItem.id)}
												>
													<TrashIcon
														sx={{ width: '16px', height: '16px' }}
													/>
												</Button>
											</Box>
										)}
									{noteItem.id && (
										<DeleteDialog
											dialogType="note"
											open={deleteDialogOpen}
											onClose={() => setDeleteDialogOpen(false)}
											onConfirm={handleConfirmDelete}
											itemName="Note"
										/>
									)}
								</Box>
							))
						) : (
							<Box className={styles.noNotes} p={2}>
								<img src={noNotes} alt="no notes" className={styles.noNotesImg} />
								<Typography variant="subtitle2">No notes yet</Typography>
								<Typography variant="body2">
									Once new notes are added, they will be displayed here.
								</Typography>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Notes;
