import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CheckOutlined, Error } from '@mui/icons-material';
import { Box, Button, Chip, Typography } from '@mui/material';

import { useActiveSubscriptionInfo } from 'shared/api/hooks/billing/useActiveSubscriptionInfo';
import { useGetDashboard } from 'shared/api/hooks/billing/useGetDashboard';
import { ModalContainer } from 'shared/components/ModalContainer/ModalContainer';
import { RoutesEnum } from 'shared/enums';
import { useGetUser } from 'shared/hooks/useGetUser';

import logo from 'assets/images/logo-gradient.svg';

import styles from './PaymentStatus.module.scss';

const PaymentStatus = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { data: user } = useGetUser();
	const { data: dashboardData } = useGetDashboard(user?.id);
	const { activeSubscription, activeAddon } = useActiveSubscriptionInfo(user?.id);
	const queryParams = new URLSearchParams(location.search);
	const errorMessage: string | null = location.state?.errorMessage;
	const status = queryParams.get('redirect_status');
	const payment_intent = queryParams.get('payment_intent');
	const [isAddonModalOpen, setIsAddonModalOpen] = useState(false);

	useEffect(() => {
		if (
			(status === 'succeeded' || status === 'updated') &&
			activeSubscription &&
			!activeAddon
		) {
			const timer = setTimeout(() => {
				setIsAddonModalOpen(true);
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [status, activeSubscription, activeAddon]);

	const handleManagePlans = () => {
		if (dashboardData?.session_url) {
			window.history.pushState({}, '', RoutesEnum.HOME);
			window.location.href = dashboardData?.session_url;
		}
	};

	const handleAddonModalClose = () => {
		setIsAddonModalOpen(false);
	};

	const handleAddonPurchase = () => {
		setIsAddonModalOpen(false);
		navigate('/checkout');
	};

	return (
		<Box className={styles.paymentStatusContainer}>
			<img src={logo} alt="Ad Universe" width={72} />
			{status === 'succeeded' ? (
				<Box className={styles.successContainer}>
					<Typography variant="h3">Payment Successful!</Typography>
					<CheckOutlined className={styles.icon} />
					<Typography variant="subtitle2">Transaction ID: {payment_intent}</Typography>
					<Typography sx={{ marginTop: '16px' }} variant="body1">
						You can now continue exploring ads.
					</Typography>
				</Box>
			) : status === 'updated' ? (
				<Box className={styles.successContainer}>
					<Typography variant="h3">Subscription Updated Successfully!</Typography>
					<CheckOutlined className={styles.icon} />
					<Typography sx={{ marginTop: '16px' }} variant="body1">
						Your subscription has been updated successfully.
					</Typography>
				</Box>
			) : status === 'canceled' ? (
				<Box className={styles.successContainer}>
					<Typography variant="h3">Payment canceled!</Typography>
					<CheckOutlined className={styles.icon} />
					<Typography sx={{ marginTop: '16px' }} variant="body1">
						Your payment has been canceled.
					</Typography>
				</Box>
			) : (
				<Box className={styles.errorContainer}>
					{errorMessage && <Typography variant="h5">{errorMessage}</Typography>}
					<Typography variant="h5">Payment Failed!</Typography>
					<Error className={styles.icon} color="error" />
					<Typography variant="subtitle2">Transaction ID: {payment_intent}</Typography>
					<Typography variant="body2">Please try again or contact support.</Typography>
					<Button
						variant="contained"
						onClick={handleManagePlans}
						color="primary"
						disabled={!dashboardData?.session_url}
						sx={{ width: '300px', marginTop: '24px' }}
					>
						Manage plans
					</Button>
				</Box>
			)}
			<ModalContainer isOpen={isAddonModalOpen} title="">
				<Box className={styles.popupContent}>
					<Chip className={styles.popupChip} label="Limited 1-Time Offer" />
					<Typography variant="h5">Upgrade Your Account Now And Save</Typography>
					<Typography variant="subtitle1" className={styles.promoText}>
						{`Add 20 Trackers to your account and get 25% off this plan! (just an example)`}
					</Typography>
					<Box className={styles.planCard__item}>
						<Box className={styles.planCard__info}>
							<Typography variant="subtitle2" className={styles.planCard__title}>
								Small Add-on
							</Typography>
							<Typography variant="body2" className={styles.planCard__count}>
								20 Brands
							</Typography>
						</Box>
						<Box className={styles.planCard__price}>150$</Box>
					</Box>
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddonPurchase}
						sx={{ mt: 2, width: '100%' }}
					>
						Purchase Addon
					</Button>
					<Button
						variant="outlined"
						color="primary"
						onClick={handleAddonModalClose}
						sx={{ mt: 2, width: '100%' }}
					>
						No, thanks
					</Button>
				</Box>
			</ModalContainer>
		</Box>
	);
};

export default PaymentStatus;
