import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Avatar, Box, Button, Typography } from '@mui/material';

import { useActiveSubscriptionInfo } from 'shared/api/hooks/billing/useActiveSubscriptionInfo';
import { usePlansAndAddOns } from 'shared/api/hooks/billing/usePlansAndAddons';
import { useSubscribe } from 'shared/api/hooks/billing/useSubscribe';
import Header from 'shared/components/CheckoutComponents/CheckoutHeader/Header';
import BillingToggle from 'shared/components/CheckoutComponents/CheckoutIntervalToggle';
import {
	AddonSelectionV2,
	PlanSelectionV2,
} from 'shared/components/CheckoutComponents/SubscriptionSelection';
import { Spinner } from 'shared/components/Spinner';
import { RoutesEnum } from 'shared/enums';
import { getCookie, getPriceInEuros, getPriceIntervalId } from 'shared/helpers/billingHelpers';
import { useGetUser } from 'shared/hooks/useGetUser';
import { Product, StripeMetadataEnum } from 'shared/models/billing';

import quoteImage from 'assets/images/quote-image.jpeg';
import { ToastContext } from 'core/toast/ToastProvider';

import styles from './SubscriptionConfirmation.module.scss';

const SubscriptionConfirmation = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const toast = useContext(ToastContext);
	const [billingInterval, setBillingInterval] = useState<'month' | 'year'>('month');
	const { plans, addons, isLoading } = usePlansAndAddOns(billingInterval);
	const fpromTid = getCookie('_fprom_tid');
	const { data: user } = useGetUser();
	const {
		activeSubscription,
		activeAddon,
		isSubscriptionTrialing,
		isSubscriptionActive,
		isLoading: activeSubscriptionLoading,
	} = useActiveSubscriptionInfo(user?.id);

	const initialPlan: Product | undefined = location.state?.plan;
	const initialAddon: Product | undefined = location.state?.addon;
	const showBillingPlans: 'show' | undefined = location.state?.showBillingPlans;

	const [selectedPlan, setSelectedPlan] = useState<Product | null>(null);
	const [selectedAddon, setSelectedAddon] = useState<Product | null>(null);

	const { mutateAsync: subscribe } = useSubscribe();

	useEffect(() => {
		if (!isLoading && plans && addons && !activeSubscriptionLoading) {
			if (selectedPlan) {
				return;
			}
			if (initialAddon) {
				setSelectedAddon(initialAddon);
			} else if (activeSubscription) {
				const subscriptionAddon = addons.find((p) => p.id === activeAddon?.plan.product);
				const defaultAddon = addons.find(
					(p) => p.metadata.vs_id === StripeMetadataEnum.SmallAddon
				);
				setSelectedAddon(subscriptionAddon || defaultAddon || null);
			}
			if (initialPlan) {
				setSelectedPlan(initialPlan);
			} else if (activeSubscription) {
				const activePlan = plans.find((p) => p.id === activeSubscription.plan.product);
				const defaultPlan = plans.find(
					(p) => p.metadata.vs_id === StripeMetadataEnum.BasicPlan
				);
				setSelectedPlan(activePlan || defaultPlan || null);
			}
		}
	}, [
		isLoading,
		addons,
		plans,
		initialPlan,
		activeSubscription,
		activeAddon,
		initialAddon,
		activeSubscriptionLoading,
		selectedPlan,
	]);

	if (isLoading) {
		return <Spinner />;
	}
	if (!selectedPlan) {
		return <Typography>Loading plans...</Typography>;
	}

	const handleIntervalClick = (label: 'month' | 'year') => {
		setBillingInterval(label);
	};

	const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const plan = plans?.find((p) => p.name === event.target.value);
		if (plan) {
			setSelectedPlan(plan);
		}
	};

	const handleAddonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const addonName = event.target.value;
		if (addonName === '') {
			setSelectedAddon(null);
			return;
		}
		const addon = addons?.find((a) => a.name === addonName) || null;

		if (selectedAddon?.name === addon?.name) {
			setSelectedAddon(null);
		} else {
			setSelectedAddon(addon);
		}
	};

	const formatPlanLabel = (plan: Product) => (
		<span>
			{plan.name} -{' '}
			<Typography component="span" fontWeight="normal" sx={{ color: '#8c8c8c' }}>
				{plan.description}
			</Typography>
		</span>
	);

	const handleContinueToCheckout = async () => {
		try {
			const planPriceId = getPriceIntervalId(selectedPlan.prices, billingInterval);
			const addonPriceId = selectedAddon
				? getPriceIntervalId(selectedAddon?.prices, billingInterval)
				: undefined;
			if (!user || !planPriceId) {
				throw new Error('An unexpected error occurred.');
			} else {
				subscribe({
					user_id: user.id,
					planPriceId,
					...(addonPriceId && { addonPriceId }),
					...(fpromTid && { fp_tid: fpromTid }),
				});
			}
		} catch (error) {
			toast.open('Error', 'An unexpected error occurred.', {
				severity: 'error',
			});
		}
	};

	return (
		<Box className={styles.checkoutContainer}>
			<Box className={styles.containerLeft__Wrapper}>
				<Header isActiveSubscription={isSubscriptionActive} />
				<Box className={styles.containerLeft}>
					<Box display="flex" justifyContent="space-between" width={1}>
						<BillingToggle
							activeInterval={billingInterval}
							handleIntervalClick={handleIntervalClick}
						/>
					</Box>
					<PlanSelectionV2
						disabled={showBillingPlans === 'show'}
						billingInterval={billingInterval}
						subscriptions={plans}
						selectedPlan={selectedPlan}
						handlePlanChange={handlePlanChange}
						formatPlanLabel={formatPlanLabel}
						activeSubscriptionId={
							isSubscriptionTrialing ? undefined : activeSubscription?.plan.product
						}
					/>
					<AddonSelectionV2
						addons={addons}
						billingInterval={billingInterval}
						selectedAddon={selectedAddon}
						handleAddonChange={handleAddonChange}
						formatPlanLabel={formatPlanLabel}
						disabled={!selectedPlan}
						activeSubscriptionAddonId={activeAddon?.plan.product}
					/>
				</Box>
			</Box>
			<Box className={styles.divider} />
			<Box className={styles.containerRight}>
				<Box className={styles.step}>
					<Box className={styles.selectedItems}>
						<Box className={styles.item}>
							<Box className={styles.itemText}>
								<Typography className={styles.itemLabel} variant="h5">
									Plan:
								</Typography>
								<Typography className={styles.itemValue} variant="h5">
									{selectedPlan.name}
								</Typography>
							</Box>
							<Typography className={styles.itemValue} variant="h5">
								€{getPriceInEuros(selectedPlan.prices, billingInterval)}/mo
							</Typography>
						</Box>
						{selectedAddon && (
							<Box className={styles.item}>
								<Box className={styles.itemText}>
									<Typography className={styles.itemLabel} variant="h5">
										Add-on:
									</Typography>
									<Typography className={styles.itemValue} variant="h5">
										{selectedAddon.name}
									</Typography>
								</Box>
								<Typography className={styles.itemValue} variant="h5">
									€{getPriceInEuros(selectedAddon.prices, billingInterval)}/mo
								</Typography>
							</Box>
						)}
					</Box>
					<Button
						type="button"
						fullWidth
						variant="contained"
						onClick={handleContinueToCheckout}
						sx={{ mb: '16px' }}
					>
						Continue to Checkout
					</Button>

					<Button
						type="button"
						fullWidth
						variant="outlined"
						onClick={() => navigate(RoutesEnum.HOME)}
						sx={{ mb: '16px' }}
					>
						Go Back
					</Button>

					<Box className={styles.quoteContainer}>
						<Box className={styles.avatarWrapper}>
							<Avatar src={quoteImage} className={styles.customAvatar} alt="Avatar" />
						</Box>
						<Box display="flex" flexDirection="column" gap="8px">
							<Typography variant="h6" className={styles.quoteText}>
								"In 11 years, we've achieved 3,640 successful A/B tests and spent
								over 62 million, all thanks to AdUniverse's insights into ad spend
								and successful creatives!"
							</Typography>
							<Typography variant="h5" sx={{ ml: '16px', fontSize: '16px' }}>
								Chris Erthel
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default SubscriptionConfirmation;
