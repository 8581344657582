import { SvgIcon, SvgIconProps } from '@mui/material';

export const TimesIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="15" height="14" viewBox="0 0 15 14" fill="none" {...props}>
			<path
				d="M8.51186 7.00933L12.77 2.75116C12.841 2.68501 12.898 2.60524 12.9375 2.51661C12.9769 2.42798 12.9982 2.3323 12.9999 2.23529C13.0016 2.13827 12.9838 2.0419 12.9474 1.95194C12.9111 1.86197 12.857 1.78024 12.7884 1.71163C12.7198 1.64302 12.638 1.58893 12.5481 1.55259C12.4581 1.51625 12.3617 1.4984 12.2647 1.50011C12.1677 1.50182 12.072 1.52306 11.9834 1.56255C11.8948 1.60204 11.815 1.65898 11.7488 1.72997L7.49067 5.98814L3.2325 1.72997C3.09553 1.60234 2.91437 1.53286 2.72718 1.53616C2.53999 1.53946 2.3614 1.61529 2.22901 1.74767C2.09663 1.88006 2.0208 2.05865 2.0175 2.24584C2.0142 2.43303 2.08368 2.61419 2.21131 2.75116L6.46948 7.00933L2.21131 11.2675C2.076 11.403 2 11.5866 2 11.7781C2 11.9696 2.076 12.1532 2.21131 12.2887C2.34679 12.424 2.53043 12.5 2.7219 12.5C2.91338 12.5 3.09702 12.424 3.2325 12.2887L7.49067 8.03052L11.7488 12.2887C11.8843 12.424 12.068 12.5 12.2594 12.5C12.4509 12.5 12.6346 12.424 12.77 12.2887C12.9053 12.1532 12.9813 11.9696 12.9813 11.7781C12.9813 11.5866 12.9053 11.403 12.77 11.2675L8.51186 7.00933Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
