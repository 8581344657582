import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { authApiService } from 'app/pages/auth/service';
import { EmailVerify } from 'core/api/models/user';
import { ToastContext } from 'core/toast/ToastProvider';

export const usePostEmailVerify = (): UseMutationResult<unknown, unknown, EmailVerify, unknown> => {
	const navigate = useNavigate();

	const toast = useContext(ToastContext);

	return useMutation({
		mutationFn: (verificationData) => authApiService.postEmailVerify(verificationData),
		onSuccess: () => {
			toast.open('Success', 'Email Verification successful', {
				severity: 'success',
			});
			navigate('/login');
		},
		onError: () => {
			toast.open('Error', 'Email Verification error', {
				severity: 'error',
			});
		},
	});
};

export default usePostEmailVerify;
