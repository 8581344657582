import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, CircularProgress, Typography } from '@mui/material';

import { usePostEmailVerify } from 'app/pages/auth/hooks';

const ConfirmEmail = () => {
	const navigate = useNavigate();
	const { search } = useLocation();
	const { mutateAsync: handleEmailVerify, isPending } = usePostEmailVerify();

	useEffect(() => {
		const queryParams = new URLSearchParams(search);
		const verifyToken = queryParams.get('verify_token');
		const uid = queryParams.get('uid');

		if (verifyToken && uid) {
			handleEmailVerify({ uid, verify_token: verifyToken })
				.then(() => {
					navigate('/login');
				})
				.catch((error) => {
					console.error('Error during email verification:', error);
				});
		} else {
			navigate('/signup');
		}
	}, [navigate, search, handleEmailVerify]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
			}}
		>
			{isPending ? (
				<>
					<CircularProgress />
					<Typography variant="body2">Verifying your email...</Typography>
				</>
			) : (
				<Typography variant="body1">
					If your email has not been automatically verified, please check your inbox for a
					verification email or contact support.
				</Typography>
			)}
		</Box>
	);
};

export default ConfirmEmail;
