import { SvgIcon, SvgIconProps } from '@mui/material';

export const ThumbsUpIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M5.83341 18.3333V9.16667M1.66675 10.8333V16.6667C1.66675 17.5871 2.41294 18.3333 3.33341 18.3333H14.522C15.7559 18.3333 16.8053 17.4331 16.9929 16.2135L17.8903 10.3801C18.1233 8.86575 16.9516 7.5 15.4194 7.5H12.5001C12.0398 7.5 11.6667 7.12691 11.6667 6.66667V3.72153C11.6667 2.58666 10.7468 1.66667 9.61188 1.66667C9.3412 1.66667 9.0959 1.82608 8.98596 2.07344L6.05336 8.67178C5.91961 8.97272 5.62118 9.16667 5.29185 9.16667H3.33341C2.41294 9.16667 1.66675 9.91286 1.66675 10.8333Z"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
};
