import SvgIcon from '@mui/material/SvgIcon';

const CustomCheckIcon = (props: any) => (
	<SvgIcon {...props}>
		<rect x="3" y="3" width="14" height="14" rx="4" ry="4" fill="rgba(0, 158, 194, 1)" />
		<path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
	</SvgIcon>
);

export default CustomCheckIcon;
