import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { authApiService } from 'app/pages/auth/service';
import { RegisterUserData } from 'core/api/models/user';
import { ToastContext } from 'core/toast/ToastProvider';

const msgSignupError = 'An error occurred during sign-up. Please try again or contact support.';
// Use the below string when emial is already existed. We can use it after the backend logic is done.
//const msgEmailAlreadyExists = 'Email address is already in use. Please use a different email or log in.';

export const usePostRegister = (): UseMutationResult<
	unknown,
	unknown,
	RegisterUserData,
	unknown
> => {
	const toast = useContext(ToastContext);
	const navigate = useNavigate();

	return useMutation({
		mutationFn: (userData: RegisterUserData) => authApiService.postRegister(userData),
		onSuccess: (response: any, userData: RegisterUserData) => {
			toast.open('Success', response.message, {
				severity: 'success',
			});
			setTimeout(() => {
				navigate('/verify-email', {
					state: { email: userData.email },
				});
			}, 1000);
		},
		onError: () => {
			toast.open('Error', msgSignupError, {
				severity: 'error',
			});
		},
	});
};

export default usePostRegister;
