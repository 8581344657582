import { FC } from 'react';

import { Skeleton } from '@mui/material';

import styles from './AdCardSkeleton.module.scss';

const AdCardSkeleton: FC = () => {
	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<Skeleton variant="circular" width={32} height={32} />
				<div>
					<Skeleton />
					<Skeleton />
				</div>
			</div>
			<Skeleton height={300} variant="rectangular" />
		</div>
	);
};

export default AdCardSkeleton;
