import {
	BillingDashboardResponse,
	BillingResponse,
	CheckoutSessionResponse,
	InvoiceObject,
	Product,
	StripePromoCode,
	SubscriptionResponseV2,
} from 'shared/models/billing';

import { Address, PaymentMethod } from '@stripe/stripe-js';
import { apiService } from 'core/api/services';

export class BillingAndPlansApiService {
	async getDashboard(user_id: number | undefined): Promise<BillingDashboardResponse> {
		return apiService.responseHandler(
			await apiService.get(`/api/client/billing/${user_id}/dashboard`)
		);
	}

	async getActiveSubscriptionV2(user_id: number | undefined): Promise<SubscriptionResponseV2> {
		try {
			return await apiService.responseHandler(
				await apiService.get<SubscriptionResponseV2>(
					`/api/client/billing/v2/${user_id}/active_subscription`
				)
			);
		} catch (error) {
			console.error('There is no active subscription:');
			return {} as SubscriptionResponseV2;
		}
	}

	async getProductsV2(): Promise<Product[]> {
		return apiService.responseHandler(
			await apiService.get<Product[]>('/api/client/billing/v2/products')
		);
	}
	async subscribe(
		user_id: number,
		planPriceId: string,
		addonPriceId?: string,
		fp_tid?: string
	): Promise<CheckoutSessionResponse> {
		return apiService.responseHandler(
			await apiService.post(`/api/client/billing/V2/${user_id}/subscribe`, {
				planPriceId,
				addonPriceId,
				fp_tid
			})
		);
	}

	async updateSubscriptionV2(
		user_id: number,
		paymentMethodId: string,
		planPriceId?: string,
		addonPriceId?: string,
		promoCode?: string
	): Promise<BillingResponse> {
		return apiService.responseHandler(
			await apiService.post(`/api/client/billing/V2/${user_id}/update_subscription`, {
				paymentMethodId,
				planPriceId,
				addonPriceId,
				...(promoCode && {
					promoCode,
				}),
			})
		);
	}

	async getInvoiceObject(
		user_id: number | undefined,
		planId: number,
		addonId?: number
	): Promise<InvoiceObject> {
		return apiService.responseHandler(
			await apiService.get(`/api/client/billing/V2/${user_id}/calculate_price`, {
				params: { planId, addonId },
			})
		);
	}

	async getInvoiceObjectV2(
		user_id: number | undefined,
		planPriceId?: string,
		addonPriceId?: string,
		promoCode?: string
	): Promise<InvoiceObject> {
		return apiService.responseHandler(
			await apiService.get(`/api/client/billing/V2/${user_id}/calculate_price`, {
				params: { planPriceId, addonPriceId, ...(promoCode && { promoCode }) },
			})
		);
	}

	async getPaymentMethods(user_id: number | undefined): Promise<PaymentMethod[]> {
		return apiService.responseHandler(
			await apiService.get(`/api/client/billing/${user_id}/payment_methods`)
		);
	}

	async updateBillingInfo(
		user_id: number | undefined,
		userAddress: Address | undefined | null,
		taxId?: string
	): Promise<any> {
		return apiService.responseHandler(
			await apiService.post(`/api/client/billing/${user_id}/update_billing_info`, {
				userAddress,
				...(taxId && {
					tax: {
						type: 'eu_vat',
						value: taxId,
					},
				}),
			})
		);
	}

	async getPromoCode(promoCode?: string): Promise<StripePromoCode> {
		return apiService.responseHandler(
			await apiService.get(`/api/client/billing/V2/promo_code`, {
				params: { promoCode },
			})
		);
	}
}

export const billingAndPlansApiService = new BillingAndPlansApiService();
