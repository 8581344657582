import { billingAndPlansApiService } from 'shared/api/services/billing/BillingAndPlansApiService';

import { PaymentMethod } from '@stripe/stripe-js';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useGetPaymentMethods = (
	user_id: number | undefined
): UseQueryResult<PaymentMethod[], Error> => {
	return useQuery({
		queryKey: ['payment_methods', user_id],
		queryFn: async () => await billingAndPlansApiService.getPaymentMethods(user_id),
		enabled: !!user_id,
	});
};
