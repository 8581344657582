import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { adsApiService } from 'shared/api/services/ads/AdsApiService';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastContext } from 'core/toast/ToastProvider';
import { deleteAdFromUserAds, updateAdTags, updatePublicAdId } from 'features/ads/adsSlice';

export const useDeleteAd = () => {
	const toast = useContext(ToastContext);
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	return useMutation({
		mutationFn: (data: { userId: number; adId: string; publicAdId: string }) =>
			adsApiService.deleteAd(data.userId, data.adId),
		onSuccess: (_, variables) => {
			queryClient.invalidateQueries({ queryKey: ['tags'] });
			dispatch(updateAdTags({ user_ad_id: variables.adId, tagData: [] }));
			dispatch(deleteAdFromUserAds({ adId: variables.adId }));
			dispatch(updatePublicAdId({ adId: variables.publicAdId, user_ad_id: undefined }));

			toast.open('Success', 'Ad deleted successfully', {
				severity: 'success',
			});
		},
		onError: () => {
			toast.open('Error', 'Error deleting ad', {
				severity: 'error',
			});
		},
	});
};

export default useDeleteAd;
