import { Product } from '../../../models/billing';
import { billingAndPlansApiService } from '../../services/billing/BillingAndPlansApiService';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useGetProducts = (): UseQueryResult<Product[], Error> => {
	return useQuery({
		queryKey: ['products'],
		queryFn: async () => await billingAndPlansApiService.getProductsV2(),
		staleTime: 3 * 60 * 1000,
	});
};
