import { useContext } from 'react';

import { publicService } from 'shared/api/services/public/publicService';

import { useMutation } from '@tanstack/react-query';
import { ToastContext } from 'core/toast/ToastProvider';

export const useCopyPublicAdLink = (userId: number | undefined, adId: string | undefined) => {
	const toast = useContext(ToastContext);

	return useMutation({
		mutationFn: async () => {
			if (!userId || !adId) {
				throw new Error('User ID and Ad ID are required');
			}
			return await publicService.getPublicAdToken(userId, adId);
		},
		onSuccess: async (data) => {
			const adLink = `${import.meta.env.VITE_APP_URL}/external/ads/${data.token}`;
			try {
				await navigator.clipboard.writeText(adLink);
				toast.open('Success', 'Link copied!', {
					severity: 'success',
				});
			} catch (err) {
				console.error('Failed to copy link!', err);
			}
		},
		onError: (err) => {
			console.error('Error fetching token:', err);
			toast.open('Error', 'Error copying link', { severity: 'error' });
		},
	});
};
