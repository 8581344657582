import { SubscriptionResponseV2 } from '../../../models/billing';
import { billingAndPlansApiService } from '../../services/billing/BillingAndPlansApiService';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useGetActiveSubscriptionV2 = (
	user_id: number | undefined
): UseQueryResult<SubscriptionResponseV2, Error> => {
	return useQuery({
		queryKey: ['active_subscription', user_id],
		queryFn: async () => await billingAndPlansApiService.getActiveSubscriptionV2(user_id),
		enabled: !!user_id,
		retry: false,
		staleTime: 3 * 60 * 1000,
	});
};
