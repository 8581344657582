import { useEffect } from 'react';

const useDisableScroll = (disable: boolean) => {
  useEffect(() => {
    const body = document.body;

    if (disable) {
      if (body.classList.contains('body-scrollbar-visible')) {
        body.classList.remove('body-scrollbar-visible');
      }
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
      body.classList.add('body-scrollbar-visible');
    }

    return () => {
      body.style.overflow = 'auto';
      body.classList.add('body-scrollbar-visible');
    };
  }, [disable]);
};

export default useDisableScroll;
