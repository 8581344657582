import { TeamResponse } from '../models/TeamResponse';
import { teamApiService } from '../services/TeamApiService';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useUser } from 'core/UserProvider/useUser';

export const useGetTeam = (team_id: number | undefined): UseQueryResult<TeamResponse, Error> => {
	const { user } = useUser();
	return useQuery({
		queryKey: ['team', team_id],
		queryFn: async () => await teamApiService.getTeam(user?.id, team_id),
		enabled: !!team_id && !!user?.id,
	});
};
