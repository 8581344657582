import { FC } from 'react';

import { Alert, AlertProps, AlertTitle, Snackbar, SnackbarProps, useTheme } from '@mui/material';

export type ToastProps = (Pick<AlertProps, 'severity' | 'variant'> &
	Pick<SnackbarProps, 'anchorOrigin' | 'autoHideDuration'>) & {
	handleToastAction?: () => void;
	toastButtonText?: string;
};

interface IToastProps extends ToastProps {
	title: string;
	alertMessage: string;
	isOpen: boolean;
	handleClose: () => void;
}

const Toast: FC<IToastProps> = ({
	title,
	alertMessage,
	isOpen,
	handleClose,
	anchorOrigin,
	severity,
	variant,
	autoHideDuration = 4000,
}) => {
	const theme = useTheme();
	return (
		<Snackbar
			sx={{ zIndex: 10000 }}
			anchorOrigin={
				anchorOrigin ?? {
					vertical: 'bottom',
					horizontal: 'right',
				}
			}
			open={isOpen}
			autoHideDuration={autoHideDuration}
			onClose={handleClose}
		>
			<Alert
				variant={variant}
				severity={severity}
				onClose={handleClose}
				sx={{
					'borderLeft':
						severity === 'success'
							? `6px solid ${theme.palette && theme.palette.success && theme.palette.success.contrastText}`
							: severity === 'error'
								? `6px solid ${theme.palette && theme.palette.error?.main}`
								: 'inherit',
					'backgroundColor':
						severity === 'error'
							? 'white'
							: severity === 'success'
								? (theme) => theme.palette.success.main
								: '',
					'color':
						severity === 'error'
							? (theme) => theme.palette.error.main
							: severity === 'success'
								? (theme) => theme.palette.success.contrastText
								: '',
					'borderRadius': 2,
					'& .MuiAlert-icon': {
						color:
							severity === 'error'
								? (theme) => theme.palette.error.main
								: severity === 'success'
									? (theme) => theme.palette.success.contrastText
									: '#d4d4d4',
					},
				}}
			>
				<AlertTitle
					sx={{
						fontWeight: 'bold',
						color:
							severity === 'success'
								? (theme) => theme.palette.success.contrastText
								: severity === 'error'
									? (theme) => theme.palette.error.main
									: '',
					}}
				>
					{title}
				</AlertTitle>
				{alertMessage}
			</Alert>
		</Snackbar>
	);
};

export default Toast;
