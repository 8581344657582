import { PRICE_PER_1000_IMPRESSIONS } from 'shared/constants/price-per-impressions';

export const calculateAdSpend = (adSpend?: number) => {
	if (!adSpend) {
		return '-';
	} else {
		return new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(adSpend);
	}
};

type Platform = keyof typeof PRICE_PER_1000_IMPRESSIONS;

type AdSpendResult = {
	raw: number | null; // Raw number for calculations and sorting
	formatted: string; // Formatted string for display
};

export const calculateAdSpendOld = (reach: number | string, platform: string): AdSpendResult => {
	if (typeof reach !== 'number' || isNaN(reach) || reach <= 0) {
		return { raw: null, formatted: '-' };
	}

	const normalizedPlatform = platform?.toLowerCase() as Platform;

	if (normalizedPlatform in PRICE_PER_1000_IMPRESSIONS) {
		const pricePerImpression = PRICE_PER_1000_IMPRESSIONS[normalizedPlatform] / 1000;
		const adSpend = reach * pricePerImpression * 1.95;

		return {
			raw: adSpend,
			formatted: new Intl.NumberFormat('de-DE', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}).format(adSpend),
		};
	} else {
		console.error('Invalid platform');
		return { raw: null, formatted: 'No spend info' };
	}
};
