import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { Box } from '@mui/material';

import { Spinner } from 'shared/components/Spinner';
import { generateVideoThumbnails } from 'shared/utils/video-utils';

import { useVideoPlayerContext } from 'core/VideoPlayerProvider/useVideoPlayerContext';

import styles from '../AdCard.module.scss';

type AdPlayerProps = {
	url: string;
	detail?: boolean;
	handleError: () => void;
	playerInitialized: boolean[];
	setPlayerInitialized: Dispatch<SetStateAction<boolean[]>>;
	index: number;
	scrollPosition: number;
	uniqueId: string;
};

interface DocumentWithWebkit extends Document {
	webkitFullscreenElement?: Element;
}

const AdPlayer: FC<AdPlayerProps> = ({
	url,
	detail,
	handleError,
	playerInitialized,
	setPlayerInitialized,
	index,
	scrollPosition,
	uniqueId,
}) => {
	const [playing, setPlaying] = useState(false);
	const [loading, setLoading] = useState(true);
	const [thumbnails, setThumbnails] = useState<string[]>([]);
	const [thumbnailsGenerated, setThumbnailsGenerated] = useState(false);
	const [hoverThumbnail, setHoverThumbnail] = useState<string | null>(null);
	const [indicatorPosition, setIndicatorPosition] = useState<number>(0);
	const [thumbnailDimensions, setThumbnailDimensions] = useState({
		width: 150,
		height: 150,
		bottom: 75,
	});
	const { activeVideoIndex, setActiveVideoIndex, resetAllPlayers } = useVideoPlayerContext();
	const playerRef = useRef<ReactPlayer | null>(null);
	const doc = document as DocumentWithWebkit;

	const handleFullScreenChange = () => {
		if (!doc.fullscreenElement && !doc.webkitFullscreenElement) {
			window.scrollTo({
				top: scrollPosition,
				behavior: 'instant',
			});
		}
	};

	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullScreenChange);
		document.addEventListener('webkitfullscreenchange', handleFullScreenChange);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullScreenChange);
			document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
		};
	}, [scrollPosition]);

	const handleReady = () => {
		resetAllPlayers();
		setPlayerInitialized((prev) => prev.map((_, i) => i === index));

		setActiveVideoIndex(uniqueId);
		setPlaying(true);
		setLoading(false);
	};

	useEffect(() => {
		if (activeVideoIndex !== uniqueId && playing) {
			setPlaying(false);
			setPlayerInitialized((prev) => prev.map((init, i) => (i === index ? false : init)));
		}
	}, [activeVideoIndex, uniqueId, playing, playerInitialized, index, setPlayerInitialized]);

	useEffect(() => {
		const generateThumbnailsOnce = async () => {
			if (!thumbnailsGenerated) {
				try {
					const videoFile = await fetch(url)
						.then((res) => res.blob())
						.then((blob) => new File([blob], 'video.mp4', { type: 'video/mp4' }));
					const generatedThumbnails = await generateVideoThumbnails(videoFile, 10);
					setThumbnails(generatedThumbnails);
					setThumbnailsGenerated(true);
				} catch (error) {
					console.error('Error generating thumbnails in AdPlayer:', error);
				}
			}
		};

		if (!loading) {
			generateThumbnailsOnce();
		}
	}, [loading, url, thumbnailsGenerated]);

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const { width, height } = entry.contentRect;
				setThumbnailDimensions({
					width: width * 0.5,
					height: height * 0.7,
					bottom: height / 5,
				});
			}
		});

		if (playerRef.current?.getInternalPlayer()) {
			const playerElement = playerRef.current.getInternalPlayer() as HTMLDivElement;
			resizeObserver.observe(playerElement);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	const handleSeekHover = (e: React.MouseEvent) => {
		const seekBar = e.currentTarget as HTMLDivElement;
		const rect = seekBar.getBoundingClientRect();
		const hoverPosition = e.clientX - rect.left;
		const seekBarWidth = rect.width;
		const hoverTime = (hoverPosition / seekBarWidth) * playerRef.current!.getDuration();
		const thumbnailIndex = Math.floor(
			(hoverTime / playerRef.current!.getDuration()) * thumbnails.length
		);
		setHoverThumbnail(thumbnails[thumbnailIndex] || null);
		setIndicatorPosition(hoverPosition);
	};

	return (
		<>
			{loading && (
				<Box className={styles.spinnerContainer}>
					<Spinner />
				</Box>
			)}
			{thumbnailsGenerated && (
				<div
					onMouseMove={handleSeekHover}
					className={styles.seekBar}
					onMouseLeave={() => {
						setHoverThumbnail(null);
						setIndicatorPosition(0);
					}}
				>
					<div className={styles.indicator} style={{ left: `${indicatorPosition}px` }} />
					{hoverThumbnail && (
						<img
							src={hoverThumbnail}
							alt="Thumbnail preview"
							style={{
								position: 'absolute',
								bottom: `${thumbnailDimensions.bottom}px`,
								left: '50%',
								transform: `translateX(-50%)`,
								width: `${thumbnailDimensions.width}px`,
								height: `${thumbnailDimensions.height}px`,
								pointerEvents: 'none',
								objectFit: 'cover',
								border: '2px solid #FFFFFF',
								borderRadius: '8px',
							}}
						/>
					)}
				</div>
			)}
			<ReactPlayer
				ref={playerRef}
				onReady={handleReady}
				onEnded={() => {
					if (doc.fullscreenElement && doc.webkitFullscreenElement) {
						window.scrollTo({
							top: scrollPosition,
							behavior: 'instant',
						});
					}
					setPlayerInitialized((prev) =>
						prev.map((init, i) => (i === index ? false : init))
					);
				}}
				className={`${styles.reactPlayer} ${detail && styles.detail}`}
				url={url}
				width="100%"
				controls
				playing={playing}
				height={loading ? 0 : 'unset'}
				onError={handleError}
			/>
		</>
	);
};

export default AdPlayer;
