import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

import classNames from 'classnames';
import { useDragContext } from 'core/DragProvider/useDrag';

import styles from './PrivateLayout.module.scss';

interface ContentProps extends PropsWithChildren<unknown> {
	isSidebarOpen: boolean;
}

const Sidebar = ({ isSidebarOpen, children }: ContentProps) => {
	return (
		<aside className={`${styles.sidebar} ${!isSidebarOpen && styles.sidebarHidden}`}>
			{children}
		</aside>
	);
};

const Content = ({ isSidebarOpen, children }: ContentProps) => {
	const { isDragging } = useDragContext();

	return (
		<section className={`${styles.content} ${!isSidebarOpen && styles.contentWide}`}>
			<Box className={classNames(styles.overlay, { [styles.overlayVisible]: isDragging })} />
			{children}
		</section>
	);
};

const PrivateLayout = ({ children }: PropsWithChildren) => {
	return <main className={styles.main}>{children}</main>;
};

PrivateLayout.Sidebar = Sidebar;
PrivateLayout.Content = Content;

export default PrivateLayout;
