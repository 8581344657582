import { adsApiService } from 'shared/api/services/ads/AdsApiService';
import { Ad } from 'shared/models/ads';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useGetAd = (
	id: string,
	adsType: 'publicAds' | 'userAds',
	userId?: number | undefined,
	token?: string | undefined
): UseQueryResult<Ad, Error> => {
	const queryFn = async () => {
		if (token) {
			return await adsApiService.getPublicTagAd(id, token);
		}
		if (adsType === 'userAds') {
			if (userId === undefined) {
				throw new Error('User ID must be provided for userAds');
			}
			return await adsApiService.getUserAd(id, userId);
		} else if (adsType === 'publicAds') {
			return await adsApiService.getAd(id, userId);
		} else {
			return await adsApiService.getUserAd(id, userId);
		}
	};
	return useQuery({
		queryKey: ['ad', userId, id, token],
		queryFn: queryFn,
		enabled: !!id,
	});
};
