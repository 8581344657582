import { apiService } from 'core/api/services';

interface Note {
	id: string | undefined;
	userId: number;
	content: string;
	is_private: boolean;
}

export class NotesApiService {
	async postNote(
		userId: number,
		content: string,
		adId: string | undefined,
		is_private: boolean
	): Promise<Note> {
		return apiService.responseHandler(
			await apiService.post<Note>(`api/client/user/${userId}/note`, {
				content: content,
				user_ad_id: adId,
				is_private: is_private,
			})
		);
	}
	async patchNote(
		userId: number,
		content: string,
		noteId: string | undefined,
		is_private: boolean
	): Promise<Note> {
		return apiService.responseHandler(
			await apiService.patch<Note>(`api/client/user/${userId}/note/${noteId}`, {
				content: content,
				is_private: is_private,
			})
		);
	}

	async deleteNote(userId: number | undefined, noteId: string | undefined): Promise<unknown> {
		return apiService.responseHandler(
			await apiService.delete<unknown>(`api/client/user/${userId}/note/${noteId}`)
		);
	}
}

export const notesApiService = new NotesApiService();
