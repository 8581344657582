import type { FC, PropsWithChildren } from 'react';
import { createContext } from 'react';
import type { ValidTranslationKeys } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import { TOptions } from 'i18next';

interface MultiLanguageSupportContextValue {
	t: (key: ValidTranslationKeys | ValidTranslationKeys[], options?: TOptions) => string;
}

const MultiLanguageSupportContext = createContext<MultiLanguageSupportContextValue>({
	t: () => 'No provider available.',
});

export const MultiLanguageSupportProvider: FC<PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation<ValidTranslationKeys>();

	return (
		<MultiLanguageSupportContext.Provider value={{ t }}>
			{children}
		</MultiLanguageSupportContext.Provider>
	);
};

export default MultiLanguageSupportContext;
