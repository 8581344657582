import type { Token, TokenResponse } from 'core/token/models';

class TokenApiService {
	async refreshToken(refreshToken: string, userId: number | undefined): Promise<Token> {
		const body = JSON.stringify({
			id: userId,
			refresh_token: refreshToken,
		});

		const { accessToken, refresh_token }: TokenResponse = await fetch(
			`${import.meta.env.VITE_APP_API_URL}api/client/auth/refresh-token`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: body,
			}
		).then((response) => response.json());
		return {
			token: accessToken,
			refreshToken: refresh_token,
		};
	}
}

const tokenApiService = new TokenApiService();

export default tokenApiService;
