import { SvgIcon, SvgIconProps } from '@mui/material';

export const SearchIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.05845 12.6017C4.18996 13.3577 5.52027 13.7613 6.88113 13.7613C7.7847 13.7613 8.67943 13.5833 9.51423 13.2375C10.1373 12.9794 10.718 12.6316 11.2381 12.2061L14.8309 15.7989C14.8942 15.863 14.9696 15.9138 15.0528 15.9483C15.136 15.9828 15.2252 16.0004 15.3152 15.9999C15.4053 16.0004 15.4945 15.9828 15.5777 15.9483C15.6608 15.9138 15.7362 15.863 15.7995 15.7989C15.9279 15.6704 15.9999 15.4962 15.9999 15.3146C15.9999 15.133 15.9279 14.9588 15.7995 14.8303L12.2067 11.2375C12.6321 10.7174 12.9799 10.1368 13.238 9.51374C13.5838 8.67895 13.7618 7.78422 13.7618 6.88064C13.7618 5.51978 13.3582 4.18948 12.6022 3.05796C11.8461 1.92645 10.7715 1.04454 9.51423 0.52376C8.25696 0.00298121 6.87349 -0.133278 5.53878 0.132213C4.20407 0.397703 2.97806 1.05302 2.01578 2.01529C1.05351 2.97757 0.398192 4.20358 0.132701 5.53829C-0.13279 6.873 0.0034695 8.25647 0.524248 9.51374C1.04503 10.771 1.92694 11.8456 3.05845 12.6017ZM3.83517 2.30377C4.73677 1.70133 5.79677 1.37979 6.88113 1.37979C8.3352 1.37979 9.72971 1.95741 10.7579 2.98559C11.7861 4.01378 12.3637 5.40829 12.3637 6.86236C12.3637 7.94671 12.0422 9.00671 11.4397 9.90832C10.8373 10.8099 9.98103 11.5126 8.97922 11.9276C7.97741 12.3426 6.87504 12.4511 5.81153 12.2396C4.74801 12.028 3.77111 11.5059 3.00436 10.7391C2.23761 9.97238 1.71544 8.99548 1.50389 7.93196C1.29235 6.86844 1.40092 5.76608 1.81588 4.76427C2.23085 3.76246 2.93356 2.9062 3.83517 2.30377Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
