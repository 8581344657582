import { UserTeamsResponse } from '../models/UserTeamsResponse';
import { teamApiService } from '../services/TeamApiService';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useUser } from 'core/UserProvider/useUser';

export const useGetUsersTeams = (id?: number): UseQueryResult<UserTeamsResponse, Error> => {
	const { user } = useUser();
	return useQuery({
		queryKey: ['userTeams', id],
		queryFn: async () => await teamApiService.getUsersTeams(id ?? (user?.id as number)),
	});
};
