import React, { FC, PropsWithChildren, createContext, useState } from 'react';


export interface VideoPlayerContextProps {
	activeVideoIndex: string | null;
	setActiveVideoIndex: React.Dispatch<React.SetStateAction<string | null>>;
    resetAllPlayers: () => void;
}

export const VideoPlayerContext = createContext<VideoPlayerContextProps | undefined>(undefined);

const VideoPlayerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeVideoIndex, setActiveVideoIndex] = useState<string | null>(null);

   const resetAllPlayers = () => {
		setActiveVideoIndex(null);
   };

  return (
    <VideoPlayerContext.Provider value={{ activeVideoIndex, setActiveVideoIndex, resetAllPlayers }}>
      {children}
    </VideoPlayerContext.Provider>
  );
};

export default VideoPlayerProvider;