import React, { useState } from 'react';

import { Box, TextField, Typography } from '@mui/material';

import styles from '../TagManagement.module.scss';

interface InputEditWrapperProps {
	prefix: string;
	value: string;
	onSave: (value: string) => void;
	onCancel?: () => void;
	isSubTag?: boolean;
	isEditing?: boolean;
}

const InputEditWrapper: React.FC<InputEditWrapperProps> = ({
	prefix,
	value: initialValue,
	onSave,
	onCancel,
	isSubTag,
	isEditing,
}) => {
	const [value, setValue] = useState(initialValue);

	const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSave(value);
        } else if (e.key === 'Escape' && onCancel) {
            onCancel();
        }
    };

	return (
		<Box
			className={`${isEditing ? styles.inputEditWrapper : styles.inputWrapper} ${isSubTag ? styles.subTagInputWrapper : ''}`}
		>
			<Typography variant="h6" className={styles.hashSymbol}>
				{prefix}
			</Typography>
			<TextField
				size="small"
				value={value}
				fullWidth
				onChange={(e) => setValue(e.target.value)}
				onKeyDown={handleKeyDown}
				onBlur={onCancel}
				autoFocus
				className={styles.tagInput}
			/>
		</Box>
	);
};

export default InputEditWrapper;
