import { Ad } from 'shared/models/ads';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UserAdsState {
	filteredAds?: Ad[];
	hasNextPage: boolean;
	isFetchingNextPage: boolean;
	isLoading: boolean;
	totalAdsCount: number | null;
	error: any;
	requestNextPage: boolean;
}

interface PublicAdsState extends UserAdsState {}

interface AdsState {
	userAds: UserAdsState;
	publicAds: PublicAdsState;
}

const initialState: AdsState = {
	userAds: {
		filteredAds: undefined,
		hasNextPage: false,
		isFetchingNextPage: false,
		isLoading: false,
		totalAdsCount: 0,
		error: null,
		requestNextPage: false,
	},
	publicAds: {
		filteredAds: undefined,
		hasNextPage: false,
		isFetchingNextPage: false,
		isLoading: false,
		totalAdsCount: 0,
		error: null,
		requestNextPage: false,
	},
};

export const adsSlice = createSlice({
	name: 'ads',
	initialState,
	reducers: {
		setUserAdsData: (state, action: PayloadAction<Partial<UserAdsState>>) => {
			const {
				filteredAds,
				hasNextPage,
				isFetchingNextPage,
				isLoading,
				totalAdsCount,
				error,
			} = action.payload;
			state.userAds = {
				...state.userAds,
				filteredAds: filteredAds ?? state.userAds.filteredAds,
				hasNextPage: hasNextPage ?? state.userAds.hasNextPage,
				isFetchingNextPage: isFetchingNextPage ?? state.userAds.isFetchingNextPage,
				isLoading: isLoading ?? state.userAds.isLoading,
				totalAdsCount: totalAdsCount ?? state.userAds.totalAdsCount,
				error: error ?? state.userAds.error,
			};
		},
		setPublicAdsData: (state, action: PayloadAction<Partial<PublicAdsState>>) => {
			const {
				filteredAds,
				hasNextPage,
				isFetchingNextPage,
				isLoading,
				totalAdsCount,
				error,
			} = action.payload;
			state.publicAds = {
				...state.publicAds,
				filteredAds: filteredAds ?? state.publicAds.filteredAds,
				hasNextPage: hasNextPage ?? state.publicAds.hasNextPage,
				isFetchingNextPage: isFetchingNextPage ?? state.publicAds.isFetchingNextPage,
				isLoading: isLoading ?? state.publicAds.isLoading,
				totalAdsCount: totalAdsCount ?? state.publicAds.totalAdsCount,
				error: error ?? state.publicAds.error,
			};
		},
		updatePublicAdId: (
			state,
			action: PayloadAction<{ adId: string; user_ad_id: string | undefined }>
		) => {
			const { adId, user_ad_id } = action.payload;
			state.publicAds.filteredAds = state.publicAds.filteredAds?.map((ad: Ad) =>
				ad._id === adId ? { ...ad, user_ad_id } : ad
			);
		},
		requestNextPageUserAds: (state) => {
			state.userAds.requestNextPage = true;
		},
		resetRequestNextPageUserAds: (state) => {
			state.userAds.requestNextPage = false;
		},
		requestNextPagePublicAds: (state) => {
			state.publicAds.requestNextPage = true;
		},
		resetRequestNextPagePublicAds: (state) => {
			state.publicAds.requestNextPage = false;
		},
		deleteAdFromUserAds: (state, action: PayloadAction<{ adId: string }>) => {
			const { adId } = action.payload;
			state.userAds.filteredAds =
				state.userAds.filteredAds &&
				state.userAds.filteredAds?.filter((ad: Ad) => ad.user_ad_id !== adId);
		},
		updateAdTags: (
			state,
			action: PayloadAction<{ user_ad_id: string; tagData: { id: number; name: string }[] }>
		) => {
			const { user_ad_id, tagData } = action.payload;
			state.userAds.filteredAds =
				state.userAds.filteredAds &&
				state.userAds.filteredAds?.map((ad: Ad) =>
					ad.user_ad_id === user_ad_id ? { ...ad, tags: tagData } : ad
				);
			state.publicAds.filteredAds =
				state.publicAds.filteredAds &&
				state.publicAds.filteredAds?.map((ad: Ad) =>
					ad.user_ad_id === user_ad_id ? { ...ad, user_ad_tags: tagData } : ad
				);
		},
		resetUserAdsState: (state) => {
			state.userAds = initialState.userAds;
		},
		resetPublicAdsState: (state) => {
			state.publicAds = initialState.publicAds;
		},
	},
});

export const {
	setUserAdsData,
	setPublicAdsData,
	updatePublicAdId,
	requestNextPageUserAds,
	resetRequestNextPageUserAds,
	requestNextPagePublicAds,
	resetRequestNextPagePublicAds,
	deleteAdFromUserAds,
	updateAdTags,
	resetUserAdsState,
	resetPublicAdsState,
} = adsSlice.actions;

export default adsSlice.reducer;
