import { FC, PropsWithChildren } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { store } from '../app/store/store';

const ReduxStoreProvider: FC<PropsWithChildren> = ({ children }) => {
	return <ReduxProvider store={store}>{children}</ReduxProvider>;
};

export default ReduxStoreProvider;
