import { BillingDashboardResponse } from 'shared/models/billing';

import { billingAndPlansApiService } from '../../services/billing/BillingAndPlansApiService';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useGetDashboard = (
	user_id: number | undefined
): UseQueryResult<BillingDashboardResponse, Error> => {
	return useQuery({
		queryKey: ['dashboard', user_id],
		queryFn: async () => await billingAndPlansApiService.getDashboard(user_id),
		enabled: !!user_id,
	});
};
