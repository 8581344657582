import React, { FC, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
    Avatar,
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Popover,
    Typography,
} from '@mui/material';

import {
    ChartIcon,
    CogWheelIcon,
    DollarIcon,
    ElipsisVertIcon,
    GlobeIcon,
    QuestionMarkIcon,
    SearchIcon,
    SignOutIcon,
    ThIcon,
} from 'shared/components/Icons';
import { RoutesEnum } from 'shared/enums';
import useScrollLock from 'shared/hooks/useScrollLock';
import { getFullName } from 'shared/utils/getFullName';

import { useGetUserSettingsData } from 'app/pages/settings/hooks/useGetUserSettingsData';
import Logo from 'assets/images/logo.svg';
import { useDragContext } from 'core/DragProvider/useDrag';
import { useSidebar } from 'core/SidebarProvider/useSidebar';
import { useUser } from 'core/UserProvider/useUser';
import { tokenService } from 'core/token/services';
import FreeTrialModal from '../FreeTrialModal';
import { TagManagement } from '../TagManagement';

import styles from './Sidebar.module.scss';

const Sidebar: FC = () => {
	const navigate = useNavigate();
	const { isDragging } = useDragContext();
	const { toggleSidebar, isSidebarOpen } = useSidebar();
	const {
		actions: { logoutUser },
	} = useUser();
	const { user, teams } = useGetUserSettingsData();
	const hideBillingItem = teams?.owner.length === 0;

	const menuItems = [
		{ text: 'Overview', icon: <ChartIcon />, link: RoutesEnum.HOME },
		{ text: 'My collection', icon: <ThIcon />, link: RoutesEnum.SWIPE },
		{
			text: 'Tracker',
			icon: <SearchIcon sx={{ width: '20px', height: '20px' }} />,
			link: RoutesEnum.TRACKER,
		},
		{ text: 'Discovery', icon: <GlobeIcon />, link: RoutesEnum.DISCOVERY },
		{ text: 'High Spend Ads', icon: <StarBorderIcon />, link: RoutesEnum.TOPADS },
	];

	const moreItems = [
		{
			text: 'Settings',
			icon: <CogWheelIcon sx={{ width: '14px', height: '14px' }} />,
			link: '/settings?tab=0',
		},
		{
			text: 'Billing & Plans',
			icon: <DollarIcon sx={{ width: '14px', height: '14px' }} />,
			link: '/settings?tab=2',
		},
		{
			text: 'Help',
			icon: <QuestionMarkIcon sx={{ width: '14px', height: '14px' }} />,
			link: 'https://aduniverse.ai/faq',
			external: true,
		},
		{
			text: 'Log out',
			icon: <SignOutIcon sx={{ width: '14px', height: '14px' }} />,
			link: '/login',
		},
	];

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const profileMenuItems = moreItems.filter(
		(item) => !(hideBillingItem && item.text === 'Billing & Plans')
	);

	useScrollLock(anchorEl);

	const handleLogout = () => {
		tokenService.clear();
		logoutUser();
		navigate('/login');
	};

	const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget as HTMLElement);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// const getBadge = () => {
	// 	switch (user?.subscription.id) {
	// 		case 1:
	// 			return {
	// 				label: 'PREMIUM',
	// 				type: BadgeEnum.PREMIUM,
	// 			};
	// 		case 2:
	// 			return {
	// 				label: 'PRO',
	// 				type: BadgeEnum.PRO,
	// 			};
	// 		default:
	// 			return {
	// 				label: '',
	// 				type: BadgeEnum.EMPTY,
	// 			};
	// 	}
	// };

	return (
		<>
			<Drawer
				PaperProps={{ className: styles.drawerPaper }}
				className={styles.drawer}
				variant="persistent"
				anchor="left"
				open={isSidebarOpen}
				onClose={toggleSidebar}
			>
				<Box
					className={`${styles.logoContainer} ${isDragging ? styles.dimmed : styles.opaque}`}
				>
					<Link to={RoutesEnum.HOME} style={{ display: 'flex' }}>
						<img src={Logo} alt="Logo" />
					</Link>
					{/* <Box className={styles.versionBadgeContainer}>
						<Box className={styles.versionBadge}>
							Beta <span>v0.8</span>
						</Box>
					</Box>
					<InfoBadge {...getBadge()} /> */}
				</Box>
				<Box
					className={`${styles.navigation} ${isDragging ? styles.dimmed : styles.opaque}`}
				>
					<Typography className={styles.subtitle}>DASHBOARD</Typography>
					<List>
						{menuItems.map((item) => (
							<ListItem disablePadding key={item.text}>
								<NavLink
									to={item.link}
									className={({ isActive }) =>
										`${styles.routerLink} ${isActive ? styles.active : ''}`
									}
								>
									<ListItemButton>
										<ListItemIcon className={styles.linkIcon}>
											{item.icon}
										</ListItemIcon>
										<Typography variant="link">{item.text}</Typography>
									</ListItemButton>
								</NavLink>
							</ListItem>
						))}
					</List>
				</Box>
				<Box className={styles.favorites}>
					<TagManagement />
					<FreeTrialModal />
				</Box>
				<Box
					className={`${styles.userInfoGroup} ${isDragging ? styles.dimmed : styles.opaque}`}
				>
					<Avatar src={user?.avatar_url} />
					<Box className={styles.detailInfo}>
						<Box className={styles.nameAndEmail}>
							<Typography className={styles.name}>
								{getFullName(user) === '' ? 'User' : getFullName(user)}
							</Typography>
							<Typography className={styles.email}>{user?.email}</Typography>
						</Box>
						<IconButton
							className={styles.moreButton}
							aria-describedby={id}
							onClick={handlePopover}
						>
							<ElipsisVertIcon sx={{ width: '20px', height: '20px' }} />
						</IconButton>
					</Box>
				</Box>
			</Drawer>
			<Popover
				disableScrollLock={true}
				sx={{ zIndex: '1600', mt: -7, ml: 1 }}
				id={id}
				open={open}
				onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
			>
				<List>
					{profileMenuItems.map((item) => (
						<ListItem
							disablePadding
							key={item.text}
							onClick={() => {
								if (item.text === 'Log out') {
									handleLogout();
								} else {
									handleClose();
								}
							}}
						>
							{item.external ? (
								<Link
									to={item.link}
									target="_blank"
									className={styles.moreItemLink}
								>
									<ListItemButton className={styles.menuButton}>
										<ListItemIcon className={styles.linkIcon}>
											{item.icon}
										</ListItemIcon>
										<Typography className={styles.linkTitle}>
											{item.text}
										</Typography>
									</ListItemButton>
								</Link>
							) : (
								<NavLink to={item.link} className={styles.moreItemLink}>
									<ListItemButton className={styles.menuButton}>
										<ListItemIcon className={styles.linkIcon}>
											{item.icon}
										</ListItemIcon>
										<Typography className={styles.linkTitle}>
											{item.text}
										</Typography>
									</ListItemButton>
								</NavLink>
							)}
						</ListItem>
					))}
				</List>
			</Popover>
			<IconButton
				onClick={toggleSidebar}
				className={`${styles.toggleButton} ${!isSidebarOpen ? styles.moveToLeft : styles.moveToRight} ${isDragging && styles.hidden}`}
			>
				{isSidebarOpen ? (
					<KeyboardDoubleArrowLeftIcon className={styles.icon} />
				) : (
					<KeyboardDoubleArrowRightIcon className={styles.icon} />
				)}
			</IconButton>
		</>
	);
};

export default Sidebar;
