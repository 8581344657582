import { useContext } from 'react';

import { DragContext, DragContextProps } from './DragProvider';

export const useDragContext = (): DragContextProps => {
	const context = useContext(DragContext);
	if (!context) {
		throw new Error('useDragContext must be used within a DragProvider');
	}
	return context;
};
