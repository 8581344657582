import { useCopyPublicTagToken } from 'shared/api/hooks/public/useCopyPublicTagToken';

import { useUser } from 'core/UserProvider/useUser';

export const useCopyFolderLink = (tagId: number) => {
	const { user } = useUser();
	const copyFolderLinkMutation = useCopyPublicTagToken(user?.id, tagId);

	const handleCopyFolderLink = () => {
		if (tagId) {
			copyFolderLinkMutation.mutate();
		}
	};

	return { handleCopyFolderLink };
};
