export const importFileAndPreview = (file: File): Promise<string> => {
	return new Promise((resolve) => {
		const preview = URL.createObjectURL(file);
		resolve(preview);
	});
};

export const getVideoDuration = (videoFile: File): Promise<number> => {
	return new Promise((resolve, reject) => {
		importFileAndPreview(videoFile).then((url) => {
			const video = document.createElement('video');
			video.preload = 'metadata';
			video.src = url;
			video.muted = true;
			video.playsInline = true;
			video.onloadedmetadata = () => {
				resolve(video.duration);
				URL.revokeObjectURL(url);
			};
			video.onerror = () => reject(new Error('Error loading video'));
		});
	});
};

export const getVideoThumbnail = (file: File, timeInSeconds: number): Promise<string> => {
	return new Promise((resolve, reject) => {
		importFileAndPreview(file).then((url) => {
			const video = document.createElement('video');
			video.src = url;
			video.muted = true;
			video.playsInline = true;
			video.onloadeddata = () => {
				video.currentTime = timeInSeconds;
			};
			video.onseeked = () => {
				const canvas = document.createElement('canvas');
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				const ctx = canvas.getContext('2d');
				if (ctx) {
					ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
					const imageUrl = canvas.toDataURL('image/png');
					resolve(imageUrl);
					URL.revokeObjectURL(url);
				} else {
					reject(new Error('Could not get canvas context'));
				}
			};
			video.onerror = () => reject(new Error('Failed to capture thumbnail'));
		});
	});
};

export const generateVideoThumbnails = async (
	videoFile: File,
	numberOfThumbnails: number
): Promise<string[]> => {
	const thumbnails: string[] = [];
	try {
		const duration = await getVideoDuration(videoFile);
		const interval = duration / numberOfThumbnails;

		for (let i = 0; i < numberOfThumbnails; i++) {
			const captureTime = i * interval;
			const thumbnail = await getVideoThumbnail(videoFile, captureTime);
			thumbnails.push(thumbnail);
		}

		return thumbnails;
	} catch (error) {
		return [];
	}
};
