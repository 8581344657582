import 'index.scss';

import { StrictMode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';

import { GlobalOverlay } from 'shared/components/GlobalOverlay';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { App } from 'app';
import { CoreProvider } from 'core';
import './instrument';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

root.render(
	<StrictMode>
		<GoogleOAuthProvider clientId={googleClientId}>
			<CoreProvider>
				<DndProvider backend={HTML5Backend}>
					<GlobalOverlay>
						<App />
					</GlobalOverlay>
				</DndProvider>
			</CoreProvider>
		</GoogleOAuthProvider>
	</StrictMode>
);
