import type { LocalStorageKeysEnum } from '../enums';

const setItemToLocalStorage = <T>(key: LocalStorageKeysEnum, value: T): void => {
	try {
		window.localStorage.setItem(key, JSON.stringify(value));
	} catch (error) {
		console.error(`Can not get item with key ${key} from local storage - `, error);
	}
};

export default setItemToLocalStorage;
