import React, { ForwardedRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	SvgIcon,
	TextField,
	Tooltip,
} from '@mui/material';

import CustomCheckIcon from 'shared/components/CustomCheckIcon';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import { HeartRoundedIcon, HeartRoundedIconOutlined, SearchIcon } from 'shared/components/Icons';
import { Ad } from 'shared/models/ads';
import { Tag } from 'shared/models/tags';

import { RootState } from 'app/store/store';

import styles from '../AdCard.module.scss';

interface ExpandedTags {
	[key: number]: boolean;
}

interface SaveAdSubMenuProps {
	ad: Ad | undefined;
	subMenuAnchorEl: HTMLElement | null;
	handleCloseSubMenu: () => void;
	searchTerm: string;
	handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	handleSaveClick: () => void;
	checkedTagIds: number[];
	setCheckedTagIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const SaveAdSubMenu = React.forwardRef(function SaveAdSubMenu(
	{
		subMenuAnchorEl,
		handleCloseSubMenu,
		searchTerm,
		handleSearchChange,
		handleKeyDown,
		ad,
		setCheckedTagIds,
		checkedTagIds,
		handleSaveClick,
	}: SaveAdSubMenuProps,
	ref: ForwardedRef<HTMLDivElement | null>
) {
	const { tags } = useSelector((state: RootState) => state.tags);

	const [expandedTags, setExpandedTags] = useState<ExpandedTags>({});
	const [initialCheckedTagIds, setInitialCheckedTagIds] = useState<number[]>([]);

	const handleToggleSubtags = (tagId: number) => {
		setExpandedTags((prevExpandedTags) => ({
			...prevExpandedTags,
			[tagId]: !prevExpandedTags[tagId],
		}));
	};

	useEffect(() => {
		// User ads - swipe
		if (ad?.tags) {
			const initialTagIds = ad.tags.map((tag) => tag.id);
			setCheckedTagIds(initialTagIds);
			setInitialCheckedTagIds(initialTagIds);
		}
		// Public saved ads - discovery
		if (ad?.user_ad_tags) {
			const initialTagIds = ad.user_ad_tags.map((user_ad_tag) => user_ad_tag.id);
			setCheckedTagIds(initialTagIds);
			setInitialCheckedTagIds(initialTagIds);
		}
	}, [ad, setCheckedTagIds]);

	useEffect(() => {
		if (tags) {
			const initialExpandedState = tags.reduce<ExpandedTags>((acc, tag) => {
				acc[tag.id] = false;
				return acc;
			}, {});
			setExpandedTags(initialExpandedState);
		}
	}, [tags]);

	const filteredTags = searchTerm
		? tags.filter((tag: Tag) => {
				const tagMatches = tag.name.toLowerCase().includes(searchTerm.toLowerCase());
				const subtagMatches = tag.subTags?.some((subTag) =>
					subTag.name.toLowerCase().includes(searchTerm.toLowerCase())
				);

				return tagMatches || subtagMatches;
			})
		: tags;

	useEffect(() => {
		if (searchTerm && filteredTags.length > 0) {
			const newExpandedTags = filteredTags.reduce<ExpandedTags>((acc, tag) => {
				acc[tag.id] = true;
				return acc;
			}, {});
			setExpandedTags(newExpandedTags);
		}
	}, [searchTerm, filteredTags]);

	const handleTagChange = (tagId: number, isChecked: boolean) => {
		setCheckedTagIds((prev) =>
			isChecked ? [...prev, tagId] : prev.filter((id) => id !== tagId)
		);
	};

	const hasTagsChanged = () => {
		if (initialCheckedTagIds.length !== checkedTagIds.length) {
			return true;
		}

		const sortedInitialIds = [...initialCheckedTagIds].sort();
		const sortedCheckedIds = [...checkedTagIds].sort();

		return !sortedInitialIds.every((id, index) => id === sortedCheckedIds[index]);
	};

	return (
		<Menu
			id="save-ad-menu"
			anchorEl={subMenuAnchorEl}
			keepMounted
			open={Boolean(subMenuAnchorEl)}
			onClose={handleCloseSubMenu}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			slotProps={{
				paper: {
					ref: ref,
					style: {
						marginLeft: -2,
						marginTop: -8,
						width: 220,
					},
				},
			}}
		>
			<MenuItem sx={{ background: 'rgba(248, 249, 250, 1)' }}>
				<TextField
					disabled={tags.length === 0}
					placeholder="Search Tags"
					value={searchTerm}
					onChange={handleSearchChange}
					onKeyDown={handleKeyDown}
					fullWidth
					variant="outlined"
					size="small"
					sx={{ '.MuiInputBase-root': { paddingRight: '6px' } }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									sx={{ width: '24px', height: '24px' }}
									aria-label="search tags"
								>
									<SearchIcon
										sx={{
											width: '14px',
											height: '14px',
										}}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</MenuItem>
			<Box className={styles.tagsListMenu}>
				{filteredTags.map((tag) => (
					<React.Fragment key={tag.id}>
						<MenuItem>
							<ListItemIcon>
								<CustomCheckbox
									checked={checkedTagIds.includes(tag.id)}
									onChange={(e) => handleTagChange(tag.id, e.target.checked)}
									icon={
										<SvgIcon>
											<rect
												x="2"
												y="2"
												width="18"
												height="18"
												rx="4"
												ry="4"
												fill="#fff"
											/>
										</SvgIcon>
									}
									checkedIcon={<CustomCheckIcon />}
								/>
							</ListItemIcon>
							<Tooltip title={`# ${tag.name}`} placement="top">
								<ListItemText
									className={styles.tagName}
									primary={`# ${tag.name}`}
								/>
							</Tooltip>
							{tag.subTags && tag.subTags.length > 0 && (
								<IconButton onClick={() => handleToggleSubtags(tag.id)}>
									{expandedTags[tag.id] ? (
										<KeyboardArrowDown />
									) : (
										<KeyboardArrowRight />
									)}
								</IconButton>
							)}
						</MenuItem>
						{expandedTags[tag.id] &&
							tag.subTags &&
							tag.subTags.map((subTag) => (
								<MenuItem key={subTag.id} style={{ paddingLeft: '30px' }}>
									<ListItemIcon>
										<CustomCheckbox
											checked={checkedTagIds.includes(subTag.id)}
											onChange={(e) =>
												handleTagChange(subTag.id, e.target.checked)
											}
											icon={
												<SvgIcon>
													<rect
														x="2"
														y="2"
														width="18"
														height="18"
														rx="4"
														ry="4"
														fill="#fff"
													/>
												</SvgIcon>
											}
											checkedIcon={<CustomCheckIcon />}
										/>
									</ListItemIcon>
									<Tooltip title={`# ${subTag.name}`} placement="top">
										<ListItemText
											className={styles.tagName}
											primary={`# ${subTag.name}`}
										/>
									</Tooltip>
								</MenuItem>
							))}
					</React.Fragment>
				))}
			</Box>

			<MenuItem sx={{ padding: '8px' }}>
				<Button
					disabled={tags.length === 0 || !hasTagsChanged()}
					startIcon={
						tags.length === 0 || !hasTagsChanged() ? (
							<HeartRoundedIconOutlined
								sx={{
									color: '#fff !important',
									width: '16px',
									height: '16px',
								}}
							/>
						) : (
							<HeartRoundedIcon sx={{ width: '16px', height: '16px' }} />
						)
					}
					onClick={handleSaveClick}
					fullWidth
					variant="outlined"
					color="primary"
					sx={{
						'&.Mui-disabled': {
							opacity: 0.5,
							backgroundColor: 'white',
							borderColor: 'rgba(200, 225, 229, 1)',
							color: 'rgba(0, 158, 194, 1)',
						},
					}}
				>
					Save Ad
				</Button>
			</MenuItem>
		</Menu>
	);
});

export default SaveAdSubMenu;
