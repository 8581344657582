import { useEffect, useState } from 'react';

import { useCreateTag } from 'shared/api/hooks/tags/useCreateTag';
import { useDeleteTag } from 'shared/api/hooks/tags/useDeleteTag';
import { useGetTags } from 'shared/api/hooks/tags/useGetTags';
import { useUpdateTag } from 'shared/api/hooks/tags/useUpdateTag';
import { Tag, TagsResponse } from 'shared/models/tags';

export const useTags = (userId: number | undefined) => {
	const { data: tagsData, isLoading, isError } = useGetTags(userId);
	const [tags, setTags] = useState<Tag[]>([]);
	const createTagMutation = useCreateTag();
	const updateTagMutation = useUpdateTag();
	const deleteTagMutation = useDeleteTag();

	const organizeTagsAndSubTags = (tagsResponse: TagsResponse): Tag[] => {
		const tagsMap: Record<number, Tag & { subTags?: Tag[] }> = {};

		tagsResponse.tags.forEach((tag) => {
			tagsMap[tag.id] = { ...tag, subTags: [] };
		});

		tagsResponse.tags.forEach((tag) => {
			if (tag.parent_id !== null && tagsMap[tag.parent_id]) {
				tagsMap[tag.parent_id].subTags?.push(tag);
			}
		});
		return Object.values(tagsMap).filter((tag) => tag.parent_id === null);
	};

	useEffect(() => {
		if (tagsData && 'tags' in tagsData) {
			const organizedTags = organizeTagsAndSubTags(tagsData);
			setTags(organizedTags);
		}
	}, [tagsData]);

	const addTag = (tagName: string, level: number, parentId: number | null) => {
		if (!tagName) return;

		createTagMutation.mutate({
			userId: userId,
			tagName: tagName,
			level: level,
			parentId: parentId,
		});
	};

	const updateTagName = (tagId: number, newName: string) => {
		updateTagMutation.mutate({ userId, tagId, tagName: newName });
	};

	const deleteTag = (tagId: number, tagName: string) => {
		deleteTagMutation.mutate({ userId, tagId, tagName });
	};

	return {
		tags,
		addTag,
		updateTagName,
		deleteTag,
		isError,
		isLoading,
	};
};
