import { FC } from 'react';
import Box from '@mui/material/Box/Box';
import { AdCard } from 'shared/components/AdGrid/AdCard';
import { Ad } from 'shared/models/ads';
import styles from './DetailsCardView.module.scss';

interface DetailsCardViewProps {
	ad: Ad | undefined;
}

const DetailsCardView: FC<DetailsCardViewProps> = ({ ad }) => {
	return (
		<Box className={styles.cardWrapper}>
			<Box className={styles.cardContainer}>
				<AdCard footer ad={ad} detail />
			</Box>
		</Box>
	);
};

export default DetailsCardView;
