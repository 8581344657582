import { useContext } from 'react';

import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { authApiService } from 'app/pages/auth/service';
import { ToastContext } from 'core/toast/ToastProvider';

const msgValidEmail =
	'A password reset link has been sent to your email address. Please check your inbox.';
// The bellow meesage text is used after the backend logic is done.
//const msgInvalidEmail = 'Invalid email address. Please enter a valid email associated with your account.';
const msgError =
	'An error occurred during the password reset. Please try again or contact support.';

export const usePostForgotPassword = (): UseMutationResult<unknown, unknown, unknown, unknown> => {
	const toast = useContext(ToastContext);

	return useMutation({
		mutationFn: (userData) => authApiService.postForgotPassword(userData),
		onSuccess: () => {
			toast.open('Success', msgValidEmail, {
				severity: 'success',
			});
		},
		onError: () => {
			toast.open('Error', msgError, {
				severity: 'error',
			});
		},
	});
};

export default usePostForgotPassword;
