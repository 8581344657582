import { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { CommentIcon, EyeIcon, ThumbsUpIcon } from 'shared/components/Icons';
import { Ad } from 'shared/models/ads';

import styles from '../AdCard.module.scss';

interface CardFooterProps {
	ad: Ad | undefined;
}

const CardFooter: FC<CardFooterProps> = ({ ad }) => {
	return (
		<Box className={styles.footer}>
			<Box className={styles.footerLeft}>
				<ThumbsUpIcon sx={{ width: '20px', height: '20px' }} />
				<Typography>{ad?.total_likes}</Typography>
			</Box>
			<Box className={styles.footerCenter}>
				<EyeIcon sx={{ width: '20px', height: '20px' }} />
				<Typography>{ad?.ad_impressions}</Typography>
			</Box>
			<Box className={styles.footerCenter}>
				<CommentIcon sx={{ width: '20px', height: '20px' }} />
				<Typography>{ad?.comments}</Typography>
			</Box>
		</Box>
	);
};

export default CardFooter;
