import { useCallback, useState } from 'react';

export const usePermissionModal = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [content, setContent] = useState<React.ReactNode>();

	const handleModalOpen = useCallback(() => {
		setIsOpen(true);
	}, []);

	const handleModalClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const memoizedSetContent = useCallback((content: React.ReactNode) => {
		setContent(content);
	}, []);

	return {
		isOpen,
		content,
		handleModalClose,
		handleModalOpen,
		setContent: memoizedSetContent,
	};
};
