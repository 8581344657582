import { FC, PropsWithChildren, createContext, useState } from 'react';

import { UserDTO } from 'core/api/models/user';

export interface UserActions {
	setLoggedInUser: (userData: UserDTO) => void;
	logoutUser: () => void;
	updateTokens: (access_token: string, refresh_token: string) => void;
}

export interface SidebarContextType {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

export const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

const SidebarProvider: FC<PropsWithChildren> = ({ children }) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

	const value: SidebarContextType = {
    isSidebarOpen,
    toggleSidebar  
	};

	return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

export default SidebarProvider;
