import { useGetUser } from 'shared/hooks/useGetUser';

import { useGetTeam } from './useGetTeam';
import { useGetUsersTeams } from './useGetUsersTeams';

export const useGetUserSettingsData = () => {
	const { data: user } = useGetUser();
	const { data: teams } = useGetUsersTeams();
	const { data: team } = useGetTeam(teams?.owner[0]?.id ?? teams?.member[0]?.id);
	const isAdmin = team?.length === 0 || (teams && teams?.owner.length > 0);

	return {
		user,
		team,
		isAdmin,
		teams,
	};
};
