import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import styles from './DeleteDialog.module.scss';

interface DeleteDialogProps {
	dialogType: 'brand' | 'tag' | 'note';
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	itemName: string;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
	dialogType,
	open,
	onClose,
	onConfirm,
	itemName,
}) => {
	let title = '';
	let text = '';

	switch (dialogType) {
		case 'brand':
			title = `Delete brand "${itemName}"?`;
			text = 'Please confirm if you want to delete the selected brand that you tracked.';
			break;
		case 'tag':
			title = `Delete "${itemName}" tag folder?`;
			text =
				'Deleting the tag folder is irreversible, and once done, it cannot be restored. This action will result in the deletion of all associated data Ads from the "My collection" as well.';
			break;
		case 'note':
			title = `Delete note`;
			text = 'Are you sure you want to delete this note?';
			break;
		default:
			break;
	}
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			classes={{
				paper: styles.dialogPaper,
			}}
			sx={{ zIndex: 2000 }}
		>
			<DialogTitle id="alert-dialog-title" className={styles.dialogTitle}>
				{title}
			</DialogTitle>
			<DialogContent className={styles.dialogContent}>
				<DialogContentText
					id="alert-dialog-description"
					classes={{
						root: styles.dialogContentText,
					}}
				>
					{text}
				</DialogContentText>
			</DialogContent>
			<DialogActions className={styles.dialogActions}>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button
					onClick={onConfirm}
					color={dialogType === 'brand' ? 'primary' : 'error'}
					autoFocus
					variant="contained"
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;
