import { FC, lazy } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { Button } from '@mui/material';
import Box from '@mui/material/Box/Box';

import useDeleteAd from 'shared/api/hooks/ads/useDeleteAd';
import { AdDetails } from 'shared/models/ads';

import { Accordions } from './Accordions';
import { MainInfo } from './MainInfo';
// import { Statistics } from './Statistics';
import { useUser } from 'core/UserProvider/useUser';
import { closeModal } from 'features/detailsModal/detailsModalSlice';

import styles from './AllData.module.scss';

const Countries = lazy(() => import('./Countries/Countries'));
const Landing = lazy(() => import('./Landing/Landing'));

interface AllDataProps {
	details: AdDetails;
	external?: boolean;
	adSpend: string;
}

const AllData: FC<AllDataProps> = ({ details, external, adSpend }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useUser();
	const deleteMutation = useDeleteAd();
	const handleDeleteAd = () => {
		if (!user) {
			navigate('/login');
		}
		if (user && details && details.user_ad_id && details.publicAdId) {
			deleteMutation.mutate(
				{ userId: user.id, adId: details.user_ad_id, publicAdId: details.publicAdId },
				{
					onSuccess: () => {
						dispatch(closeModal());
					},
				}
			);
		}
	};

	return (
		<Box className={styles.tabContent}>
			<MainInfo details={details} adSpend={adSpend} />
			{/* {details.total_likes && details.ad_impressions && (
				<Statistics likes={details.total_likes} impressions={details.ad_impressions} />
			)} */}
			<Box className={styles.chartLanding}>
				{details.country_reach && details.country_reach?.length > 0 && (
					<Countries countriesData={details.country_reach} />
				)}
				{details.landing_page && (
					<Landing
						contentUrl={details.landing_page}
						screenshot={details.landing_page_image_url}
					/>
				)}
			</Box>
			{(details.title || details.content) && (
				<Accordions title={details.title} content={details.content} />
			)}
			{!external && details.user_ad_id && (
				<Box className={styles.removeAd}>
					<Button
						variant="outlined"
						color="error"
						endIcon={<DeleteOutlined />}
						onClick={handleDeleteAd}
					>
						Remove Ad from saved
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default AllData;
