import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { adsApiService } from 'shared/api/services/ads/AdsApiService';
import { getSimplifiedTagByIds } from 'shared/helpers';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RootState } from 'app/store/store';
import { ToastContext } from 'core/toast/ToastProvider';
import { updateAdTags } from 'features/ads/adsSlice';

export const useUpdateAdTags = () => {
	const toast = useContext(ToastContext);
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const { tags: userTags } = useSelector((state: RootState) => state.tags);

	return useMutation({
		mutationFn: ({
			userId,
			adId,
			tagData,
		}: {
			userId: number;
			adId: string;
			tagData: number[];
		}) => adsApiService.updateAdTags(userId, adId, tagData),
		onSuccess: (_, variables) => {
			const updatedTagObjects = getSimplifiedTagByIds(userTags, variables.tagData);

			queryClient.invalidateQueries({ queryKey: ['tags'] });

			dispatch(updateAdTags({ user_ad_id: variables.adId, tagData: updatedTagObjects }));

			toast.open('Success', 'Ad tags updated successfully', {
				severity: 'success',
			});
		},
		onError: () => {
			toast.open('Error', 'Error updating ad tags', {
				severity: 'error',
			});
		},
	});
};
