import { useContext } from 'react';

import { Ad } from 'shared/models/ads';

import { notesApiService } from '../service/NotesApiService';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastContext } from 'core/toast/ToastProvider';

interface Note {
	id: string | undefined;
	userId: number;
	content: string;
	is_private: boolean;
}

export const usePatchNote = (adId: string | undefined, userId: number | undefined) => {
	const toast = useContext(ToastContext);
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data: {
			userId: number;
			content: string;
			noteId: string | undefined;
			is_private: boolean;
		}) => notesApiService.patchNote(data.userId, data.content, data.noteId, data.is_private),
		onMutate: async (updatedNote) => {
			await queryClient.cancelQueries({
				queryKey: ['ad', userId, adId].filter(Boolean),
			});

			const previousAd = queryClient.getQueryData<Ad>(['ad', userId, adId]);

			if (previousAd && updatedNote.noteId) {
				queryClient.setQueryData<Ad>(['ad', userId, adId], {
					...previousAd,
					notes: previousAd.notes.map((note) =>
						note.id === updatedNote.noteId
							? {
									...note,
									text: updatedNote.content,
									is_private: updatedNote.is_private,
								}
							: note
					),
				});
			}

			return { previousAd };
		},
		onSuccess: (data: Note, variables) => {
			toast.open('Success', 'Note updated successfully', { severity: 'success' });
			queryClient.setQueryData<Ad>(['ad', userId, adId], (previousAd) => {
				if (!previousAd) return undefined;
				const updatedNotes = previousAd.notes.map((note) =>
					note.id === variables.noteId
						? { ...note, text: data.content, is_private: data.is_private }
						: note
				);
				return { ...previousAd, notes: updatedNotes };
			});
		},
		onError: (_, __, context) => {
			toast.open('Error', 'Error updating note', { severity: 'error' });
			if (context?.previousAd) {
				queryClient.setQueryData<Ad>(['ad', userId, adId], context.previousAd);
			}
		},
	});
};

export default usePatchNote;
