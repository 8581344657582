import React, { SyntheticEvent, useState } from 'react';

import {
	Box,
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Typography,
} from '@mui/material';

import { useToggleOptionsHook } from 'shared/hooks/useAdsManagement';
import useScrollLock from 'shared/hooks/useScrollLock';

import styles from '../AdsControls.module.scss';

//TODO - use again when ad types is active
// type ToggleOption = 'adTypes' | 'estimatedAdSpend';
type ToggleOption = 'estimatedAdSpend';

const VisibilityControl = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	useScrollLock(anchorEl);

	const { toggleOptions, toggleOption } = useToggleOptionsHook();

	const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name as ToggleOption;
		toggleOption(name);
	};

	const handleMenuOpen = (event: SyntheticEvent<Element, Event>) => {
		setAnchorEl(event.currentTarget as HTMLElement);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const getVisibleLabel = () => {
		const enabledOptions = [
			// toggleOptions.adTypes ? 'Ad Types' : null,
			toggleOptions.estimatedAdSpend ? 'Estimated Ad Spend' : null,
		].filter(Boolean);

		if (enabledOptions.length === 0) {
			return 'None';
		}
		//TODO - use again when ad types is active
		// else if (enabledOptions.length === Object.keys(toggleOptions).length) {
		// 	return 'All';
		// }
		else {
			return enabledOptions.join(', ');
		}
	};

	return (
		<FormControl
			variant="outlined"
			size="small"
			className={`${styles.formControl} ${styles.visibleControl}`}
			style={{ width: '240px' }}
		>
			<InputLabel id="toggle-select-label" style={{ visibility: 'hidden' }}>
				Visible:
			</InputLabel>
			<Select
				labelId="toggle-select-label"
				open={Boolean(anchorEl)}
				onOpen={handleMenuOpen}
				onClose={handleMenuClose}
				value=""
				displayEmpty
				renderValue={() => (
					<Box className={styles.visibleOption}>
						<span className={styles.placeholder}>Visible: </span>
						<Typography variant="body3" className={styles.visibleValue}>
							{getVisibleLabel()}
						</Typography>
					</Box>
				)}
				MenuProps={{
					PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } },
					disableScrollLock: true,
				}}
			>
				<MenuItem value="">
					<FormGroup>
						{Object.entries(toggleOptions).map(([key, value]) => (
							<FormControlLabel
								key={key}
								control={
									<Switch checked={value} onChange={handleToggle} name={key} />
								}
								label={
									key.charAt(0).toUpperCase() +
									key.slice(1).replace(/([A-Z])/g, ' $1')
								}
							/>
						))}
					</FormGroup>
				</MenuItem>
			</Select>
		</FormControl>
	);
};

export default VisibilityControl;
