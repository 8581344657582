import { forwardRef } from 'react';

import { Avatar, Box, CardContent, Typography } from '@mui/material';

import { useToggleOptionsHook } from 'shared/hooks/useAdsManagement';
import { Ad } from 'shared/models/ads';
import { calculateAdSpend } from 'shared/utils/calculate-ad-spend';

import dayjs from 'dayjs';

import styles from '../AdCard.module.scss';

interface CardInfoProps {
	ad: Ad | undefined;
}

const CardInfo = forwardRef<HTMLDivElement, CardInfoProps>(({ ad }, ref) => {
	const { toggleOptions } = useToggleOptionsHook();

	return (
		<CardContent
			ref={ref}
			className={styles.cardContent}
			style={{
				alignItems: 'center',
				minHeight: '56px',
			}}
		>
			<Avatar src={ad?.brand_avatar_url} classes={{ root: styles.cardInfo }}>
				{ad?.brand_name?.slice(0, 1)[0]}
			</Avatar>
			<Box display="flex" flexDirection="column">
				{ad?.brand_name && (
					<Typography className={styles.brandName} variant="subtitle2">
						{ad.brand_name}
					</Typography>
				)}
				{/* {toggleOptions?.adTypes || toggleOptions?.estimatedAdSpend ? ( */}
				{toggleOptions?.estimatedAdSpend ? (
					<Box className={styles.cardAddInfo}>
						{/* TODO - use again when ad types is active
						{toggleOptions.adTypes && (
							<>
								{ad?.sourced_by === 'human' ? (
									<UserMaleIcon sx={{ width: '16px', height: '16px' }} />
								) : (
									<BotIcon sx={{ width: '16px', height: '16px' }} />
								)}
							</>
						)} */}
						<Typography
							className={styles.cardSaved}
							// sx={{ margin: toggleOptions.adTypes ? '0 10px 0 4px' : '0 10px 0 0' }}
							sx={{ margin: '0 10px 0 0' }}
							variant="caption"
							whiteSpace="nowrap"
						>
							Saved {dayjs(ad?.created_at).fromNow()}
						</Typography>
						{toggleOptions.estimatedAdSpend && (
							<>
								{ad?.ad_spend ? (
									<Typography variant="caption" whiteSpace="nowrap">
										{calculateAdSpend(ad.ad_spend)}
									</Typography>
								) : (
									<Typography
										variant="caption"
										whiteSpace="nowrap"
										sx={{ color: 'rgba(73, 80, 87, 0.5)' }}
									>
										No spend info
									</Typography>
								)}
							</>
						)}
					</Box>
				) : (
					<Box className={styles.cardAddInfo}>
						<Typography
							className={styles.cardSaved}
							sx={{ margin: '0 10px 0 0' }}
							variant="caption"
							whiteSpace="nowrap"
						>
							Saved {dayjs(ad?.created_at).fromNow()}
						</Typography>
					</Box>
				)}
			</Box>
		</CardContent>
	);
});

export default CardInfo;
