const PRICE_PER_1000_IMPRESSIONS = {
	tiktok: 8.52,
	meta: 12.86,
	instagram: 12.86,
	facebook: 12.86,
};

type PlatformType = keyof typeof PRICE_PER_1000_IMPRESSIONS;

const calculateReachFromAdSpend = (
	adSpend: number | undefined,
	platform: string
): number | undefined => {
	if (!adSpend) {
		return undefined;
	}
	if (typeof platform !== 'string' || !(platform.toLowerCase() in PRICE_PER_1000_IMPRESSIONS)) {
		console.error('Invalid platform');
		return undefined;
	}

	const normalizedPlatform: PlatformType = platform.toLowerCase() as PlatformType;
	const pricePerImpression = PRICE_PER_1000_IMPRESSIONS[normalizedPlatform] / 1000;
	return Math.floor((adSpend / pricePerImpression) * 1.95);
};

export default calculateReachFromAdSpend;
