import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChevronRightIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
			<path
				d="M4.3872 13.5C4.28421 13.5005 4.18215 13.4804 4.08703 13.4409C3.99191 13.4014 3.90563 13.3433 3.83325 13.2701C3.68646 13.1231 3.604 12.9238 3.604 12.7161C3.604 12.5084 3.68646 12.3091 3.83325 12.1622L8.5052 7.49022L3.83325 2.81827C3.69479 2.66968 3.61941 2.47313 3.62299 2.27005C3.62657 2.06698 3.70884 1.87322 3.85246 1.72959C3.99608 1.58597 4.18984 1.50371 4.39292 1.50012C4.596 1.49654 4.79254 1.57192 4.94114 1.71039L10.167 6.93628C10.3138 7.08325 10.3963 7.28249 10.3963 7.49022C10.3963 7.69795 10.3138 7.89718 10.167 8.04416L4.94114 13.2701C4.86877 13.3433 4.78249 13.4014 4.68737 13.4409C4.59224 13.4804 4.49019 13.5005 4.3872 13.5Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
};
