import { SvgIcon, SvgIconProps } from '@mui/material';

export const DnDIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="10" height="16" viewBox="0 0 10 16" fill="none" {...props}>
			<path
				d="M2.66667 8.33334C2.66667 7.8731 2.29357 7.5 1.83333 7.5C1.3731 7.5 1 7.8731 1 8.33334C1 8.79357 1.3731 9.16667 1.83333 9.16667C2.29357 9.16667 2.66667 8.79357 2.66667 8.33334Z"
				stroke="#495057"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.66667 2.5C2.66667 2.03977 2.29357 1.66667 1.83333 1.66667C1.3731 1.66667 0.999999 2.03977 0.999999 2.5C0.999999 2.96024 1.3731 3.33334 1.83333 3.33334C2.29357 3.33334 2.66667 2.96024 2.66667 2.5Z"
				stroke="#495057"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.66667 14.1667C2.66667 13.7064 2.29357 13.3333 1.83333 13.3333C1.3731 13.3333 1 13.7064 1 14.1667C1 14.6269 1.3731 15 1.83333 15C2.29357 15 2.66667 14.6269 2.66667 14.1667Z"
				stroke="#495057"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.66667 8.33334C8.66667 7.8731 8.29357 7.5 7.83333 7.5C7.3731 7.5 7 7.8731 7 8.33334C7 8.79357 7.3731 9.16667 7.83333 9.16667C8.29357 9.16667 8.66667 8.79357 8.66667 8.33334Z"
				stroke="#495057"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.66667 2.5C8.66667 2.03977 8.29357 1.66667 7.83333 1.66667C7.3731 1.66667 7 2.03977 7 2.5C7 2.96024 7.3731 3.33334 7.83333 3.33334C8.29357 3.33334 8.66667 2.96024 8.66667 2.5Z"
				stroke="#495057"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.66667 14.1667C8.66667 13.7064 8.29357 13.3333 7.83333 13.3333C7.3731 13.3333 7 13.7064 7 14.1667C7 14.6269 7.3731 15 7.83333 15C8.29357 15 8.66667 14.6269 8.66667 14.1667Z"
				stroke="#495057"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
};
