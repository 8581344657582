import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { getPriceInEuros } from 'shared/helpers/billingHelpers';
import { Product } from 'shared/models/billing';

import styles from './SubscriptionSelection.module.scss';

interface PlanSelectionProps {
	subscriptions: Product[] | undefined;
	selectedPlan: Product;
	handlePlanChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	formatPlanLabel: (plan: Product) => JSX.Element;
	disabled: boolean;
	billingInterval: 'month' | 'year';
	activeSubscriptionId?: string;
}

const PlanSelectionV2 = ({
	subscriptions,
	selectedPlan,
	handlePlanChange,
	formatPlanLabel,
	disabled,
	billingInterval,
	activeSubscriptionId,
}: PlanSelectionProps) => (
	<Box className={styles.step}>
		<Typography className={styles.stepTitle} variant="subtitle2">
			1. Choose a Plan
		</Typography>
		<RadioGroup value={selectedPlan.name} onChange={handlePlanChange}>
			{subscriptions?.map((plan) => (
				<FormControlLabel
					disabled={disabled}
					sx={{
						'& .MuiFormControlLabel-label': {
							width: '100%',
						},
					}}
					key={plan.id}
					value={plan.name}
					control={plan.id === activeSubscriptionId ? <Radio disabled /> : <Radio />}
					label={
						<Box className={styles.planLabel}>
							<Box className={styles.planDetails}>
								<Typography variant="subtitle2">
									{formatPlanLabel(plan)}
									{plan.id === activeSubscriptionId && (
										<Typography
											component="span"
											fontWeight="bold"
											sx={{ color: 'green' }}
										>
											{' '}
											(Active Subscription)
										</Typography>
									)}
								</Typography>
								{plan.metadata && (
									<Typography variant="subtitle1">
										Includes {plan.metadata.teamMembers} team seats and{' '}
										{plan.metadata.brands} brands
									</Typography>
								)}
							</Box>
							<Typography variant="subtitle1" className={styles.planPrice}>
								{`€${getPriceInEuros(plan.prices, billingInterval)}/${billingInterval === 'month' ? `mo` : 'yr'}`}
							</Typography>
						</Box>
					}
					style={{
						backgroundColor: plan.id === activeSubscriptionId ? '#e6e6e6' : '#fff',
					}}
					className={styles.planOption}
				/>
			))}
		</RadioGroup>
	</Box>
);

export default PlanSelectionV2;
