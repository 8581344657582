import React from 'react';

import { Box } from '@mui/material';

import styles from './TabPanel.module.scss';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
			className={styles.panel}
		>
			{value === index && <Box>{children}</Box>}
		</Box>
	);
};

export default TabPanel;
