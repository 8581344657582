import { SvgIcon, SvgIconProps } from '@mui/material';

export const EyeIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon width="15" height="15" viewBox="0 0 15 15" fill="none" {...props}>
			<g clipPath="url(#clip0_2338_2116)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.55355 7.5364C0.708567 7.87589 2.90413 12.6843 7.5 12.6843C12.0959 12.6843 14.2914 7.87589 14.4465 7.5364C14.4487 7.53137 14.4506 7.52732 14.452 7.52428C14.4837 7.44823 14.5 7.36666 14.5 7.28428C14.5 7.20189 14.4837 7.12032 14.452 7.04428C14.4506 7.04124 14.4487 7.03719 14.4465 7.03216C14.2914 6.69267 12.0959 1.88428 7.5 1.88428C2.90413 1.88428 0.708567 6.69266 0.55355 7.03216C0.551252 7.03719 0.549402 7.04124 0.548 7.04428C0.516314 7.12032 0.5 7.20189 0.5 7.28428C0.5 7.36666 0.516314 7.44823 0.548 7.52428C0.549402 7.52732 0.551252 7.53137 0.55355 7.5364ZM7.5 11.4843C4.164 11.4843 2.236 8.20428 1.764 7.28428C2.236 6.36428 4.164 3.08428 7.5 3.08428C10.836 3.08428 12.764 6.36428 13.236 7.28428C12.764 8.20428 10.836 11.4843 7.5 11.4843ZM6.05551 9.44609C6.48308 9.73178 6.98576 9.88427 7.5 9.88427C8.18891 9.88216 8.849 9.60755 9.33614 9.12041C9.82328 8.63328 10.0979 7.97318 10.1 7.28427C10.1 6.77004 9.94751 6.26735 9.66182 5.83978C9.37612 5.41222 8.97006 5.07897 8.49497 4.88218C8.01988 4.68539 7.49711 4.6339 6.99276 4.73422C6.48841 4.83455 6.02513 5.08217 5.66152 5.44579C5.2979 5.80941 5.05027 6.27268 4.94995 6.77703C4.84963 7.28138 4.90112 7.80416 5.09791 8.27924C5.2947 8.75433 5.62794 9.1604 6.05551 9.44609ZM6.7222 6.12021C6.95243 5.96638 7.2231 5.88427 7.5 5.88427C7.87065 5.88637 8.22553 6.03454 8.48762 6.29664C8.74972 6.55873 8.89789 6.91361 8.89999 7.28427C8.89999 7.56116 8.81789 7.83184 8.66405 8.06206C8.51022 8.29229 8.29157 8.47173 8.03575 8.5777C7.77994 8.68366 7.49844 8.71139 7.22687 8.65737C6.9553 8.60335 6.70584 8.47001 6.51005 8.27422C6.31425 8.07842 6.18091 7.82897 6.1269 7.55739C6.07288 7.28582 6.1006 7.00433 6.20656 6.74851C6.31253 6.49269 6.49197 6.27404 6.7222 6.12021Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2338_2116">
					<rect width="14" height="14" fill="white" transform="translate(0.5 0.28418)" />
				</clipPath>
			</defs>
		</SvgIcon>
	);
};
