import { FC, useContext, useState } from 'react';
import { useDrop } from 'react-dnd';

import Add from '@mui/icons-material/Add';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
	Box,
	Button,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Popover,
	Tooltip,
	Typography,
} from '@mui/material';

import useSaveAd from 'shared/api/hooks/ads/useSaveAd';
import { useUpdateAdTags } from 'shared/api/hooks/ads/useUpdateAdTags';
import { CopyIcon, ElipsisIcon, PencilIcon, TrashIcon } from 'shared/components/Icons';
import { useCopyFolderLink } from 'shared/hooks/useCopyFolderLink';
import { DropAdItem } from 'shared/models/ads';
import { Tag } from 'shared/models/tags';

import { DeleteDialog } from '../DeleteDialog';
import { InputEditWrapper } from '../InputEditWrapper';
import { SubTagItem } from './SubTagItem';
import { useUser } from 'core/UserProvider/useUser';
import { ToastContext } from 'core/toast/ToastProvider';

import styles from '../TagManagement.module.scss';

interface TagItemProps {
	tag: Tag;
	addTag: (tagName: string, level: number, parentId: number | null) => void;
	updateTagName: (tagId: number, newTagName: string) => void;
	deleteTag: (tagId: number, tagName: string) => void;
	adCount: number;
	onTagClick: (tagId: number) => void;
}

const TagItem: FC<TagItemProps> = ({
	tag,
	addTag,
	updateTagName,
	deleteTag,
	adCount,
	onTagClick,
}) => {
	const { user } = useUser();
	const updateAdTags = useUpdateAdTags();
	const saveMutation = useSaveAd();
	const toast = useContext(ToastContext);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [isEditing, setIsEditing] = useState(false);
	const [showAddSubTag, setShowAddSubTag] = useState(false);
	const [subTagName, setSubTagName] = useState('');
	const [subTagsVisible, setSubTagsVisible] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	const { handleCopyFolderLink } = useCopyFolderLink(tag.id);
	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: 'AD_CARD',
			drop: (item: DropAdItem) => handleDrop(item, tag.id),
			collect: (monitor: any) => ({
				isOver: monitor.isOver(),
			}),
		}),
		[tag]
	);

	const handleDrop = (item: DropAdItem, tagId: number) => {
		if (!user?.id || !item.adId) {
			return;
		}
		// Favourite ads
		if (item.userAdId && item.tagData) {
			const updatedTags = item.tagData.map((tag) => tag.id);

			if (updatedTags.includes(tagId)) {
				toast.open('Success', 'This ad is already assigned to the selected tag', {
					severity: 'success',
				});
				return;
			} else {
				updatedTags.push(tagId);
			}
			updateAdTags.mutate({ userId: user.id, adId: item.userAdId, tagData: updatedTags });
		} else {
			// Public ads
			saveMutation.mutate(
				{ userId: user.id, adId: item.adId, tagData: [tagId] },
				{
					onSuccess: () => {
						toast.open('Success', 'Ad saved successfully', {
							severity: 'success',
						});
					},
					onError: () => {
						toast.open('Error', 'Error saving ad', {
							severity: 'error',
						});
					},
				}
			);
		}
	};

	const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSaveEditedTagName = (newName: string) => {
		updateTagName(tag.id, newName);
		setIsEditing(false);
		handleClose();
	};

	const handleAddSubTag = (name: string) => {
		if (name) {
			addTag(name, 1, tag.id);
			setShowAddSubTag(false);
			setSubTagName('');
		}
	};

	const handleDeleteClick = () => {
		setIsDeleteDialogOpen(true);
		handleClose();
	};

	const handleConfirmDelete = () => {
		deleteTag(tag.id, tag.name);
		setIsDeleteDialogOpen(false);
	};

	const renderSubTags = (subTags: Tag[]) => {
		return (
			<Box
				className={`${styles.subTagsContainer} ${subTagsVisible ? styles.subTagsVisible : styles.subTagsHidden}`}
			>
				{subTags.map((subTag) => (
					<Box key={subTag.id} className={styles.singleTag}>
						<SubTagItem
							onSubTagClick={onTagClick}
							subTag={subTag}
							updateTagName={updateTagName}
							deleteTag={deleteTag}
							handleClose={handleClose}
							adCount={subTag.user_ads_count}
							handleDropItem={handleDrop}
						/>
					</Box>
				))}
			</Box>
		);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<Box className={styles.singleTagWrapper}>
			{isEditing ? (
				<InputEditWrapper
					isEditing
					prefix="#"
					value={tag.name}
					onSave={handleSaveEditedTagName}
					onCancel={() => {
						setIsEditing(false);
						handleClose();
					}}
				/>
			) : (
				<Box
					className={styles.singleTag}
					ref={drop}
					style={{
						opacity: isOver ? '.4' : '1',
						cursor: 'grab',
					}}
				>
					<Box className={styles.singleTagContent} onMouseLeave={handleClose}>
						<Box className={styles.arrowPlace}>
							{tag.subTags && tag.subTags.length > 0 && (
								<IconButton
									size="small"
									onClick={() => setSubTagsVisible(!subTagsVisible)}
								>
									{subTagsVisible ? (
										<KeyboardArrowDown className={styles.moreIcon} />
									) : (
										<KeyboardArrowRight className={styles.moreIcon} />
									)}
								</IconButton>
							)}
						</Box>
						<Box
							onClick={() => onTagClick(tag.id)}
							className={styles.tagName}
						>
							<span>#</span>
							<Tooltip title={tag.name} placement="top" arrow>
								<Typography className={styles.ellipsis} variant="subtitle1">
									{tag.name}
								</Typography>
							</Tooltip>
						</Box>
						<IconButton
							onClick={handleMenuClick}
							className={styles.moreButton}
							size="small"
							sx={{ visibility: 'hidden' }}
						>
							<ElipsisIcon
								sx={{ width: '20px', height: '20px' }}
								className={styles.moreIcon}
							/>
						</IconButton>
						<Popover
							disableScrollLock={true}
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							sx={{ zIndex: 2000 }}
							slotProps={{
								paper: {
									style: {
										position: 'absolute',
									},
								},
							}}
						>
							<List component="nav" aria-label="tag actions">
								<Button
									onClick={() => setIsEditing(true)}
									className={styles.menuButton}
								>
									<ListItem className={styles.menuList}>
										<ListItemIcon>
											<PencilIcon sx={{ width: '16px', height: '16px' }} />
										</ListItemIcon>
										<ListItemText primary="Rename" />
									</ListItem>
								</Button>
								<Button onClick={handleDeleteClick} className={styles.menuButton}>
									<ListItem className={styles.menuList}>
										<ListItemIcon>
											<TrashIcon sx={{ width: '16px', height: '16px' }} />
										</ListItemIcon>
										<ListItemText primary="Delete" />
									</ListItem>
								</Button>
								<Divider />
								<Button
									onClick={() => {
										handleCopyFolderLink();
										handleClose();
									}}
									className={styles.menuButton}
								>
									<ListItem className={styles.menuList}>
										<ListItemIcon>
											<CopyIcon sx={{ width: '16px', height: '16px' }} />
										</ListItemIcon>
										<ListItemText primary="Copy folder" />
									</ListItem>
								</Button>
							</List>
						</Popover>
						<Typography className={styles.tagCount} sx={{ ml: 1 }}>
							{adCount}
						</Typography>
						<Tooltip title="Add new sub-folder" placement="top">
							<Box className={styles.tagAdd}>
								<IconButton size="small" onClick={() => setShowAddSubTag(true)}>
									<Add className={styles.tagAddIcon} />
								</IconButton>
							</Box>
						</Tooltip>
					</Box>
					{showAddSubTag && (
						<InputEditWrapper
							prefix="#"
							value={subTagName}
							onSave={handleAddSubTag}
							onCancel={() => setShowAddSubTag(false)}
							isSubTag
						/>
					)}
				</Box>
			)}
			{subTagsVisible && tag.subTags && renderSubTags(tag.subTags)}
			<DeleteDialog
				dialogType='tag'
				open={isDeleteDialogOpen}
				onClose={() => setIsDeleteDialogOpen(false)}
				onConfirm={handleConfirmDelete}
				itemName={tag.name}
			/>
		</Box>
	);
};

export default TagItem;
