import { useContext } from 'react';

import { tagsApiService } from 'shared/api/services/tags/TagsApiService';
import { TagsResponse } from 'shared/models/tags';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastContext } from 'core/toast/ToastProvider';

export const useDeleteTag = () => {
	const toast = useContext(ToastContext);
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data: { userId: number | undefined; tagId: number; tagName: string }) =>
			tagsApiService.deleteTag(data.userId, data.tagId),
		onMutate: async (targetTag) => {
			await queryClient.cancelQueries({
				queryKey: ['tags'],
			});

			const previousTags: TagsResponse | undefined = queryClient.getQueryData(['tags']);

			if (previousTags) {
				const updatedTags = previousTags.tags.filter((tag) => tag.id !== targetTag.tagId);

				queryClient.setQueryData(['tags'], {
					...previousTags,
					tags: updatedTags,
					total: previousTags.total - 1,
				});

				return { previousTags, tagName: targetTag.tagName };
			}
			return { previousTags: undefined, tagName: targetTag.tagName };
		},
		onSuccess: (_, __, context) => {
			toast.open('Success', `Tag "${context.tagName}" deleted successfully`, {
				severity: 'success',
			});
			queryClient.invalidateQueries({ queryKey: ['tags'] });
		},
		onError: (_, __, context: any) => {
			toast.open('Error', `Error deleting tag "${context?.tagName}"`, {
				severity: 'error',
			});
			if (context?.previousTags) {
				queryClient.setQueryData(['tags'], context.previousTags);
			}
		},
	});
};
