import React from 'react';
import { useLocation } from 'react-router-dom';

import { ForgotPassword } from '../ForgotPassword';
import { ResetPassword } from '../ResetPassword';

const PasswordResetFlow: React.FC = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const resetToken = queryParams.get('reset_password_token');
	const uid = queryParams.get('uid');

	return (
		<div>
			{resetToken ? <ResetPassword token={resetToken} uid={uid} /> : <ForgotPassword />}
		</div>
	);
};

export default PasswordResetFlow;
