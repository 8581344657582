import { TagsResponse } from 'shared/models/tags';

import { apiService } from 'core/api/services';

export class TagsApiService {
	async getTags(userId: number | undefined): Promise<TagsResponse> {
		const response: any = await apiService.get(`api/client/user/${userId}/tag`);
		return {
			tags: response.data[0],
			total: response.data[1],
		};
	}

	async createTag(
		user_id: number | undefined,
		tagName: string,
		level: number,
		parentId: number | null
	): Promise<unknown> {
		return apiService.responseHandler(
			await apiService.post<unknown>(`api/client/user/${user_id}/tag`, {
				name: tagName,
				level: level,
				parent_id: parentId,
			})
		);
	}

	async updateTag(user_id: number | undefined, tagId: number, tagName: string): Promise<unknown> {
		return apiService.responseHandler(
			await apiService.patch<unknown>(`api/client/user/${user_id}/tag/${tagId}`, {
				name: tagName,
			})
		);
	}

	async deleteTag(user_id: number | undefined, tagId: number): Promise<unknown> {
		return apiService.responseHandler(
			await apiService.delete<unknown>(`api/client/user/${user_id}/tag/${tagId}`)
		);
	}
}

export const tagsApiService = new TagsApiService();
