import React from 'react';

import { Button } from '@mui/material';

import GoogleLogo from 'assets/images/login-signup-forgetpass-form/google-logo.svg';

interface GoogleLoginButtonProps {
	label: string;
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ label }) => {
	const baseURL = import.meta.env.VITE_APP_API_URL;

	const handleLogin = () => {
		window.location.href = `${baseURL}/api/client/auth/google`;
	};
	return (
		<Button
			variant="outlined"
			sx={{ height: '38px' }}
			fullWidth
			startIcon={<img src={GoogleLogo} alt="Google" />}
			onClick={handleLogin}
		>
			{label}
		</Button>
	);
};

export default GoogleLoginButton;
